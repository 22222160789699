import { ArrowPathIcon, EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Avatar } from '../../components/Avatars/Avatar';
import { GridItem2 } from '../../components/Lists/GridItem2';
import { GridListPlain } from '../../components/Lists/GridListPlain';
import { Menu } from '../../components/Menu/Menu';
import { DeleteModal } from '../../components/Modals/DeleteModal';
import { TitleWithSubtitle } from '../../components/Titles/TitleWithSubtitle';
import {
  Assignments,
  useAssignmentTimeEntryCountQuery,
  useDeleteAssignmentMutation
} from '../../generated/graphql';
import { customerLogoUrl } from '../../models/ProjectTypes';

interface EmployeeDetailsProjectsProps {
  assignments: Assignments[];
  onReloadNeeded: () => void;
}

export const EmployeeProjects = ({ assignments, onReloadNeeded }: EmployeeDetailsProjectsProps) => {
  const [toBeDeleted, setToBeDeleted] = useState<string | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);

  const [deleteAssignment] = useDeleteAssignmentMutation({
    onCompleted: () => {
      setToBeDeleted(null);
      setDeleteInProgress(false);
      onReloadNeeded();
    },
    onError: (error) => console.error(error)
  });

  const ids = assignments.map((a) => a.id);
  const { data, loading } = useAssignmentTimeEntryCountQuery({
    variables: { assignment_ids: ids }
  });

  const hasTimeEntries = (assignment: Assignments) => {
    const a = data?.assignments.find((item) => item.id === assignment.id);
    if (a === undefined) {
      return true;
    }
    return a.time_entries_aggregate.aggregate!.count > 0;
  };

  const assignmentMenu = (assignment: Assignments) => {
    if (loading) {
      return (
        <ArrowPathIcon className='h-5 w-5 text-gray-900-400 animate-spin' aria-hidden='true' />
      );
    } else if (hasTimeEntries(assignment)) {
      return null;
    } else {
      return (
        <Menu
          icon={<EllipsisVerticalIcon className='block h-6 w-6' aria-hidden='true' />}
          menuItems={[
            <span
              className={'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'}
              onClick={() => setToBeDeleted(assignment.id)}
            >
              Remove
            </span>
          ]}
        />
      );
    }
  };

  return (
    <div>
      <div className='flex sm:items-center mb-8'>
        <div className='sm:flex-auto'>
          <div className='flex'>
            <h1 className='text-xl font-semibold text-gray-900'>{assignments.length}</h1>
            <h1 className='lowercase text-xl text-gray-900 ml-2'>Projects</h1>
          </div>
        </div>
        <div className='mt-4 sm:mt-0 sm:ml-8 sm:flex-none'>
          <button
            type='button'
            className='inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none'
            onClick={() => {}}
          >
            <PlusIcon className='h-5 w-5' aria-hidden='true' />
            Add to project
          </button>
        </div>
      </div>
      <GridListPlain>
        {assignments.map((assignment: Assignments, index: number) => (
          <GridItem2
            key={assignment.id}
            left={<Avatar size={12} url={customerLogoUrl(assignment.project?.customer!)} />}
            center={
              <TitleWithSubtitle title={assignment?.name} subtitle={assignment?.project?.name} />
            }
            right={assignmentMenu(assignment)}
          />
        ))}
      </GridListPlain>
      <DeleteModal
        isOpen={toBeDeleted != null}
        title='Remove'
        description={`Are you sure you want to remove assignment from employee?`}
        onClose={() => {}}
        onDelete={() => {
          setDeleteInProgress(true);
          deleteAssignment({
            variables: {
              id: toBeDeleted
            }
          });
        }}
        inProgress={deleteInProgress}
      />
    </div>
  );
};

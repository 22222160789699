import { ArrowPathIcon, CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { usePDF } from '@react-pdf/renderer';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { Avatar } from '../../components/Avatars/Avatar';
import {
  assignmentProjectNameAvatarGenerator,
  dateColumnGenerator,
  hoursColumnGenerator,
  stringColumnGenerator
} from '../../components/Tables/ColumnTypes';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { Customers, Employee, Time_Entries } from '../../generated/graphql';
import { employeeFullname } from '../../models/EmployeeTypes';
import {
  customerLogoUrl,
  customersFromTimeEntries,
  entriesForCustomer
} from '../../models/ProjectTypes';
import { sortByTimeEntriesByDate, totalHours } from '../../models/TimeEntryTypes';
import { HoursPdfExport } from './HoursPdfExport';

type MonthlyDetailsTableProps = {
  entries: Time_Entries[];
  employee: Employee;
  month: Date;
};

export const MonthlyDetailsTable = ({ entries, employee, month }: MonthlyDetailsTableProps) => {
  const allCustomer: Customers = {
    id: 'all',
    name: 'All entries',
    is_own: false,
    projects: [],
    projects_aggregate: { nodes: [] }
  };

  const [selected, setSelected] = useState<Customers>(allCustomer);

  const customers = customersFromTimeEntries(entries);

  var entriesForSelectedCustomer =
    selected && selected.id !== 'all' ? entriesForCustomer(entries, selected) : entries;

  const hours = totalHours(entriesForSelectedCustomer);
  sortByTimeEntriesByDate(entriesForSelectedCustomer);

  const name = employeeFullname(employee);
  const [instance, updateInstance] = usePDF({
    document: (
      <HoursPdfExport
        entries={entriesForSelectedCustomer}
        name={name}
        totalHours={hours}
        month={month}
      />
    )
  });

  useEffect(() => {
    updateInstance(
      <HoursPdfExport
        entries={entriesForSelectedCustomer}
        name={name}
        totalHours={hours}
        month={month}
      />
    );
  }, [entriesForSelectedCustomer, month, name, updateInstance, hours]);

  const columnHelper = createColumnHelper<Time_Entries>();

  const dailyColumns = [
    columnHelper.accessor((row) => row.day, {
      id: 'day',
      ...dateColumnGenerator('Day')
    }),

    columnHelper.accessor((row) => row.hours, {
      id: 'hours',
      ...hoursColumnGenerator('Hours')
    }),

    columnHelper.accessor('assignment', {
      id: 'assignment',
      ...assignmentProjectNameAvatarGenerator()
    }),

    columnHelper.accessor((row) => row.comment, {
      id: 'comment',
      ...stringColumnGenerator('Comment')
    })
  ];

  type TagProps = {
    customer: Customers;
    index: number;
  };
  const Tag = ({ customer, index }: TagProps) => {
    const bg =
      selected?.name === customer.name ? 'bg-royal-blue text-white' : 'bg-transparent text-black';

    return (
      <div
        className={`flex align-middle ${bg} border-royal-blue border pt-1 pb-1 pl-2 pr-2 rounded-full mr-2 cursor-pointer`}
        onClick={() => setSelected(customer)}
      >
        <Avatar className='self-center' size={5} url={customerLogoUrl(customer)} />
        <span className='mr-3 ml-3'>{customer?.name}</span>
      </div>
    );
  };

  const pdfButton = () => {
    if (instance.loading) {
      return <ArrowPathIcon className='mr-5 h-6 w-6 text-white animate-spin' aria-hidden='true' />;
    }
    return (
      <div className='flex'>
        <Tag customer={allCustomer} index={-1} />
        {customers.map((entry, index) => (
          <Tag customer={entry} index={index} />
        ))}
        <a className=' ml-4 self-center' href={instance.url!} target='_blank' rel='noreferrer'>
          <CloudArrowDownIcon className='mr-5 h-6 w-6' />
        </a>
      </div>
    );
  };

  return (
    <ExtendableTable
      columns={dailyColumns}
      data={entriesForSelectedCustomer}
      title={`Daily summary \u2022 ${hours}h`}
      action={pdfButton()}
      initialSort={{ id: 'day', desc: false }}
    />
  );
};

import { PlusIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { Avatar } from '../Avatars/Avatar';

interface GridHeaderProps {
  imageUrl?: string | undefined | null;
  title: string;
  subtitle: string;
  buttonAction?(): void;
  buttonLabel: string;
  children?: React.ElementType;
  linkTo: string;
}

export default function GridHeader({
  title,
  subtitle,
  buttonAction,
  buttonLabel,
  imageUrl,
  linkTo
}: GridHeaderProps) {
  return (
    <div className='sm:flex sm:items-center sm:justify-between mt-10'>
      <Link to={linkTo || ''}>
        <div className='relative flex items-center space-x-3 px-6 py-5'>
          <Avatar size={10} url={imageUrl} />

          <div className='min-w-0 flex-1'>
            <span className='absolute inset-0' aria-hidden='true' />
            <p className='text-sm font-medium text-gray-900'>{title}</p>
            <p className='truncate text-sm text-gray-500'>{subtitle}</p>
          </div>
        </div>
      </Link>
      <div className='mt-3 sm:mt-0 sm:ml-4'>
        <button
          type='button'
          className='inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none'
          onClick={buttonAction ? buttonAction : () => {}}
        >
          <PlusIcon className='-ml-1 mr-3 h-5 w-5' aria-hidden='true' />
          {buttonLabel}
        </button>
      </div>
    </div>
  );
}

import { useContext, useState } from 'react';
import { EmptyPageLayout } from '../../components/PageLayout/EmptyPageLayout';
import { CustomerListItem } from '../../components/Projects/CustomerListItem';
import { Search } from '../../components/Search/Search';
import { Customers, Projects as Project, useCustomersQuery } from '../../generated/graphql';
import {
  activeProjects,
  filterCustomersProjects,
  projectsFor,
  sortCustomers
} from '../../models/ProjectTypes';
import { MonthContext } from '../../providers/MonthContext';
import { monthName } from '../../utils/formatters';

export const Projects = () => {
  const { data, refetch } = useCustomersQuery();
  const { selectedMonth } = useContext(MonthContext);
  const [filter, setFilter] = useState<string>('');

  const customers = (data?.customers || []) as Customers[];

  const selectedMonthFilter = (project: Project): boolean => {
    return activeProjects(project, selectedMonth);
  };

  const p = projectsFor(customers, selectedMonthFilter);

  const filtered = filterCustomersProjects(p, filter);

  const sorted = sortCustomers(filtered);

  return (
    <EmptyPageLayout title='Projects'>
      <div className='flex flex-shrink-0 justify-between'>
        <div className='w-96 text-2xl font-normal'>{`${monthName(
          selectedMonth
        )} ${selectedMonth.getFullYear()}`}</div>
        <Search onFilterUpdated={setFilter} placeholder={'projects by name'} />
      </div>
      {sorted.map((cust: Customers) => (
        <CustomerListItem key={cust.id} customer={cust} onUpdate={refetch} />
      ))}
    </EmptyPageLayout>
  );
};

import { useApolloClient } from '@apollo/client';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { formatISO } from 'date-fns';
import { useContext, useState } from 'react';
import { DeleteModal } from '../../components/Modals/DeleteModal';
import {
  currencyEditColumnGenerator,
  dateEditColumnGenerator,
  employeeAvatarFullnameGenerator,
  stringColumnGenerator
} from '../../components/Tables/ColumnTypes';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { Content, PanelButton, PanelTab } from '../../components/Tabs/Panels';
import {
  Assignments,
  Projects,
  useDeleteAssignmentMutation,
  useProjectsQuery,
  useTimeEntryCountQuery
} from '../../generated/graphql';
import { UPDATE_ASSIGNMENT } from '../../graphQL/mutations';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { ProjectEmployeeAddForm } from './ProjectEmployeeAddForm';

type ProjectEmployeesProps = {
  projectId: string;
};
export const ProjectEmployees = ({ projectId }: ProjectEmployeesProps) => {
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [assignmentId, setAssignmentId] = useState<String | undefined>();

  const { dispatch } = useContext(SystemUiContext);
  const client = useApolloClient();

  const { data, loading, refetch } = useProjectsQuery({
    variables: {
      where: {
        id: { _eq: projectId }
      }
    }
  });

  const [deleteAssignment] = useDeleteAssignmentMutation({
    onCompleted: () => {
      setDeleteInProgress(false);
      setShowRemoveModal(false);

      refetch();
    },
    onError: (error) => {
      setDeleteInProgress(false);
      setShowRemoveModal(false);
      console.log('DELETE ERROR', error);
    }
  });

  const projects = (data?.projects || []) as Projects[];
  const project = projects[0];
  const assignments = (project?.assignments || []) as Assignments[];

  const aIds = assignments.reduce((prev, curr) => [...prev, curr.id], [] as any);

  const { data: timeEntriesCount } = useTimeEntryCountQuery({
    variables: { where: { id: { _in: aIds } } }
  });

  const assignmentHasEntries = (id: string): boolean => {
    const a = timeEntriesCount?.assignments?.find((i) => i.id === id);
    const c = a?.time_entries_aggregate.aggregate?.count;
    return c !== undefined ? c > 0 : false;
  };

  const triggerRemoveModal = async (assignmentId: string) => {
    setShowRemoveModal(true);
    setAssignmentId(assignmentId);
  };

  const editCallback = async (value: any, context: any, key: keyof Object): Promise<number> => {
    return new Promise(async (resolve, reject) => {
      const data = {};
      data[key] = value;

      // Using the direct method instead of the generated useUpdateAssignmentMutation hook as
      // the hook usage will cause an unwanted render on this level and it will inturn trigger
      // the table re-render and thus causing the cell to loose its state
      const res = await client.mutate({
        mutation: UPDATE_ASSIGNMENT,
        variables: {
          id: context.id,
          data
        }
      });
      if (res.data?.update_assignments?.affected_rows === 1) {
        resolve(value);
      } else {
        reject();
      }
    });
  };

  const dateEditCallback = async (value: Date, context: any, key: keyof Object): Promise<Date> => {
    return new Promise(async (resolve, reject) => {
      const data = {};
      const dateString = formatISO(value);
      data[key] = dateString as any;

      // Using the direct method instead of the generated useUpdateAssignmentMutation hook as
      // the hook usage will cause an unwanted render on this level and it will inturn trigger
      // the table re-render and thus causing the cell to loose its state
      const res = await client.mutate({
        mutation: UPDATE_ASSIGNMENT,
        variables: {
          id: context.id,
          data
        }
      });
      if (res.data?.update_assignments?.affected_rows === 1) {
        resolve(value);
      } else {
        reject();
      }
    });
  };

  const columnHelper = createColumnHelper<Assignments>();
  const columns = [
    columnHelper.accessor((row) => row.employee, {
      id: 'employee',
      ...employeeAvatarFullnameGenerator()
    }),
    columnHelper.accessor((row) => row.po, {
      id: 'po',
      ...stringColumnGenerator('PO')
    }),
    columnHelper.accessor((row) => row.start_date, {
      id: 'start_date',
      ...dateEditColumnGenerator('Start', dateEditCallback)
    }),
    columnHelper.accessor((row) => row.end_date, {
      id: 'end_date',
      ...dateEditColumnGenerator('End', dateEditCallback)
    }),
    columnHelper.accessor((row) => row.external_price, {
      id: 'external_price',
      ...currencyEditColumnGenerator('External price', editCallback)
    }),
    columnHelper.accessor((row) => row.internal_price, {
      id: 'internal_price',
      ...currencyEditColumnGenerator('Internal price', editCallback)
    }),
    {
      accessor: 'actions',
      header: ' ',
      size: 20,
      cell: (item: any) =>
        assignmentHasEntries(item.row.original.id) ? null : (
          <span
            className={'text-right'}
            onClick={() => {
              triggerRemoveModal(item.row.original.id);
            }}
          >
            <TrashIcon className='w-5 h-5 text-gray-200 group-hover:text-gray-500' />
          </span>
        )
    }
  ];

  const handleAssignmentDelete = () => {
    setDeleteInProgress(true);
    deleteAssignment({ variables: { id: assignmentId } });
  };
  return (
    <>
      <PanelTab
        action={
          <PanelButton
            icon={<PlusIcon className='h-5 w-5 mr-5' aria-hidden='true' />}
            label='Add to project'
            onClick={() => {
              dispatch?.({
                type: SystemUiActions.SHOW_DRAWER,
                payload: {
                  open: true,
                  title: 'New Assignment',
                  description: `Add employee to project`,
                  content: <ProjectEmployeeAddForm project={project} onComplete={() => refetch()} />
                }
              });
            }}
          />
        }
      >
        People
      </PanelTab>
      <Content>
        <div className='flex sm:items-center mb-8'>
          <div className='sm:flex-auto'>
            <div className='flex'>
              <h1 className='text-xl font-semibold text-gray-900'>{assignments.length}</h1>
              <h1 className='lowercase text-xl text-gray-900 ml-2'>Employees</h1>
            </div>
          </div>
        </div>
        <div className='mb-12'>
          <ExtendableTable
            columns={columns}
            data={assignments}
            loading={loading}
            initialSort={{
              id: 'employee',
              desc: false
            }}
          />
        </div>

        <DeleteModal
          isOpen={showRemoveModal}
          title='Remove'
          description={`Are you sure you want to remove employee from project?`}
          onClose={() => setShowRemoveModal(false)}
          onDelete={() => {
            handleAssignmentDelete();
          }}
          inProgress={deleteInProgress}
        />
      </Content>
    </>
  );
};

import { TrashIcon } from '@heroicons/react/20/solid';
import { useContext } from 'react';
import { Content, PanelButton, PanelTab } from '../../components/Tabs/Panels';
import { Projects } from '../../generated/graphql';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { ProjectDeleteForm } from './ProjectDeleteForm';

type ProjectDetailsProps = {
  project: Projects;
};

const LineItem = ({ items }: any) => {
  return (
    <div className='grid grid-cols-3 gap-0 border-b border-gray-200 py-8'>
      {items.map((item: any, index: number) => (
        <div key={index}>{item}</div>
      ))}
    </div>
  );
};

export const ProjectDetails = ({ project }: ProjectDetailsProps) => {
  const { dispatch } = useContext(SystemUiContext);
  return (
    <>
      <PanelTab
        action={
          <PanelButton
            icon={<TrashIcon className='h-5 w-5 mr-5' aria-hidden='true' />}
            label='Delete project'
            onClick={() => {
              dispatch?.({
                type: SystemUiActions.SHOW_DRAWER,
                payload: {
                  open: true,
                  title: 'Delete project',
                  description: 'Tread carefully, this cannot be undone',
                  content: <ProjectDeleteForm project={project} onComplete={() => {}} />
                }
              });
            }}
          />
        }
      >
        Details
      </PanelTab>
      <Content>
        <div className='border-b border-gray-200 pb-4'>
          <div className='text-lg leading-6 font-medium'>Project information</div>
          <div className='text-gray-500'>&nbsp;</div>
        </div>

        <LineItem items={['Name', project.name, '']} />
        <LineItem items={['PO', project.po, '']} />
        <LineItem items={['Start date', project.start_date, '']} />
        <LineItem items={['End date', project.end_date, '']} />
      </Content>
    </>
  );
};

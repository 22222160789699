import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface MenuProps {
  icon: JSX.Element;
  menuItems: JSX.Element[];
}

export const Menu = ({ icon, menuItems }: MenuProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Popover className='relative'>
      {({ open }) => (
        <>
          <Popover.Button
            onClick={() => setIsOpen(true)}
            className={classNames(
              open ? 'text-gray-900' : 'text-gray-500',
              'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none'
            )}
          >
            {icon}
          </Popover.Button>

          <Transition
            as={Fragment}
            show={isOpen && open}
            enter='transition ease-out duration-200'
            enterFrom='opacity-0 translate-y-1'
            enterTo='opacity-100 translate-y-0'
            leave='transition ease-in duration-150'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-1'
          >
            <Popover.Panel
              className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
              onClick={() => setIsOpen(false)}
            >
              {menuItems.map((menuItem: JSX.Element, index: number) => (
                <div key={index}>{menuItem}</div>
              ))}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

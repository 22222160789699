import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Avatar } from '../components/Avatars/Avatar';
import { GridListPlain } from '../components/Lists/GridListPlain';
import { GridItem2 } from '../components/Lists/GridItem2';
import { PageLayout } from '../components/PageLayout/PageLayout';
import { TitleWithSubtitle } from '../components/Titles/TitleWithSubtitle';
import { SideDrawer } from '../components/Drawers/SideDrawer';
import { ADD_EMPLOYEE } from '../graphQL/mutations';
import {
  employeeBasicInfoOk,
  employeeFullname,
  employeeInitials,
  filterEmployees,
  sortByLastName
} from '../models/EmployeeTypes';
import { Link } from 'react-router-dom';
import { Employee, useEmployeesQuery } from '../generated/graphql';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

export const People = () => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [filter, setFilter] = useState<string>('');

  const { loading, data, refetch } = useEmployeesQuery();

  const [addEmployee] = useMutation(ADD_EMPLOYEE, {
    onError: (err: any) => {
      console.log(err);
    },
    onCompleted: (_resp: any) => {
      setShowDrawer(false);
      refetch();
    }
  });

  const transformedData = sortByLastName(
    filterEmployees((data?.employee || []) as Employee[], filter)
  );

  // TODO:
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = () => {
    if (employee) {
      addEmployee({ variables: { ...employee } });
    }
  };

  return (
    <div>
      <PageLayout
        loading={loading}
        title='People'
        subtitle='people'
        dataCount={transformedData.length}
        onFilterUpdated={(value) => setFilter(value)}
      >
        <GridListPlain>
          {transformedData.map((employee: Employee, index: number) => (
            <Link to={`/people/${employee.id}`} key={index}>
              <GridItem2
                key={employee.id}
                left={
                  <Avatar size={12} url={employee.avatar} fallback={employeeInitials(employee)} />
                }
                center={
                  <TitleWithSubtitle title={employeeFullname(employee)} subtitle={employee.email} />
                }
                right={
                  employeeBasicInfoOk(employee) ? null : (
                    <ExclamationCircleIcon className='w-6 h-6 text-red-600' />
                  )
                }
              />
            </Link>
          ))}
        </GridListPlain>
      </PageLayout>
      <SideDrawer
        title='Add member'
        description='Welcome a new member to Graend Company. Get started by filling information.'
        isOpen={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <div className='h-0 flex-1 overflow-y-auto'>
          <div className='divide-y divide-gray-200 px-4 sm:px-6'>
            <div className='space-y-6 pt-6 pb-5'>
              <div>
                <label htmlFor='first_name' className='block text-sm font-medium text-gray-900'>
                  First name
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='first_name'
                    id='first_name'
                    className='block w-full p-2 text-ae-black rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    onChange={(evt) =>
                      setEmployee({
                        ...employee,
                        [evt.target.name]: evt.target.value
                      } as Employee)
                    }
                  />
                </div>
              </div>
              <div>
                <label htmlFor='last_name' className='block text-sm font-medium text-gray-900'>
                  Last name
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='last_name'
                    id='last_name'
                    className='block w-full p-2 text-ae-black rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    onChange={(evt) =>
                      setEmployee({
                        ...employee,
                        [evt.target.name]: evt.target.value
                      } as Employee)
                    }
                  />
                </div>
              </div>
              <div>
                <label htmlFor='email' className='block text-sm font-medium text-gray-900'>
                  Email
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='email'
                    id='email'
                    className='block w-full p-2 text-ae-black rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    onChange={(evt) =>
                      setEmployee({
                        ...employee,
                        [evt.target.name]: evt.target.value
                      } as Employee)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideDrawer>
    </div>
  );
};

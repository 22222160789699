import { Tooltip } from 'flowbite-react';
import { Employee } from '../../generated/graphql';
import { employeeFullname, employeeIsNonBillable } from '../../models/EmployeeTypes';
import { Avatar, AvatarProps } from './Avatar';

type EmployeeAvatarProps = {
  employee: Employee;
} & AvatarProps;

export const EmployeeAvatar = ({ employee, size, notification }: EmployeeAvatarProps) => (
  <Tooltip content={employeeFullname(employee)}>
    <Avatar
      url={employee.avatar}
      size={size}
      notification={notification}
      className={employeeIsNonBillable(employee) ? 'p-[2px] rounded-full bg-green-500' : ''}
    />
  </Tooltip>
);

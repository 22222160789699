import { Employee } from '../../generated/graphql';
import { EmployeeAvatar } from './EmployeeAvatar';

interface EmployeeAvatarListProps {
  employees: Employee[];
  size?: number;
}

export const EmployeeAvatarList = ({ employees, size = 6 }: EmployeeAvatarListProps) => {
  return (
    <>
      {employees && (
        <div className='isolate flex -space-x-1 overflow-hidden'>
          {employees.map((employee, index) => (
            <EmployeeAvatar key={index} employee={employee} size={size} />
          ))}
        </div>
      )}
    </>
  );
};

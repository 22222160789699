import { StatsCard } from '../../components/Cards/StatsCard';
import { useMonthSelector } from '../../components/Inputs/MonthSelect';
import { Assignments, Projects, useAssignmentDetailsQuery } from '../../generated/graphql';
import { firstDayOfMonthAsISO, formatCurrency, lastDayOfMonthAsISO } from '../../utils/formatters';

import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { useContext } from 'react';
import { ProjectEditForm } from '../../components/Projects/ProjectEditForm';
import { ExtendableTable } from '../../components/Tables/ExtendableTable';
import { Content, PanelButton, PanelTab } from '../../components/Tabs/Panels';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';

import {
  currencyColumnGenerator,
  employeeAvatarFullnameGenerator,
  hoursColumnGenerator,
  numberColumnGenerator
} from '../../components/Tables/ColumnTypes';

type ProjectOverviewProps = {
  id: string;
  project: Projects;
};

export const ProjectOverview = ({ id, project }: ProjectOverviewProps) => {
  const { MonthSelectorComponent, selectedMonth } = useMonthSelector();
  const { dispatch } = useContext(SystemUiContext);

  const { data, loading } = useAssignmentDetailsQuery({
    variables: {
      where: {
        project_id: { _eq: id }
      },
      time_entries_aggr_where: {
        _and: [
          {
            day: {
              _gte: firstDayOfMonthAsISO(selectedMonth),
              _lte: lastDayOfMonthAsISO(selectedMonth)
            }
          }
        ]
      }
    }
  });

  const rightSideControls = () => {
    return (
      <PanelButton
        icon={<PencilSquareIcon className='w-5 h-5 mr-5' />}
        label='Edit project'
        onClick={() => {
          dispatch?.({
            type: SystemUiActions.SHOW_DRAWER,
            payload: {
              open: true,
              title: 'Edit project',
              description: `Edit project details`,
              content: <ProjectEditForm project={project} onComplete={() => {}} />
            }
          });
        }}
      />
    );
  };

  const columnHelper = createColumnHelper<Assignments>();
  const columns = [
    columnHelper.accessor((row) => row.employee, {
      id: 'employee',
      ...employeeAvatarFullnameGenerator()
    }),
    columnHelper.accessor((row) => row.allocation, {
      id: 'allocation',
      ...numberColumnGenerator('Allocation (%)')
    }),
    columnHelper.accessor((row) => row.time_entries_aggregate.aggregate?.sum?.hours || 0, {
      id: 'hours',
      ...hoursColumnGenerator('Reported hours (h)')
    }),
    columnHelper.accessor(
      (row) => row.external_price * row.time_entries_aggregate.aggregate?.sum?.hours || 0,
      {
        id: 'billing',
        ...currencyColumnGenerator('Billing (€)')
      }
    )
  ];

  const assignments = (data?.assignments || []) as Assignments[];

  const summarize = (assignments: Assignments[]) => {
    return assignments.reduce(
      (prev, value) => {
        return {
          billing:
            prev.billing +
            value.external_price * value.time_entries_aggregate.aggregate?.sum?.hours,
          reported_hours: prev.reported_hours + value.time_entries_aggregate.aggregate?.sum?.hours
        };
      },
      { billing: 0, reported_hours: 0 }
    );
  };

  const summary: any = summarize(assignments);
  return (
    <>
      <PanelTab action={rightSideControls()}>Overview</PanelTab>
      <Content>
        <div className='grid grid-cols-3 gap-20 mb-12'>
          <MonthSelectorComponent />
        </div>

        <div className='grid grid-cols-3 gap-8 mb-12'>
          <StatsCard label='Actual billing' value={formatCurrency(summary.billing)} />
          <StatsCard label='Maximum billing' value='-' />
          <StatsCard label='Reported hours' value={`${summary.reported_hours} h`} />
        </div>

        <div className='mb-12'>
          <ExtendableTable
            columns={columns}
            data={assignments}
            loading={loading}
            initialSort={{
              id: 'employee',
              desc: false
            }}
          />
        </div>
      </Content>
    </>
  );
};

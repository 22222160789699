import { endOfMonth, isSameDay, isWeekend, startOfMonth } from 'date-fns';

export const workingDaysInMonth = (month: Date, holidays: Date[]): number => {
  return workingDaysBetween(startOfMonth(month), endOfMonth(month), holidays);
};

export const workingDaysBetween = (start: Date, end: Date, holidays: Date[]): number => {
  var wd = 0;

  for (var d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    if (!(isWeekend(d) || datesIncludes(d, holidays))) {
      wd += 1;
    }
  }
  return wd;
};

export const datesIncludes = (item: Date, array: Date[]): boolean => {
  return array.find((d) => isSameDay(d, item)) !== undefined;
};

import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Customers, Projects } from '../../generated/graphql';
import { customerLogoUrl } from '../../models/ProjectTypes';
import { SystemUiActions, SystemUiContext } from '../../providers/SystemUi';
import { collectEmployees } from '../../utils/type';
import { EmployeeAvatarList } from '../Avatars/EmployeeAvatarList';
import GridHeader from '../Lists/GridHeader';
import { GridItem2 } from '../Lists/GridItem2';
import { GridListPlain } from '../Lists/GridListPlain';
import { ProjectAddForm } from './ProjectAddForm';

interface CustomerListItemProps {
  customer: Customers;
  onUpdate: () => void;
}

export const CustomerListItem = ({ customer, onUpdate }: CustomerListItemProps) => {
  const { dispatch } = useContext(SystemUiContext);

  const { name, projects } = customer;

  const logoUrl = customerLogoUrl(customer);

  return (
    <>
      <GridHeader
        imageUrl={logoUrl}
        title={name}
        linkTo={`/customer/${customer.id}`}
        subtitle={projects.length.toString()}
        buttonAction={() => {
          dispatch?.({
            type: SystemUiActions.SHOW_DRAWER,
            payload: {
              open: true,
              title: 'Add new project',
              description: `Add new project for ${customer.name}`,
              content: <ProjectAddForm customer={customer} onComplete={() => onUpdate()} />
            }
          });
        }}
        buttonLabel='Add project'
      ></GridHeader>

      <GridListPlain>
        {projects.map((project: Projects, index: number) => (
          <Link to={`/projects/${project.id}`} key={index}>
            <GridItem2
              key={project.id}
              center={<div>{project.name}</div>}
              right={<EmployeeAvatarList employees={collectEmployees(project.assignments)} />}
            />
          </Link>
        ))}
      </GridListPlain>
    </>
  );
};

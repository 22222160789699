import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  daterange: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "assignments" */
export type Assignments = {
  __typename?: 'assignments';
  allocation?: Maybe<Scalars['numeric']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  employee?: Maybe<Employee>;
  employee_id?: Maybe<Scalars['uuid']>;
  end_date?: Maybe<Scalars['date']>;
  external_price?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  internal_price?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  po?: Maybe<Scalars['String']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  report?: Maybe<Employee_Aggregated_Hours>;
  start_date?: Maybe<Scalars['date']>;
  /** An array relationship */
  time_entries: Array<Time_Entries>;
  /** An aggregate relationship */
  time_entries_aggregate: Time_Entries_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "assignments" */
export type AssignmentsTime_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Time_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Entries_Order_By>>;
  where?: InputMaybe<Time_Entries_Bool_Exp>;
};


/** columns and relationships of "assignments" */
export type AssignmentsTime_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Time_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Entries_Order_By>>;
  where?: InputMaybe<Time_Entries_Bool_Exp>;
};

/** aggregated selection of "assignments" */
export type Assignments_Aggregate = {
  __typename?: 'assignments_aggregate';
  aggregate?: Maybe<Assignments_Aggregate_Fields>;
  nodes: Array<Assignments>;
};

export type Assignments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Assignments_Aggregate_Bool_Exp_Count>;
};

export type Assignments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Assignments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assignments" */
export type Assignments_Aggregate_Fields = {
  __typename?: 'assignments_aggregate_fields';
  avg?: Maybe<Assignments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Assignments_Max_Fields>;
  min?: Maybe<Assignments_Min_Fields>;
  stddev?: Maybe<Assignments_Stddev_Fields>;
  stddev_pop?: Maybe<Assignments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Assignments_Stddev_Samp_Fields>;
  sum?: Maybe<Assignments_Sum_Fields>;
  var_pop?: Maybe<Assignments_Var_Pop_Fields>;
  var_samp?: Maybe<Assignments_Var_Samp_Fields>;
  variance?: Maybe<Assignments_Variance_Fields>;
};


/** aggregate fields of "assignments" */
export type Assignments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Assignments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assignments" */
export type Assignments_Aggregate_Order_By = {
  avg?: InputMaybe<Assignments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Assignments_Max_Order_By>;
  min?: InputMaybe<Assignments_Min_Order_By>;
  stddev?: InputMaybe<Assignments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Assignments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Assignments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Assignments_Sum_Order_By>;
  var_pop?: InputMaybe<Assignments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Assignments_Var_Samp_Order_By>;
  variance?: InputMaybe<Assignments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "assignments" */
export type Assignments_Arr_Rel_Insert_Input = {
  data: Array<Assignments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Assignments_On_Conflict>;
};

/** aggregate avg on columns */
export type Assignments_Avg_Fields = {
  __typename?: 'assignments_avg_fields';
  allocation?: Maybe<Scalars['Float']>;
  external_price?: Maybe<Scalars['Float']>;
  internal_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "assignments" */
export type Assignments_Avg_Order_By = {
  allocation?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "assignments". All fields are combined with a logical 'AND'. */
export type Assignments_Bool_Exp = {
  _and?: InputMaybe<Array<Assignments_Bool_Exp>>;
  _not?: InputMaybe<Assignments_Bool_Exp>;
  _or?: InputMaybe<Array<Assignments_Bool_Exp>>;
  allocation?: InputMaybe<Numeric_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee?: InputMaybe<Employee_Bool_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  external_price?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_price?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  po?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  report?: InputMaybe<Employee_Aggregated_Hours_Bool_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  time_entries?: InputMaybe<Time_Entries_Bool_Exp>;
  time_entries_aggregate?: InputMaybe<Time_Entries_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "assignments" */
export enum Assignments_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssignmentsPkey = 'assignments_pkey'
}

/** input type for incrementing numeric columns in table "assignments" */
export type Assignments_Inc_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
  external_price?: InputMaybe<Scalars['numeric']>;
  internal_price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "assignments" */
export type Assignments_Insert_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employee?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  end_date?: InputMaybe<Scalars['date']>;
  external_price?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_price?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  po?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  report?: InputMaybe<Employee_Aggregated_Hours_Obj_Rel_Insert_Input>;
  start_date?: InputMaybe<Scalars['date']>;
  time_entries?: InputMaybe<Time_Entries_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Assignments_Max_Fields = {
  __typename?: 'assignments_max_fields';
  allocation?: Maybe<Scalars['numeric']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['uuid']>;
  end_date?: Maybe<Scalars['date']>;
  external_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  internal_price?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  po?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "assignments" */
export type Assignments_Max_Order_By = {
  allocation?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  po?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Assignments_Min_Fields = {
  __typename?: 'assignments_min_fields';
  allocation?: Maybe<Scalars['numeric']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['uuid']>;
  end_date?: Maybe<Scalars['date']>;
  external_price?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  internal_price?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  po?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "assignments" */
export type Assignments_Min_Order_By = {
  allocation?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  po?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "assignments" */
export type Assignments_Mutation_Response = {
  __typename?: 'assignments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assignments>;
};

/** input type for inserting object relation for remote table "assignments" */
export type Assignments_Obj_Rel_Insert_Input = {
  data: Assignments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Assignments_On_Conflict>;
};

/** on_conflict condition type for table "assignments" */
export type Assignments_On_Conflict = {
  constraint: Assignments_Constraint;
  update_columns?: Array<Assignments_Update_Column>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};

/** Ordering options when selecting data from "assignments". */
export type Assignments_Order_By = {
  allocation?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employee_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  po?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  report?: InputMaybe<Employee_Aggregated_Hours_Order_By>;
  start_date?: InputMaybe<Order_By>;
  time_entries_aggregate?: InputMaybe<Time_Entries_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: assignments */
export type Assignments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assignments" */
export enum Assignments_Select_Column {
  /** column name */
  Allocation = 'allocation',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExternalPrice = 'external_price',
  /** column name */
  Id = 'id',
  /** column name */
  InternalPrice = 'internal_price',
  /** column name */
  Name = 'name',
  /** column name */
  Po = 'po',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "assignments" */
export type Assignments_Set_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  end_date?: InputMaybe<Scalars['date']>;
  external_price?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_price?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  po?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Assignments_Stddev_Fields = {
  __typename?: 'assignments_stddev_fields';
  allocation?: Maybe<Scalars['Float']>;
  external_price?: Maybe<Scalars['Float']>;
  internal_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "assignments" */
export type Assignments_Stddev_Order_By = {
  allocation?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Assignments_Stddev_Pop_Fields = {
  __typename?: 'assignments_stddev_pop_fields';
  allocation?: Maybe<Scalars['Float']>;
  external_price?: Maybe<Scalars['Float']>;
  internal_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "assignments" */
export type Assignments_Stddev_Pop_Order_By = {
  allocation?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Assignments_Stddev_Samp_Fields = {
  __typename?: 'assignments_stddev_samp_fields';
  allocation?: Maybe<Scalars['Float']>;
  external_price?: Maybe<Scalars['Float']>;
  internal_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "assignments" */
export type Assignments_Stddev_Samp_Order_By = {
  allocation?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "assignments" */
export type Assignments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assignments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assignments_Stream_Cursor_Value_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  end_date?: InputMaybe<Scalars['date']>;
  external_price?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_price?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  po?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Assignments_Sum_Fields = {
  __typename?: 'assignments_sum_fields';
  allocation?: Maybe<Scalars['numeric']>;
  external_price?: Maybe<Scalars['numeric']>;
  internal_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "assignments" */
export type Assignments_Sum_Order_By = {
  allocation?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
};

/** update columns of table "assignments" */
export enum Assignments_Update_Column {
  /** column name */
  Allocation = 'allocation',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  ExternalPrice = 'external_price',
  /** column name */
  Id = 'id',
  /** column name */
  InternalPrice = 'internal_price',
  /** column name */
  Name = 'name',
  /** column name */
  Po = 'po',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Assignments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Assignments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Assignments_Set_Input>;
  where: Assignments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Assignments_Var_Pop_Fields = {
  __typename?: 'assignments_var_pop_fields';
  allocation?: Maybe<Scalars['Float']>;
  external_price?: Maybe<Scalars['Float']>;
  internal_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "assignments" */
export type Assignments_Var_Pop_Order_By = {
  allocation?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Assignments_Var_Samp_Fields = {
  __typename?: 'assignments_var_samp_fields';
  allocation?: Maybe<Scalars['Float']>;
  external_price?: Maybe<Scalars['Float']>;
  internal_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "assignments" */
export type Assignments_Var_Samp_Order_By = {
  allocation?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Assignments_Variance_Fields = {
  __typename?: 'assignments_variance_fields';
  allocation?: Maybe<Scalars['Float']>;
  external_price?: Maybe<Scalars['Float']>;
  internal_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "assignments" */
export type Assignments_Variance_Order_By = {
  allocation?: InputMaybe<Order_By>;
  external_price?: InputMaybe<Order_By>;
  internal_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "balance_corrections" */
export type Balance_Corrections = {
  __typename?: 'balance_corrections';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day: Scalars['date'];
  /** An object relationship */
  employee?: Maybe<Employee>;
  employee_id?: Maybe<Scalars['uuid']>;
  hours: Scalars['numeric'];
  id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "balance_corrections" */
export type Balance_Corrections_Aggregate = {
  __typename?: 'balance_corrections_aggregate';
  aggregate?: Maybe<Balance_Corrections_Aggregate_Fields>;
  nodes: Array<Balance_Corrections>;
};

export type Balance_Corrections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Balance_Corrections_Aggregate_Bool_Exp_Count>;
};

export type Balance_Corrections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Balance_Corrections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Balance_Corrections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "balance_corrections" */
export type Balance_Corrections_Aggregate_Fields = {
  __typename?: 'balance_corrections_aggregate_fields';
  avg?: Maybe<Balance_Corrections_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Balance_Corrections_Max_Fields>;
  min?: Maybe<Balance_Corrections_Min_Fields>;
  stddev?: Maybe<Balance_Corrections_Stddev_Fields>;
  stddev_pop?: Maybe<Balance_Corrections_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Balance_Corrections_Stddev_Samp_Fields>;
  sum?: Maybe<Balance_Corrections_Sum_Fields>;
  var_pop?: Maybe<Balance_Corrections_Var_Pop_Fields>;
  var_samp?: Maybe<Balance_Corrections_Var_Samp_Fields>;
  variance?: Maybe<Balance_Corrections_Variance_Fields>;
};


/** aggregate fields of "balance_corrections" */
export type Balance_Corrections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Balance_Corrections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "balance_corrections" */
export type Balance_Corrections_Aggregate_Order_By = {
  avg?: InputMaybe<Balance_Corrections_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Balance_Corrections_Max_Order_By>;
  min?: InputMaybe<Balance_Corrections_Min_Order_By>;
  stddev?: InputMaybe<Balance_Corrections_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Balance_Corrections_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Balance_Corrections_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Balance_Corrections_Sum_Order_By>;
  var_pop?: InputMaybe<Balance_Corrections_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Balance_Corrections_Var_Samp_Order_By>;
  variance?: InputMaybe<Balance_Corrections_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "balance_corrections" */
export type Balance_Corrections_Arr_Rel_Insert_Input = {
  data: Array<Balance_Corrections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Balance_Corrections_On_Conflict>;
};

/** aggregate avg on columns */
export type Balance_Corrections_Avg_Fields = {
  __typename?: 'balance_corrections_avg_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "balance_corrections" */
export type Balance_Corrections_Avg_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "balance_corrections". All fields are combined with a logical 'AND'. */
export type Balance_Corrections_Bool_Exp = {
  _and?: InputMaybe<Array<Balance_Corrections_Bool_Exp>>;
  _not?: InputMaybe<Balance_Corrections_Bool_Exp>;
  _or?: InputMaybe<Array<Balance_Corrections_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  employee?: InputMaybe<Employee_Bool_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  hours?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "balance_corrections" */
export enum Balance_Corrections_Constraint {
  /** unique or primary key constraint on columns "id" */
  BalanceCorrectionsPkey = 'balance_corrections_pkey'
}

/** input type for incrementing numeric columns in table "balance_corrections" */
export type Balance_Corrections_Inc_Input = {
  hours?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "balance_corrections" */
export type Balance_Corrections_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  employee?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  hours?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Balance_Corrections_Max_Fields = {
  __typename?: 'balance_corrections_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  employee_id?: Maybe<Scalars['uuid']>;
  hours?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "balance_corrections" */
export type Balance_Corrections_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  hours?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Balance_Corrections_Min_Fields = {
  __typename?: 'balance_corrections_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  employee_id?: Maybe<Scalars['uuid']>;
  hours?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "balance_corrections" */
export type Balance_Corrections_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  hours?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "balance_corrections" */
export type Balance_Corrections_Mutation_Response = {
  __typename?: 'balance_corrections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Balance_Corrections>;
};

/** on_conflict condition type for table "balance_corrections" */
export type Balance_Corrections_On_Conflict = {
  constraint: Balance_Corrections_Constraint;
  update_columns?: Array<Balance_Corrections_Update_Column>;
  where?: InputMaybe<Balance_Corrections_Bool_Exp>;
};

/** Ordering options when selecting data from "balance_corrections". */
export type Balance_Corrections_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employee_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  hours?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: balance_corrections */
export type Balance_Corrections_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "balance_corrections" */
export enum Balance_Corrections_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Hours = 'hours',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "balance_corrections" */
export type Balance_Corrections_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  hours?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Balance_Corrections_Stddev_Fields = {
  __typename?: 'balance_corrections_stddev_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "balance_corrections" */
export type Balance_Corrections_Stddev_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Balance_Corrections_Stddev_Pop_Fields = {
  __typename?: 'balance_corrections_stddev_pop_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "balance_corrections" */
export type Balance_Corrections_Stddev_Pop_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Balance_Corrections_Stddev_Samp_Fields = {
  __typename?: 'balance_corrections_stddev_samp_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "balance_corrections" */
export type Balance_Corrections_Stddev_Samp_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "balance_corrections" */
export type Balance_Corrections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Balance_Corrections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Balance_Corrections_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  hours?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Balance_Corrections_Sum_Fields = {
  __typename?: 'balance_corrections_sum_fields';
  hours?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "balance_corrections" */
export type Balance_Corrections_Sum_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** update columns of table "balance_corrections" */
export enum Balance_Corrections_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Hours = 'hours',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Balance_Corrections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Balance_Corrections_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Balance_Corrections_Set_Input>;
  where: Balance_Corrections_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Balance_Corrections_Var_Pop_Fields = {
  __typename?: 'balance_corrections_var_pop_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "balance_corrections" */
export type Balance_Corrections_Var_Pop_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Balance_Corrections_Var_Samp_Fields = {
  __typename?: 'balance_corrections_var_samp_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "balance_corrections" */
export type Balance_Corrections_Var_Samp_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Balance_Corrections_Variance_Fields = {
  __typename?: 'balance_corrections_variance_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "balance_corrections" */
export type Balance_Corrections_Variance_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

export type Billing_By_Project_And_Employee_Args = {
  ed?: InputMaybe<Scalars['date']>;
  sd?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "billing_by_project_and_employee_tmp_table" */
export type Billing_By_Project_And_Employee_Tmp_Table = {
  __typename?: 'billing_by_project_and_employee_tmp_table';
  billing?: Maybe<Scalars['numeric']>;
  business_id?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  employee_comission?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  hours?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
  project?: Maybe<Scalars['String']>;
  sales_comission?: Maybe<Scalars['numeric']>;
  sales_person?: Maybe<Scalars['String']>;
  wd?: Maybe<Scalars['bigint']>;
};

export type Billing_By_Project_And_Employee_Tmp_Table_Aggregate = {
  __typename?: 'billing_by_project_and_employee_tmp_table_aggregate';
  aggregate?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Aggregate_Fields>;
  nodes: Array<Billing_By_Project_And_Employee_Tmp_Table>;
};

/** aggregate fields of "billing_by_project_and_employee_tmp_table" */
export type Billing_By_Project_And_Employee_Tmp_Table_Aggregate_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_aggregate_fields';
  avg?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Max_Fields>;
  min?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Min_Fields>;
  stddev?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Stddev_Fields>;
  stddev_pop?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Stddev_Samp_Fields>;
  sum?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Sum_Fields>;
  var_pop?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Var_Pop_Fields>;
  var_samp?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Var_Samp_Fields>;
  variance?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Variance_Fields>;
};


/** aggregate fields of "billing_by_project_and_employee_tmp_table" */
export type Billing_By_Project_And_Employee_Tmp_Table_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Avg_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_avg_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  wd?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "billing_by_project_and_employee_tmp_table". All fields are combined with a logical 'AND'. */
export type Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp = {
  _and?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>>;
  _not?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
  _or?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>>;
  billing?: InputMaybe<Numeric_Comparison_Exp>;
  business_id?: InputMaybe<String_Comparison_Exp>;
  customer?: InputMaybe<String_Comparison_Exp>;
  employee?: InputMaybe<String_Comparison_Exp>;
  employee_comission?: InputMaybe<Numeric_Comparison_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  hours?: InputMaybe<Numeric_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  project?: InputMaybe<String_Comparison_Exp>;
  sales_comission?: InputMaybe<Numeric_Comparison_Exp>;
  sales_person?: InputMaybe<String_Comparison_Exp>;
  wd?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "billing_by_project_and_employee_tmp_table" */
export type Billing_By_Project_And_Employee_Tmp_Table_Inc_Input = {
  billing?: InputMaybe<Scalars['numeric']>;
  employee_comission?: InputMaybe<Scalars['numeric']>;
  hours?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  sales_comission?: InputMaybe<Scalars['numeric']>;
  wd?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "billing_by_project_and_employee_tmp_table" */
export type Billing_By_Project_And_Employee_Tmp_Table_Insert_Input = {
  billing?: InputMaybe<Scalars['numeric']>;
  business_id?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  employee?: InputMaybe<Scalars['String']>;
  employee_comission?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  hours?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  project?: InputMaybe<Scalars['String']>;
  sales_comission?: InputMaybe<Scalars['numeric']>;
  sales_person?: InputMaybe<Scalars['String']>;
  wd?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Max_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_max_fields';
  billing?: Maybe<Scalars['numeric']>;
  business_id?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  employee_comission?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  hours?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
  project?: Maybe<Scalars['String']>;
  sales_comission?: Maybe<Scalars['numeric']>;
  sales_person?: Maybe<Scalars['String']>;
  wd?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Min_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_min_fields';
  billing?: Maybe<Scalars['numeric']>;
  business_id?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  employee_comission?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  hours?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
  project?: Maybe<Scalars['String']>;
  sales_comission?: Maybe<Scalars['numeric']>;
  sales_person?: Maybe<Scalars['String']>;
  wd?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "billing_by_project_and_employee_tmp_table" */
export type Billing_By_Project_And_Employee_Tmp_Table_Mutation_Response = {
  __typename?: 'billing_by_project_and_employee_tmp_table_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_By_Project_And_Employee_Tmp_Table>;
};

/** Ordering options when selecting data from "billing_by_project_and_employee_tmp_table". */
export type Billing_By_Project_And_Employee_Tmp_Table_Order_By = {
  billing?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  customer?: InputMaybe<Order_By>;
  employee?: InputMaybe<Order_By>;
  employee_comission?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  hours?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  project?: InputMaybe<Order_By>;
  sales_comission?: InputMaybe<Order_By>;
  sales_person?: InputMaybe<Order_By>;
  wd?: InputMaybe<Order_By>;
};

/** select columns of table "billing_by_project_and_employee_tmp_table" */
export enum Billing_By_Project_And_Employee_Tmp_Table_Select_Column {
  /** column name */
  Billing = 'billing',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  Customer = 'customer',
  /** column name */
  Employee = 'employee',
  /** column name */
  EmployeeComission = 'employee_comission',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Hours = 'hours',
  /** column name */
  Price = 'price',
  /** column name */
  Project = 'project',
  /** column name */
  SalesComission = 'sales_comission',
  /** column name */
  SalesPerson = 'sales_person',
  /** column name */
  Wd = 'wd'
}

/** input type for updating data in table "billing_by_project_and_employee_tmp_table" */
export type Billing_By_Project_And_Employee_Tmp_Table_Set_Input = {
  billing?: InputMaybe<Scalars['numeric']>;
  business_id?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  employee?: InputMaybe<Scalars['String']>;
  employee_comission?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  hours?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  project?: InputMaybe<Scalars['String']>;
  sales_comission?: InputMaybe<Scalars['numeric']>;
  sales_person?: InputMaybe<Scalars['String']>;
  wd?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Stddev_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_stddev_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  wd?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Stddev_Pop_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_stddev_pop_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  wd?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Stddev_Samp_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_stddev_samp_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  wd?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "billing_by_project_and_employee_tmp_table" */
export type Billing_By_Project_And_Employee_Tmp_Table_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_By_Project_And_Employee_Tmp_Table_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billing_By_Project_And_Employee_Tmp_Table_Stream_Cursor_Value_Input = {
  billing?: InputMaybe<Scalars['numeric']>;
  business_id?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  employee?: InputMaybe<Scalars['String']>;
  employee_comission?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  hours?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  project?: InputMaybe<Scalars['String']>;
  sales_comission?: InputMaybe<Scalars['numeric']>;
  sales_person?: InputMaybe<Scalars['String']>;
  wd?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Sum_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_sum_fields';
  billing?: Maybe<Scalars['numeric']>;
  employee_comission?: Maybe<Scalars['numeric']>;
  hours?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['numeric']>;
  sales_comission?: Maybe<Scalars['numeric']>;
  wd?: Maybe<Scalars['bigint']>;
};

export type Billing_By_Project_And_Employee_Tmp_Table_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Set_Input>;
  where: Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Var_Pop_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_var_pop_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  wd?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Var_Samp_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_var_samp_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  wd?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Billing_By_Project_And_Employee_Tmp_Table_Variance_Fields = {
  __typename?: 'billing_by_project_and_employee_tmp_table_variance_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  wd?: Maybe<Scalars['Float']>;
};

export type Calendar_Args = {
  from_date?: InputMaybe<Scalars['date']>;
  to_date?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "calendar_tmp_table" */
export type Calendar_Tmp_Table = {
  __typename?: 'calendar_tmp_table';
  day?: Maybe<Scalars['date']>;
  paid_holiday?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
};

export type Calendar_Tmp_Table_Aggregate = {
  __typename?: 'calendar_tmp_table_aggregate';
  aggregate?: Maybe<Calendar_Tmp_Table_Aggregate_Fields>;
  nodes: Array<Calendar_Tmp_Table>;
};

/** aggregate fields of "calendar_tmp_table" */
export type Calendar_Tmp_Table_Aggregate_Fields = {
  __typename?: 'calendar_tmp_table_aggregate_fields';
  avg?: Maybe<Calendar_Tmp_Table_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Calendar_Tmp_Table_Max_Fields>;
  min?: Maybe<Calendar_Tmp_Table_Min_Fields>;
  stddev?: Maybe<Calendar_Tmp_Table_Stddev_Fields>;
  stddev_pop?: Maybe<Calendar_Tmp_Table_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Calendar_Tmp_Table_Stddev_Samp_Fields>;
  sum?: Maybe<Calendar_Tmp_Table_Sum_Fields>;
  var_pop?: Maybe<Calendar_Tmp_Table_Var_Pop_Fields>;
  var_samp?: Maybe<Calendar_Tmp_Table_Var_Samp_Fields>;
  variance?: Maybe<Calendar_Tmp_Table_Variance_Fields>;
};


/** aggregate fields of "calendar_tmp_table" */
export type Calendar_Tmp_Table_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Calendar_Tmp_Table_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Calendar_Tmp_Table_Avg_Fields = {
  __typename?: 'calendar_tmp_table_avg_fields';
  paid_holiday?: Maybe<Scalars['Float']>;
  weekday?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "calendar_tmp_table". All fields are combined with a logical 'AND'. */
export type Calendar_Tmp_Table_Bool_Exp = {
  _and?: InputMaybe<Array<Calendar_Tmp_Table_Bool_Exp>>;
  _not?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
  _or?: InputMaybe<Array<Calendar_Tmp_Table_Bool_Exp>>;
  day?: InputMaybe<Date_Comparison_Exp>;
  paid_holiday?: InputMaybe<Int_Comparison_Exp>;
  weekday?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "calendar_tmp_table" */
export type Calendar_Tmp_Table_Inc_Input = {
  paid_holiday?: InputMaybe<Scalars['Int']>;
  weekday?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "calendar_tmp_table" */
export type Calendar_Tmp_Table_Insert_Input = {
  day?: InputMaybe<Scalars['date']>;
  paid_holiday?: InputMaybe<Scalars['Int']>;
  weekday?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Calendar_Tmp_Table_Max_Fields = {
  __typename?: 'calendar_tmp_table_max_fields';
  day?: Maybe<Scalars['date']>;
  paid_holiday?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Calendar_Tmp_Table_Min_Fields = {
  __typename?: 'calendar_tmp_table_min_fields';
  day?: Maybe<Scalars['date']>;
  paid_holiday?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "calendar_tmp_table" */
export type Calendar_Tmp_Table_Mutation_Response = {
  __typename?: 'calendar_tmp_table_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Calendar_Tmp_Table>;
};

/** Ordering options when selecting data from "calendar_tmp_table". */
export type Calendar_Tmp_Table_Order_By = {
  day?: InputMaybe<Order_By>;
  paid_holiday?: InputMaybe<Order_By>;
  weekday?: InputMaybe<Order_By>;
};

/** select columns of table "calendar_tmp_table" */
export enum Calendar_Tmp_Table_Select_Column {
  /** column name */
  Day = 'day',
  /** column name */
  PaidHoliday = 'paid_holiday',
  /** column name */
  Weekday = 'weekday'
}

/** input type for updating data in table "calendar_tmp_table" */
export type Calendar_Tmp_Table_Set_Input = {
  day?: InputMaybe<Scalars['date']>;
  paid_holiday?: InputMaybe<Scalars['Int']>;
  weekday?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Calendar_Tmp_Table_Stddev_Fields = {
  __typename?: 'calendar_tmp_table_stddev_fields';
  paid_holiday?: Maybe<Scalars['Float']>;
  weekday?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Calendar_Tmp_Table_Stddev_Pop_Fields = {
  __typename?: 'calendar_tmp_table_stddev_pop_fields';
  paid_holiday?: Maybe<Scalars['Float']>;
  weekday?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Calendar_Tmp_Table_Stddev_Samp_Fields = {
  __typename?: 'calendar_tmp_table_stddev_samp_fields';
  paid_holiday?: Maybe<Scalars['Float']>;
  weekday?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "calendar_tmp_table" */
export type Calendar_Tmp_Table_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Calendar_Tmp_Table_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Calendar_Tmp_Table_Stream_Cursor_Value_Input = {
  day?: InputMaybe<Scalars['date']>;
  paid_holiday?: InputMaybe<Scalars['Int']>;
  weekday?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Calendar_Tmp_Table_Sum_Fields = {
  __typename?: 'calendar_tmp_table_sum_fields';
  paid_holiday?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
};

export type Calendar_Tmp_Table_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Calendar_Tmp_Table_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Calendar_Tmp_Table_Set_Input>;
  where: Calendar_Tmp_Table_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Calendar_Tmp_Table_Var_Pop_Fields = {
  __typename?: 'calendar_tmp_table_var_pop_fields';
  paid_holiday?: Maybe<Scalars['Float']>;
  weekday?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Calendar_Tmp_Table_Var_Samp_Fields = {
  __typename?: 'calendar_tmp_table_var_samp_fields';
  paid_holiday?: Maybe<Scalars['Float']>;
  weekday?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Calendar_Tmp_Table_Variance_Fields = {
  __typename?: 'calendar_tmp_table_variance_fields';
  paid_holiday?: Maybe<Scalars['Float']>;
  weekday?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "company_monthly_billing_report" */
export type Company_Monthly_Billing_Report = {
  __typename?: 'company_monthly_billing_report';
  /** An object relationship */
  assignment?: Maybe<Assignments>;
  assignment_id?: Maybe<Scalars['uuid']>;
  billing?: Maybe<Scalars['numeric']>;
  customer_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  employee?: Maybe<Employee>;
  employee_comission?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  hours?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  report_month?: Maybe<Scalars['date']>;
  sales_comission?: Maybe<Scalars['numeric']>;
  sales_person_id?: Maybe<Scalars['uuid']>;
  working_days?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "company_monthly_billing_report" */
export type Company_Monthly_Billing_Report_Aggregate = {
  __typename?: 'company_monthly_billing_report_aggregate';
  aggregate?: Maybe<Company_Monthly_Billing_Report_Aggregate_Fields>;
  nodes: Array<Company_Monthly_Billing_Report>;
};

/** aggregate fields of "company_monthly_billing_report" */
export type Company_Monthly_Billing_Report_Aggregate_Fields = {
  __typename?: 'company_monthly_billing_report_aggregate_fields';
  avg?: Maybe<Company_Monthly_Billing_Report_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Monthly_Billing_Report_Max_Fields>;
  min?: Maybe<Company_Monthly_Billing_Report_Min_Fields>;
  stddev?: Maybe<Company_Monthly_Billing_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Monthly_Billing_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Monthly_Billing_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Monthly_Billing_Report_Sum_Fields>;
  var_pop?: Maybe<Company_Monthly_Billing_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Monthly_Billing_Report_Var_Samp_Fields>;
  variance?: Maybe<Company_Monthly_Billing_Report_Variance_Fields>;
};


/** aggregate fields of "company_monthly_billing_report" */
export type Company_Monthly_Billing_Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Monthly_Billing_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Monthly_Billing_Report_Avg_Fields = {
  __typename?: 'company_monthly_billing_report_avg_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_monthly_billing_report". All fields are combined with a logical 'AND'. */
export type Company_Monthly_Billing_Report_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Monthly_Billing_Report_Bool_Exp>>;
  _not?: InputMaybe<Company_Monthly_Billing_Report_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Monthly_Billing_Report_Bool_Exp>>;
  assignment?: InputMaybe<Assignments_Bool_Exp>;
  assignment_id?: InputMaybe<Uuid_Comparison_Exp>;
  billing?: InputMaybe<Numeric_Comparison_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  employee?: InputMaybe<Employee_Bool_Exp>;
  employee_comission?: InputMaybe<Numeric_Comparison_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  hours?: InputMaybe<Numeric_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  report_month?: InputMaybe<Date_Comparison_Exp>;
  sales_comission?: InputMaybe<Numeric_Comparison_Exp>;
  sales_person_id?: InputMaybe<Uuid_Comparison_Exp>;
  working_days?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Company_Monthly_Billing_Report_Max_Fields = {
  __typename?: 'company_monthly_billing_report_max_fields';
  assignment_id?: Maybe<Scalars['uuid']>;
  billing?: Maybe<Scalars['numeric']>;
  customer_id?: Maybe<Scalars['uuid']>;
  employee_comission?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  hours?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['uuid']>;
  report_month?: Maybe<Scalars['date']>;
  sales_comission?: Maybe<Scalars['numeric']>;
  sales_person_id?: Maybe<Scalars['uuid']>;
  working_days?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Company_Monthly_Billing_Report_Min_Fields = {
  __typename?: 'company_monthly_billing_report_min_fields';
  assignment_id?: Maybe<Scalars['uuid']>;
  billing?: Maybe<Scalars['numeric']>;
  customer_id?: Maybe<Scalars['uuid']>;
  employee_comission?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  hours?: Maybe<Scalars['numeric']>;
  project_id?: Maybe<Scalars['uuid']>;
  report_month?: Maybe<Scalars['date']>;
  sales_comission?: Maybe<Scalars['numeric']>;
  sales_person_id?: Maybe<Scalars['uuid']>;
  working_days?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "company_monthly_billing_report". */
export type Company_Monthly_Billing_Report_Order_By = {
  assignment?: InputMaybe<Assignments_Order_By>;
  assignment_id?: InputMaybe<Order_By>;
  billing?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employee_Order_By>;
  employee_comission?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  hours?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  sales_comission?: InputMaybe<Order_By>;
  sales_person_id?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** select columns of table "company_monthly_billing_report" */
export enum Company_Monthly_Billing_Report_Select_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  Billing = 'billing',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EmployeeComission = 'employee_comission',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Hours = 'hours',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ReportMonth = 'report_month',
  /** column name */
  SalesComission = 'sales_comission',
  /** column name */
  SalesPersonId = 'sales_person_id',
  /** column name */
  WorkingDays = 'working_days'
}

/** aggregate stddev on columns */
export type Company_Monthly_Billing_Report_Stddev_Fields = {
  __typename?: 'company_monthly_billing_report_stddev_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Monthly_Billing_Report_Stddev_Pop_Fields = {
  __typename?: 'company_monthly_billing_report_stddev_pop_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Monthly_Billing_Report_Stddev_Samp_Fields = {
  __typename?: 'company_monthly_billing_report_stddev_samp_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_monthly_billing_report" */
export type Company_Monthly_Billing_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Monthly_Billing_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Monthly_Billing_Report_Stream_Cursor_Value_Input = {
  assignment_id?: InputMaybe<Scalars['uuid']>;
  billing?: InputMaybe<Scalars['numeric']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  employee_comission?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  hours?: InputMaybe<Scalars['numeric']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  report_month?: InputMaybe<Scalars['date']>;
  sales_comission?: InputMaybe<Scalars['numeric']>;
  sales_person_id?: InputMaybe<Scalars['uuid']>;
  working_days?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Company_Monthly_Billing_Report_Sum_Fields = {
  __typename?: 'company_monthly_billing_report_sum_fields';
  billing?: Maybe<Scalars['numeric']>;
  employee_comission?: Maybe<Scalars['numeric']>;
  hours?: Maybe<Scalars['numeric']>;
  sales_comission?: Maybe<Scalars['numeric']>;
  working_days?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Company_Monthly_Billing_Report_Var_Pop_Fields = {
  __typename?: 'company_monthly_billing_report_var_pop_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Monthly_Billing_Report_Var_Samp_Fields = {
  __typename?: 'company_monthly_billing_report_var_samp_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Monthly_Billing_Report_Variance_Fields = {
  __typename?: 'company_monthly_billing_report_variance_fields';
  billing?: Maybe<Scalars['Float']>;
  employee_comission?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  sales_comission?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customers" */
export type Customers = {
  __typename?: 'customers';
  business_id?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['json']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  is_own: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "customers" */
export type CustomersContactArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "customers" */
export type CustomersProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


/** columns and relationships of "customers" */
export type CustomersProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** aggregated selection of "customers" */
export type Customers_Aggregate = {
  __typename?: 'customers_aggregate';
  aggregate?: Maybe<Customers_Aggregate_Fields>;
  nodes: Array<Customers>;
};

/** aggregate fields of "customers" */
export type Customers_Aggregate_Fields = {
  __typename?: 'customers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customers_Max_Fields>;
  min?: Maybe<Customers_Min_Fields>;
};


/** aggregate fields of "customers" */
export type Customers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customers". All fields are combined with a logical 'AND'. */
export type Customers_Bool_Exp = {
  _and?: InputMaybe<Array<Customers_Bool_Exp>>;
  _not?: InputMaybe<Customers_Bool_Exp>;
  _or?: InputMaybe<Array<Customers_Bool_Exp>>;
  business_id?: InputMaybe<String_Comparison_Exp>;
  contact?: InputMaybe<Json_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_own?: InputMaybe<Boolean_Comparison_Exp>;
  logo?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  projects?: InputMaybe<Projects_Bool_Exp>;
  projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customers" */
export enum Customers_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomersPkey = 'customers_pkey'
}

/** input type for inserting data into table "customers" */
export type Customers_Insert_Input = {
  business_id?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['json']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_own?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Customers_Max_Fields = {
  __typename?: 'customers_max_fields';
  business_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Customers_Min_Fields = {
  __typename?: 'customers_min_fields';
  business_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "customers" */
export type Customers_Mutation_Response = {
  __typename?: 'customers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customers>;
};

/** input type for inserting object relation for remote table "customers" */
export type Customers_Obj_Rel_Insert_Input = {
  data: Customers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Customers_On_Conflict>;
};

/** on_conflict condition type for table "customers" */
export type Customers_On_Conflict = {
  constraint: Customers_Constraint;
  update_columns?: Array<Customers_Update_Column>;
  where?: InputMaybe<Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "customers". */
export type Customers_Order_By = {
  business_id?: InputMaybe<Order_By>;
  contact?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_own?: InputMaybe<Order_By>;
  logo?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customers */
export type Customers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customers" */
export enum Customers_Select_Column {
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsOwn = 'is_own',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "customers" */
export type Customers_Set_Input = {
  business_id?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['json']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_own?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "customers" */
export type Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customers_Stream_Cursor_Value_Input = {
  business_id?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['json']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_own?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "customers" */
export enum Customers_Update_Column {
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  Contact = 'contact',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsOwn = 'is_own',
  /** column name */
  Logo = 'logo',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Customers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customers_Set_Input>;
  where: Customers_Bool_Exp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** Boolean expression to compare columns of type "daterange". All fields are combined with logical 'AND'. */
export type Daterange_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['daterange']>;
  _gt?: InputMaybe<Scalars['daterange']>;
  _gte?: InputMaybe<Scalars['daterange']>;
  _in?: InputMaybe<Array<Scalars['daterange']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['daterange']>;
  _lte?: InputMaybe<Scalars['daterange']>;
  _neq?: InputMaybe<Scalars['daterange']>;
  _nin?: InputMaybe<Array<Scalars['daterange']>>;
};

/** columns and relationships of "employees" */
export type Employee = {
  __typename?: 'employee';
  /** An array relationship */
  assignments: Array<Assignments>;
  /** An aggregate relationship */
  assignments_aggregate: Assignments_Aggregate;
  avatar?: Maybe<Scalars['String']>;
  /** An object relationship */
  balance?: Maybe<Employee_Balance>;
  /** An array relationship */
  balance_corrections: Array<Balance_Corrections>;
  /** An aggregate relationship */
  balance_corrections_aggregate: Balance_Corrections_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  employee_allocations: Array<Employee_Allocations>;
  /** An aggregate relationship */
  employee_allocations_aggregate: Employee_Allocations_Aggregate;
  employment_end?: Maybe<Scalars['date']>;
  employment_start?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  monthly_hours_report: Array<Employee_Monthly_Hours_Report>;
  /** An aggregate relationship */
  monthly_hours_report_aggregate: Employee_Monthly_Hours_Report_Aggregate;
  /** An array relationship */
  payroll_prediction: Array<Employee_Monthly_Predicted_Payroll>;
  /** An aggregate relationship */
  payroll_prediction_aggregate: Employee_Monthly_Predicted_Payroll_Aggregate;
  /** An array relationship */
  payroll_reports: Array<Employee_Monthly_Payroll_Report>;
  /** An aggregate relationship */
  payroll_reports_aggregate: Employee_Monthly_Payroll_Report_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "employees" */
export type EmployeeAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeeAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeeBalance_CorrectionsArgs = {
  distinct_on?: InputMaybe<Array<Balance_Corrections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Corrections_Order_By>>;
  where?: InputMaybe<Balance_Corrections_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeeBalance_Corrections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Balance_Corrections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Corrections_Order_By>>;
  where?: InputMaybe<Balance_Corrections_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeeEmployee_AllocationsArgs = {
  distinct_on?: InputMaybe<Array<Employee_Allocations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Allocations_Order_By>>;
  where?: InputMaybe<Employee_Allocations_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeeEmployee_Allocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Allocations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Allocations_Order_By>>;
  where?: InputMaybe<Employee_Allocations_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeeMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "employees" */
export type EmployeeMonthly_Hours_ReportArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Hours_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Hours_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeeMonthly_Hours_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Hours_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Hours_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeePayroll_PredictionArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeePayroll_Prediction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeePayroll_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
};


/** columns and relationships of "employees" */
export type EmployeePayroll_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
};

/** aggregated selection of "employees" */
export type Employee_Aggregate = {
  __typename?: 'employee_aggregate';
  aggregate?: Maybe<Employee_Aggregate_Fields>;
  nodes: Array<Employee>;
};

/** aggregate fields of "employees" */
export type Employee_Aggregate_Fields = {
  __typename?: 'employee_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Employee_Max_Fields>;
  min?: Maybe<Employee_Min_Fields>;
};


/** aggregate fields of "employees" */
export type Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "employee_aggregated_hours" */
export type Employee_Aggregated_Hours = {
  __typename?: 'employee_aggregated_hours';
  assignment_id?: Maybe<Scalars['uuid']>;
  employee_id?: Maybe<Scalars['uuid']>;
  external_billing?: Maybe<Scalars['numeric']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "employee_aggregated_hours" */
export type Employee_Aggregated_Hours_Aggregate = {
  __typename?: 'employee_aggregated_hours_aggregate';
  aggregate?: Maybe<Employee_Aggregated_Hours_Aggregate_Fields>;
  nodes: Array<Employee_Aggregated_Hours>;
};

/** aggregate fields of "employee_aggregated_hours" */
export type Employee_Aggregated_Hours_Aggregate_Fields = {
  __typename?: 'employee_aggregated_hours_aggregate_fields';
  avg?: Maybe<Employee_Aggregated_Hours_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Aggregated_Hours_Max_Fields>;
  min?: Maybe<Employee_Aggregated_Hours_Min_Fields>;
  stddev?: Maybe<Employee_Aggregated_Hours_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Aggregated_Hours_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Aggregated_Hours_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Aggregated_Hours_Sum_Fields>;
  var_pop?: Maybe<Employee_Aggregated_Hours_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Aggregated_Hours_Var_Samp_Fields>;
  variance?: Maybe<Employee_Aggregated_Hours_Variance_Fields>;
};


/** aggregate fields of "employee_aggregated_hours" */
export type Employee_Aggregated_Hours_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Aggregated_Hours_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Employee_Aggregated_Hours_Avg_Fields = {
  __typename?: 'employee_aggregated_hours_avg_fields';
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "employee_aggregated_hours". All fields are combined with a logical 'AND'. */
export type Employee_Aggregated_Hours_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Aggregated_Hours_Bool_Exp>>;
  _not?: InputMaybe<Employee_Aggregated_Hours_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Aggregated_Hours_Bool_Exp>>;
  assignment_id?: InputMaybe<Uuid_Comparison_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  external_billing?: InputMaybe<Numeric_Comparison_Exp>;
  internal_billing?: InputMaybe<Numeric_Comparison_Exp>;
  total?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for inserting data into table "employee_aggregated_hours" */
export type Employee_Aggregated_Hours_Insert_Input = {
  assignment_id?: InputMaybe<Scalars['uuid']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  external_billing?: InputMaybe<Scalars['numeric']>;
  internal_billing?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Employee_Aggregated_Hours_Max_Fields = {
  __typename?: 'employee_aggregated_hours_max_fields';
  assignment_id?: Maybe<Scalars['uuid']>;
  employee_id?: Maybe<Scalars['uuid']>;
  external_billing?: Maybe<Scalars['numeric']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Employee_Aggregated_Hours_Min_Fields = {
  __typename?: 'employee_aggregated_hours_min_fields';
  assignment_id?: Maybe<Scalars['uuid']>;
  employee_id?: Maybe<Scalars['uuid']>;
  external_billing?: Maybe<Scalars['numeric']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting object relation for remote table "employee_aggregated_hours" */
export type Employee_Aggregated_Hours_Obj_Rel_Insert_Input = {
  data: Employee_Aggregated_Hours_Insert_Input;
};

/** Ordering options when selecting data from "employee_aggregated_hours". */
export type Employee_Aggregated_Hours_Order_By = {
  assignment_id?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "employee_aggregated_hours" */
export enum Employee_Aggregated_Hours_Select_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  ExternalBilling = 'external_billing',
  /** column name */
  InternalBilling = 'internal_billing',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Employee_Aggregated_Hours_Stddev_Fields = {
  __typename?: 'employee_aggregated_hours_stddev_fields';
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Employee_Aggregated_Hours_Stddev_Pop_Fields = {
  __typename?: 'employee_aggregated_hours_stddev_pop_fields';
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Employee_Aggregated_Hours_Stddev_Samp_Fields = {
  __typename?: 'employee_aggregated_hours_stddev_samp_fields';
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "employee_aggregated_hours" */
export type Employee_Aggregated_Hours_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Aggregated_Hours_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Aggregated_Hours_Stream_Cursor_Value_Input = {
  assignment_id?: InputMaybe<Scalars['uuid']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  external_billing?: InputMaybe<Scalars['numeric']>;
  internal_billing?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Employee_Aggregated_Hours_Sum_Fields = {
  __typename?: 'employee_aggregated_hours_sum_fields';
  external_billing?: Maybe<Scalars['numeric']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Employee_Aggregated_Hours_Var_Pop_Fields = {
  __typename?: 'employee_aggregated_hours_var_pop_fields';
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Employee_Aggregated_Hours_Var_Samp_Fields = {
  __typename?: 'employee_aggregated_hours_var_samp_fields';
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Employee_Aggregated_Hours_Variance_Fields = {
  __typename?: 'employee_aggregated_hours_variance_fields';
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "employee_allocations" */
export type Employee_Allocations = {
  __typename?: 'employee_allocations';
  allocation: Scalars['numeric'];
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  employee?: Maybe<Employee>;
  employee_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  start_date: Scalars['date'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "employee_allocations" */
export type Employee_AllocationsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "employee_allocations" */
export type Employee_Allocations_Aggregate = {
  __typename?: 'employee_allocations_aggregate';
  aggregate?: Maybe<Employee_Allocations_Aggregate_Fields>;
  nodes: Array<Employee_Allocations>;
};

export type Employee_Allocations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Employee_Allocations_Aggregate_Bool_Exp_Count>;
};

export type Employee_Allocations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employee_Allocations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Allocations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employee_allocations" */
export type Employee_Allocations_Aggregate_Fields = {
  __typename?: 'employee_allocations_aggregate_fields';
  avg?: Maybe<Employee_Allocations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Allocations_Max_Fields>;
  min?: Maybe<Employee_Allocations_Min_Fields>;
  stddev?: Maybe<Employee_Allocations_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Allocations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Allocations_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Allocations_Sum_Fields>;
  var_pop?: Maybe<Employee_Allocations_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Allocations_Var_Samp_Fields>;
  variance?: Maybe<Employee_Allocations_Variance_Fields>;
};


/** aggregate fields of "employee_allocations" */
export type Employee_Allocations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Allocations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee_allocations" */
export type Employee_Allocations_Aggregate_Order_By = {
  avg?: InputMaybe<Employee_Allocations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Allocations_Max_Order_By>;
  min?: InputMaybe<Employee_Allocations_Min_Order_By>;
  stddev?: InputMaybe<Employee_Allocations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employee_Allocations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employee_Allocations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employee_Allocations_Sum_Order_By>;
  var_pop?: InputMaybe<Employee_Allocations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employee_Allocations_Var_Samp_Order_By>;
  variance?: InputMaybe<Employee_Allocations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Employee_Allocations_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "employee_allocations" */
export type Employee_Allocations_Arr_Rel_Insert_Input = {
  data: Array<Employee_Allocations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Employee_Allocations_On_Conflict>;
};

/** aggregate avg on columns */
export type Employee_Allocations_Avg_Fields = {
  __typename?: 'employee_allocations_avg_fields';
  allocation?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employee_allocations" */
export type Employee_Allocations_Avg_Order_By = {
  allocation?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employee_allocations". All fields are combined with a logical 'AND'. */
export type Employee_Allocations_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Allocations_Bool_Exp>>;
  _not?: InputMaybe<Employee_Allocations_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Allocations_Bool_Exp>>;
  allocation?: InputMaybe<Numeric_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  employee?: InputMaybe<Employee_Bool_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "employee_allocations" */
export enum Employee_Allocations_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmployeeAllocationsPkey = 'employee_allocations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Employee_Allocations_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Employee_Allocations_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Employee_Allocations_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "employee_allocations" */
export type Employee_Allocations_Inc_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "employee_allocations" */
export type Employee_Allocations_Insert_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employee?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Employee_Allocations_Max_Fields = {
  __typename?: 'employee_allocations_max_fields';
  allocation?: Maybe<Scalars['numeric']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "employee_allocations" */
export type Employee_Allocations_Max_Order_By = {
  allocation?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employee_Allocations_Min_Fields = {
  __typename?: 'employee_allocations_min_fields';
  allocation?: Maybe<Scalars['numeric']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  employee_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "employee_allocations" */
export type Employee_Allocations_Min_Order_By = {
  allocation?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employee_allocations" */
export type Employee_Allocations_Mutation_Response = {
  __typename?: 'employee_allocations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employee_Allocations>;
};

/** on_conflict condition type for table "employee_allocations" */
export type Employee_Allocations_On_Conflict = {
  constraint: Employee_Allocations_Constraint;
  update_columns?: Array<Employee_Allocations_Update_Column>;
  where?: InputMaybe<Employee_Allocations_Bool_Exp>;
};

/** Ordering options when selecting data from "employee_allocations". */
export type Employee_Allocations_Order_By = {
  allocation?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  employee?: InputMaybe<Employee_Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employee_allocations */
export type Employee_Allocations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Employee_Allocations_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "employee_allocations" */
export enum Employee_Allocations_Select_Column {
  /** column name */
  Allocation = 'allocation',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "employee_allocations" */
export type Employee_Allocations_Set_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Employee_Allocations_Stddev_Fields = {
  __typename?: 'employee_allocations_stddev_fields';
  allocation?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employee_allocations" */
export type Employee_Allocations_Stddev_Order_By = {
  allocation?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employee_Allocations_Stddev_Pop_Fields = {
  __typename?: 'employee_allocations_stddev_pop_fields';
  allocation?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employee_allocations" */
export type Employee_Allocations_Stddev_Pop_Order_By = {
  allocation?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employee_Allocations_Stddev_Samp_Fields = {
  __typename?: 'employee_allocations_stddev_samp_fields';
  allocation?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employee_allocations" */
export type Employee_Allocations_Stddev_Samp_Order_By = {
  allocation?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employee_allocations" */
export type Employee_Allocations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Allocations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Allocations_Stream_Cursor_Value_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  start_date?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Employee_Allocations_Sum_Fields = {
  __typename?: 'employee_allocations_sum_fields';
  allocation?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "employee_allocations" */
export type Employee_Allocations_Sum_Order_By = {
  allocation?: InputMaybe<Order_By>;
};

/** update columns of table "employee_allocations" */
export enum Employee_Allocations_Update_Column {
  /** column name */
  Allocation = 'allocation',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Employee_Allocations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Employee_Allocations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Employee_Allocations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Employee_Allocations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Employee_Allocations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Employee_Allocations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Employee_Allocations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employee_Allocations_Set_Input>;
  where: Employee_Allocations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Employee_Allocations_Var_Pop_Fields = {
  __typename?: 'employee_allocations_var_pop_fields';
  allocation?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employee_allocations" */
export type Employee_Allocations_Var_Pop_Order_By = {
  allocation?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employee_Allocations_Var_Samp_Fields = {
  __typename?: 'employee_allocations_var_samp_fields';
  allocation?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employee_allocations" */
export type Employee_Allocations_Var_Samp_Order_By = {
  allocation?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employee_Allocations_Variance_Fields = {
  __typename?: 'employee_allocations_variance_fields';
  allocation?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employee_allocations" */
export type Employee_Allocations_Variance_Order_By = {
  allocation?: InputMaybe<Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Employee_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "employee_balance" */
export type Employee_Balance = {
  __typename?: 'employee_balance';
  employee_id?: Maybe<Scalars['uuid']>;
  report_day?: Maybe<Scalars['date']>;
  rolling_balance?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "employee_balance" */
export type Employee_Balance_Aggregate = {
  __typename?: 'employee_balance_aggregate';
  aggregate?: Maybe<Employee_Balance_Aggregate_Fields>;
  nodes: Array<Employee_Balance>;
};

/** aggregate fields of "employee_balance" */
export type Employee_Balance_Aggregate_Fields = {
  __typename?: 'employee_balance_aggregate_fields';
  avg?: Maybe<Employee_Balance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Balance_Max_Fields>;
  min?: Maybe<Employee_Balance_Min_Fields>;
  stddev?: Maybe<Employee_Balance_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Balance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Balance_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Balance_Sum_Fields>;
  var_pop?: Maybe<Employee_Balance_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Balance_Var_Samp_Fields>;
  variance?: Maybe<Employee_Balance_Variance_Fields>;
};


/** aggregate fields of "employee_balance" */
export type Employee_Balance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Balance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Employee_Balance_Avg_Fields = {
  __typename?: 'employee_balance_avg_fields';
  rolling_balance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "employee_balance". All fields are combined with a logical 'AND'. */
export type Employee_Balance_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Balance_Bool_Exp>>;
  _not?: InputMaybe<Employee_Balance_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Balance_Bool_Exp>>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  report_day?: InputMaybe<Date_Comparison_Exp>;
  rolling_balance?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for inserting data into table "employee_balance" */
export type Employee_Balance_Insert_Input = {
  employee_id?: InputMaybe<Scalars['uuid']>;
  report_day?: InputMaybe<Scalars['date']>;
  rolling_balance?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Employee_Balance_Max_Fields = {
  __typename?: 'employee_balance_max_fields';
  employee_id?: Maybe<Scalars['uuid']>;
  report_day?: Maybe<Scalars['date']>;
  rolling_balance?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Employee_Balance_Min_Fields = {
  __typename?: 'employee_balance_min_fields';
  employee_id?: Maybe<Scalars['uuid']>;
  report_day?: Maybe<Scalars['date']>;
  rolling_balance?: Maybe<Scalars['numeric']>;
};

/** input type for inserting object relation for remote table "employee_balance" */
export type Employee_Balance_Obj_Rel_Insert_Input = {
  data: Employee_Balance_Insert_Input;
};

/** Ordering options when selecting data from "employee_balance". */
export type Employee_Balance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  report_day?: InputMaybe<Order_By>;
  rolling_balance?: InputMaybe<Order_By>;
};

/** select columns of table "employee_balance" */
export enum Employee_Balance_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  ReportDay = 'report_day',
  /** column name */
  RollingBalance = 'rolling_balance'
}

/** aggregate stddev on columns */
export type Employee_Balance_Stddev_Fields = {
  __typename?: 'employee_balance_stddev_fields';
  rolling_balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Employee_Balance_Stddev_Pop_Fields = {
  __typename?: 'employee_balance_stddev_pop_fields';
  rolling_balance?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Employee_Balance_Stddev_Samp_Fields = {
  __typename?: 'employee_balance_stddev_samp_fields';
  rolling_balance?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "employee_balance" */
export type Employee_Balance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Balance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Balance_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['uuid']>;
  report_day?: InputMaybe<Scalars['date']>;
  rolling_balance?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Employee_Balance_Sum_Fields = {
  __typename?: 'employee_balance_sum_fields';
  rolling_balance?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Employee_Balance_Var_Pop_Fields = {
  __typename?: 'employee_balance_var_pop_fields';
  rolling_balance?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Employee_Balance_Var_Samp_Fields = {
  __typename?: 'employee_balance_var_samp_fields';
  rolling_balance?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Employee_Balance_Variance_Fields = {
  __typename?: 'employee_balance_variance_fields';
  rolling_balance?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "employees". All fields are combined with a logical 'AND'. */
export type Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Bool_Exp>>;
  _not?: InputMaybe<Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Bool_Exp>>;
  assignments?: InputMaybe<Assignments_Bool_Exp>;
  assignments_aggregate?: InputMaybe<Assignments_Aggregate_Bool_Exp>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  balance?: InputMaybe<Employee_Balance_Bool_Exp>;
  balance_corrections?: InputMaybe<Balance_Corrections_Bool_Exp>;
  balance_corrections_aggregate?: InputMaybe<Balance_Corrections_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employee_allocations?: InputMaybe<Employee_Allocations_Bool_Exp>;
  employee_allocations_aggregate?: InputMaybe<Employee_Allocations_Aggregate_Bool_Exp>;
  employment_end?: InputMaybe<Date_Comparison_Exp>;
  employment_start?: InputMaybe<Date_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  monthly_hours_report?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
  monthly_hours_report_aggregate?: InputMaybe<Employee_Monthly_Hours_Report_Aggregate_Bool_Exp>;
  payroll_prediction?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
  payroll_prediction_aggregate?: InputMaybe<Employee_Monthly_Predicted_Payroll_Aggregate_Bool_Exp>;
  payroll_reports?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
  payroll_reports_aggregate?: InputMaybe<Employee_Monthly_Payroll_Report_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "employees" */
export enum Employee_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmployeesPkey = 'employees_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Employee_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Employee_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Employee_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "employees" */
export type Employee_Insert_Input = {
  assignments?: InputMaybe<Assignments_Arr_Rel_Insert_Input>;
  avatar?: InputMaybe<Scalars['String']>;
  balance?: InputMaybe<Employee_Balance_Obj_Rel_Insert_Input>;
  balance_corrections?: InputMaybe<Balance_Corrections_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  employee_allocations?: InputMaybe<Employee_Allocations_Arr_Rel_Insert_Input>;
  employment_end?: InputMaybe<Scalars['date']>;
  employment_start?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  monthly_hours_report?: InputMaybe<Employee_Monthly_Hours_Report_Arr_Rel_Insert_Input>;
  payroll_prediction?: InputMaybe<Employee_Monthly_Predicted_Payroll_Arr_Rel_Insert_Input>;
  payroll_reports?: InputMaybe<Employee_Monthly_Payroll_Report_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Employee_Max_Fields = {
  __typename?: 'employee_max_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  employment_end?: Maybe<Scalars['date']>;
  employment_start?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Employee_Min_Fields = {
  __typename?: 'employee_min_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  employment_end?: Maybe<Scalars['date']>;
  employment_start?: Maybe<Scalars['date']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "employee_monthly_billing_report" */
export type Employee_Monthly_Billing_Report = {
  __typename?: 'employee_monthly_billing_report';
  employee_id?: Maybe<Scalars['uuid']>;
  external_billing?: Maybe<Scalars['numeric']>;
  holiday_days?: Maybe<Scalars['bigint']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  unpaid_days?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "employee_monthly_billing_report" */
export type Employee_Monthly_Billing_Report_Aggregate = {
  __typename?: 'employee_monthly_billing_report_aggregate';
  aggregate?: Maybe<Employee_Monthly_Billing_Report_Aggregate_Fields>;
  nodes: Array<Employee_Monthly_Billing_Report>;
};

/** aggregate fields of "employee_monthly_billing_report" */
export type Employee_Monthly_Billing_Report_Aggregate_Fields = {
  __typename?: 'employee_monthly_billing_report_aggregate_fields';
  avg?: Maybe<Employee_Monthly_Billing_Report_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Monthly_Billing_Report_Max_Fields>;
  min?: Maybe<Employee_Monthly_Billing_Report_Min_Fields>;
  stddev?: Maybe<Employee_Monthly_Billing_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Monthly_Billing_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Monthly_Billing_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Monthly_Billing_Report_Sum_Fields>;
  var_pop?: Maybe<Employee_Monthly_Billing_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Monthly_Billing_Report_Var_Samp_Fields>;
  variance?: Maybe<Employee_Monthly_Billing_Report_Variance_Fields>;
};


/** aggregate fields of "employee_monthly_billing_report" */
export type Employee_Monthly_Billing_Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Monthly_Billing_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Employee_Monthly_Billing_Report_Avg_Fields = {
  __typename?: 'employee_monthly_billing_report_avg_fields';
  external_billing?: Maybe<Scalars['Float']>;
  holiday_days?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  unpaid_days?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "employee_monthly_billing_report". All fields are combined with a logical 'AND'. */
export type Employee_Monthly_Billing_Report_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Monthly_Billing_Report_Bool_Exp>>;
  _not?: InputMaybe<Employee_Monthly_Billing_Report_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Monthly_Billing_Report_Bool_Exp>>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  external_billing?: InputMaybe<Numeric_Comparison_Exp>;
  holiday_days?: InputMaybe<Bigint_Comparison_Exp>;
  internal_billing?: InputMaybe<Numeric_Comparison_Exp>;
  report_month?: InputMaybe<Date_Comparison_Exp>;
  unpaid_days?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Employee_Monthly_Billing_Report_Max_Fields = {
  __typename?: 'employee_monthly_billing_report_max_fields';
  employee_id?: Maybe<Scalars['uuid']>;
  external_billing?: Maybe<Scalars['numeric']>;
  holiday_days?: Maybe<Scalars['bigint']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  unpaid_days?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Employee_Monthly_Billing_Report_Min_Fields = {
  __typename?: 'employee_monthly_billing_report_min_fields';
  employee_id?: Maybe<Scalars['uuid']>;
  external_billing?: Maybe<Scalars['numeric']>;
  holiday_days?: Maybe<Scalars['bigint']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  unpaid_days?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "employee_monthly_billing_report". */
export type Employee_Monthly_Billing_Report_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  holiday_days?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  unpaid_days?: InputMaybe<Order_By>;
};

/** select columns of table "employee_monthly_billing_report" */
export enum Employee_Monthly_Billing_Report_Select_Column {
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  ExternalBilling = 'external_billing',
  /** column name */
  HolidayDays = 'holiday_days',
  /** column name */
  InternalBilling = 'internal_billing',
  /** column name */
  ReportMonth = 'report_month',
  /** column name */
  UnpaidDays = 'unpaid_days'
}

/** aggregate stddev on columns */
export type Employee_Monthly_Billing_Report_Stddev_Fields = {
  __typename?: 'employee_monthly_billing_report_stddev_fields';
  external_billing?: Maybe<Scalars['Float']>;
  holiday_days?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  unpaid_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Employee_Monthly_Billing_Report_Stddev_Pop_Fields = {
  __typename?: 'employee_monthly_billing_report_stddev_pop_fields';
  external_billing?: Maybe<Scalars['Float']>;
  holiday_days?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  unpaid_days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Employee_Monthly_Billing_Report_Stddev_Samp_Fields = {
  __typename?: 'employee_monthly_billing_report_stddev_samp_fields';
  external_billing?: Maybe<Scalars['Float']>;
  holiday_days?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  unpaid_days?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "employee_monthly_billing_report" */
export type Employee_Monthly_Billing_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Monthly_Billing_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Monthly_Billing_Report_Stream_Cursor_Value_Input = {
  employee_id?: InputMaybe<Scalars['uuid']>;
  external_billing?: InputMaybe<Scalars['numeric']>;
  holiday_days?: InputMaybe<Scalars['bigint']>;
  internal_billing?: InputMaybe<Scalars['numeric']>;
  report_month?: InputMaybe<Scalars['date']>;
  unpaid_days?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Employee_Monthly_Billing_Report_Sum_Fields = {
  __typename?: 'employee_monthly_billing_report_sum_fields';
  external_billing?: Maybe<Scalars['numeric']>;
  holiday_days?: Maybe<Scalars['bigint']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  unpaid_days?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Employee_Monthly_Billing_Report_Var_Pop_Fields = {
  __typename?: 'employee_monthly_billing_report_var_pop_fields';
  external_billing?: Maybe<Scalars['Float']>;
  holiday_days?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  unpaid_days?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Employee_Monthly_Billing_Report_Var_Samp_Fields = {
  __typename?: 'employee_monthly_billing_report_var_samp_fields';
  external_billing?: Maybe<Scalars['Float']>;
  holiday_days?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  unpaid_days?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Employee_Monthly_Billing_Report_Variance_Fields = {
  __typename?: 'employee_monthly_billing_report_variance_fields';
  external_billing?: Maybe<Scalars['Float']>;
  holiday_days?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  unpaid_days?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report = {
  __typename?: 'employee_monthly_hours_report';
  billable_hours?: Maybe<Scalars['numeric']>;
  company_hours?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  holiday_hours?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  sickleave_hours?: Maybe<Scalars['numeric']>;
  unpaid_hours?: Maybe<Scalars['numeric']>;
  worked_days?: Maybe<Scalars['bigint']>;
  working_days?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Aggregate = {
  __typename?: 'employee_monthly_hours_report_aggregate';
  aggregate?: Maybe<Employee_Monthly_Hours_Report_Aggregate_Fields>;
  nodes: Array<Employee_Monthly_Hours_Report>;
};

export type Employee_Monthly_Hours_Report_Aggregate_Bool_Exp = {
  count?: InputMaybe<Employee_Monthly_Hours_Report_Aggregate_Bool_Exp_Count>;
};

export type Employee_Monthly_Hours_Report_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employee_Monthly_Hours_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Aggregate_Fields = {
  __typename?: 'employee_monthly_hours_report_aggregate_fields';
  avg?: Maybe<Employee_Monthly_Hours_Report_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Monthly_Hours_Report_Max_Fields>;
  min?: Maybe<Employee_Monthly_Hours_Report_Min_Fields>;
  stddev?: Maybe<Employee_Monthly_Hours_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Monthly_Hours_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Monthly_Hours_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Monthly_Hours_Report_Sum_Fields>;
  var_pop?: Maybe<Employee_Monthly_Hours_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Monthly_Hours_Report_Var_Samp_Fields>;
  variance?: Maybe<Employee_Monthly_Hours_Report_Variance_Fields>;
};


/** aggregate fields of "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Monthly_Hours_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Aggregate_Order_By = {
  avg?: InputMaybe<Employee_Monthly_Hours_Report_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Monthly_Hours_Report_Max_Order_By>;
  min?: InputMaybe<Employee_Monthly_Hours_Report_Min_Order_By>;
  stddev?: InputMaybe<Employee_Monthly_Hours_Report_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employee_Monthly_Hours_Report_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employee_Monthly_Hours_Report_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employee_Monthly_Hours_Report_Sum_Order_By>;
  var_pop?: InputMaybe<Employee_Monthly_Hours_Report_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employee_Monthly_Hours_Report_Var_Samp_Order_By>;
  variance?: InputMaybe<Employee_Monthly_Hours_Report_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Arr_Rel_Insert_Input = {
  data: Array<Employee_Monthly_Hours_Report_Insert_Input>;
};

/** aggregate avg on columns */
export type Employee_Monthly_Hours_Report_Avg_Fields = {
  __typename?: 'employee_monthly_hours_report_avg_fields';
  billable_hours?: Maybe<Scalars['Float']>;
  company_hours?: Maybe<Scalars['Float']>;
  holiday_hours?: Maybe<Scalars['Float']>;
  sickleave_hours?: Maybe<Scalars['Float']>;
  unpaid_hours?: Maybe<Scalars['Float']>;
  worked_days?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Avg_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employee_monthly_hours_report". All fields are combined with a logical 'AND'. */
export type Employee_Monthly_Hours_Report_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Monthly_Hours_Report_Bool_Exp>>;
  _not?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Monthly_Hours_Report_Bool_Exp>>;
  billable_hours?: InputMaybe<Numeric_Comparison_Exp>;
  company_hours?: InputMaybe<Numeric_Comparison_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  holiday_hours?: InputMaybe<Numeric_Comparison_Exp>;
  report_month?: InputMaybe<Date_Comparison_Exp>;
  sickleave_hours?: InputMaybe<Numeric_Comparison_Exp>;
  unpaid_hours?: InputMaybe<Numeric_Comparison_Exp>;
  worked_days?: InputMaybe<Bigint_Comparison_Exp>;
  working_days?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Insert_Input = {
  billable_hours?: InputMaybe<Scalars['numeric']>;
  company_hours?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  holiday_hours?: InputMaybe<Scalars['numeric']>;
  report_month?: InputMaybe<Scalars['date']>;
  sickleave_hours?: InputMaybe<Scalars['numeric']>;
  unpaid_hours?: InputMaybe<Scalars['numeric']>;
  worked_days?: InputMaybe<Scalars['bigint']>;
  working_days?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Employee_Monthly_Hours_Report_Max_Fields = {
  __typename?: 'employee_monthly_hours_report_max_fields';
  billable_hours?: Maybe<Scalars['numeric']>;
  company_hours?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  holiday_hours?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  sickleave_hours?: Maybe<Scalars['numeric']>;
  unpaid_hours?: Maybe<Scalars['numeric']>;
  worked_days?: Maybe<Scalars['bigint']>;
  working_days?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Max_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employee_Monthly_Hours_Report_Min_Fields = {
  __typename?: 'employee_monthly_hours_report_min_fields';
  billable_hours?: Maybe<Scalars['numeric']>;
  company_hours?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  holiday_hours?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  sickleave_hours?: Maybe<Scalars['numeric']>;
  unpaid_hours?: Maybe<Scalars['numeric']>;
  worked_days?: Maybe<Scalars['bigint']>;
  working_days?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Min_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "employee_monthly_hours_report". */
export type Employee_Monthly_Hours_Report_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** select columns of table "employee_monthly_hours_report" */
export enum Employee_Monthly_Hours_Report_Select_Column {
  /** column name */
  BillableHours = 'billable_hours',
  /** column name */
  CompanyHours = 'company_hours',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  HolidayHours = 'holiday_hours',
  /** column name */
  ReportMonth = 'report_month',
  /** column name */
  SickleaveHours = 'sickleave_hours',
  /** column name */
  UnpaidHours = 'unpaid_hours',
  /** column name */
  WorkedDays = 'worked_days',
  /** column name */
  WorkingDays = 'working_days'
}

/** aggregate stddev on columns */
export type Employee_Monthly_Hours_Report_Stddev_Fields = {
  __typename?: 'employee_monthly_hours_report_stddev_fields';
  billable_hours?: Maybe<Scalars['Float']>;
  company_hours?: Maybe<Scalars['Float']>;
  holiday_hours?: Maybe<Scalars['Float']>;
  sickleave_hours?: Maybe<Scalars['Float']>;
  unpaid_hours?: Maybe<Scalars['Float']>;
  worked_days?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Stddev_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employee_Monthly_Hours_Report_Stddev_Pop_Fields = {
  __typename?: 'employee_monthly_hours_report_stddev_pop_fields';
  billable_hours?: Maybe<Scalars['Float']>;
  company_hours?: Maybe<Scalars['Float']>;
  holiday_hours?: Maybe<Scalars['Float']>;
  sickleave_hours?: Maybe<Scalars['Float']>;
  unpaid_hours?: Maybe<Scalars['Float']>;
  worked_days?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Stddev_Pop_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employee_Monthly_Hours_Report_Stddev_Samp_Fields = {
  __typename?: 'employee_monthly_hours_report_stddev_samp_fields';
  billable_hours?: Maybe<Scalars['Float']>;
  company_hours?: Maybe<Scalars['Float']>;
  holiday_hours?: Maybe<Scalars['Float']>;
  sickleave_hours?: Maybe<Scalars['Float']>;
  unpaid_hours?: Maybe<Scalars['Float']>;
  worked_days?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Stddev_Samp_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Monthly_Hours_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Monthly_Hours_Report_Stream_Cursor_Value_Input = {
  billable_hours?: InputMaybe<Scalars['numeric']>;
  company_hours?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  holiday_hours?: InputMaybe<Scalars['numeric']>;
  report_month?: InputMaybe<Scalars['date']>;
  sickleave_hours?: InputMaybe<Scalars['numeric']>;
  unpaid_hours?: InputMaybe<Scalars['numeric']>;
  worked_days?: InputMaybe<Scalars['bigint']>;
  working_days?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Employee_Monthly_Hours_Report_Sum_Fields = {
  __typename?: 'employee_monthly_hours_report_sum_fields';
  billable_hours?: Maybe<Scalars['numeric']>;
  company_hours?: Maybe<Scalars['numeric']>;
  holiday_hours?: Maybe<Scalars['numeric']>;
  sickleave_hours?: Maybe<Scalars['numeric']>;
  unpaid_hours?: Maybe<Scalars['numeric']>;
  worked_days?: Maybe<Scalars['bigint']>;
  working_days?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Sum_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Employee_Monthly_Hours_Report_Var_Pop_Fields = {
  __typename?: 'employee_monthly_hours_report_var_pop_fields';
  billable_hours?: Maybe<Scalars['Float']>;
  company_hours?: Maybe<Scalars['Float']>;
  holiday_hours?: Maybe<Scalars['Float']>;
  sickleave_hours?: Maybe<Scalars['Float']>;
  unpaid_hours?: Maybe<Scalars['Float']>;
  worked_days?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Var_Pop_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employee_Monthly_Hours_Report_Var_Samp_Fields = {
  __typename?: 'employee_monthly_hours_report_var_samp_fields';
  billable_hours?: Maybe<Scalars['Float']>;
  company_hours?: Maybe<Scalars['Float']>;
  holiday_hours?: Maybe<Scalars['Float']>;
  sickleave_hours?: Maybe<Scalars['Float']>;
  unpaid_hours?: Maybe<Scalars['Float']>;
  worked_days?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Var_Samp_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employee_Monthly_Hours_Report_Variance_Fields = {
  __typename?: 'employee_monthly_hours_report_variance_fields';
  billable_hours?: Maybe<Scalars['Float']>;
  company_hours?: Maybe<Scalars['Float']>;
  holiday_hours?: Maybe<Scalars['Float']>;
  sickleave_hours?: Maybe<Scalars['Float']>;
  unpaid_hours?: Maybe<Scalars['Float']>;
  worked_days?: Maybe<Scalars['Float']>;
  working_days?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employee_monthly_hours_report" */
export type Employee_Monthly_Hours_Report_Variance_Order_By = {
  billable_hours?: InputMaybe<Order_By>;
  company_hours?: InputMaybe<Order_By>;
  holiday_hours?: InputMaybe<Order_By>;
  sickleave_hours?: InputMaybe<Order_By>;
  unpaid_hours?: InputMaybe<Order_By>;
  worked_days?: InputMaybe<Order_By>;
  working_days?: InputMaybe<Order_By>;
};

/** columns and relationships of "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report = {
  __typename?: 'employee_monthly_payroll_report';
  allocation?: Maybe<Scalars['numeric']>;
  balance_change?: Maybe<Scalars['numeric']>;
  base_salary?: Maybe<Scalars['numeric']>;
  billing_for_bonus?: Maybe<Scalars['numeric']>;
  calculated_salary_base?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  external_billing?: Maybe<Scalars['numeric']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  salary_bonus_part?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Aggregate = {
  __typename?: 'employee_monthly_payroll_report_aggregate';
  aggregate?: Maybe<Employee_Monthly_Payroll_Report_Aggregate_Fields>;
  nodes: Array<Employee_Monthly_Payroll_Report>;
};

export type Employee_Monthly_Payroll_Report_Aggregate_Bool_Exp = {
  count?: InputMaybe<Employee_Monthly_Payroll_Report_Aggregate_Bool_Exp_Count>;
};

export type Employee_Monthly_Payroll_Report_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Aggregate_Fields = {
  __typename?: 'employee_monthly_payroll_report_aggregate_fields';
  avg?: Maybe<Employee_Monthly_Payroll_Report_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Monthly_Payroll_Report_Max_Fields>;
  min?: Maybe<Employee_Monthly_Payroll_Report_Min_Fields>;
  stddev?: Maybe<Employee_Monthly_Payroll_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Monthly_Payroll_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Monthly_Payroll_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Monthly_Payroll_Report_Sum_Fields>;
  var_pop?: Maybe<Employee_Monthly_Payroll_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Monthly_Payroll_Report_Var_Samp_Fields>;
  variance?: Maybe<Employee_Monthly_Payroll_Report_Variance_Fields>;
};


/** aggregate fields of "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Aggregate_Order_By = {
  avg?: InputMaybe<Employee_Monthly_Payroll_Report_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Monthly_Payroll_Report_Max_Order_By>;
  min?: InputMaybe<Employee_Monthly_Payroll_Report_Min_Order_By>;
  stddev?: InputMaybe<Employee_Monthly_Payroll_Report_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employee_Monthly_Payroll_Report_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employee_Monthly_Payroll_Report_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employee_Monthly_Payroll_Report_Sum_Order_By>;
  var_pop?: InputMaybe<Employee_Monthly_Payroll_Report_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employee_Monthly_Payroll_Report_Var_Samp_Order_By>;
  variance?: InputMaybe<Employee_Monthly_Payroll_Report_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Arr_Rel_Insert_Input = {
  data: Array<Employee_Monthly_Payroll_Report_Insert_Input>;
};

/** aggregate avg on columns */
export type Employee_Monthly_Payroll_Report_Avg_Fields = {
  __typename?: 'employee_monthly_payroll_report_avg_fields';
  allocation?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Avg_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employee_monthly_payroll_report". All fields are combined with a logical 'AND'. */
export type Employee_Monthly_Payroll_Report_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Bool_Exp>>;
  _not?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Bool_Exp>>;
  allocation?: InputMaybe<Numeric_Comparison_Exp>;
  balance_change?: InputMaybe<Numeric_Comparison_Exp>;
  base_salary?: InputMaybe<Numeric_Comparison_Exp>;
  billing_for_bonus?: InputMaybe<Numeric_Comparison_Exp>;
  calculated_salary_base?: InputMaybe<Numeric_Comparison_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  external_billing?: InputMaybe<Numeric_Comparison_Exp>;
  internal_billing?: InputMaybe<Numeric_Comparison_Exp>;
  report_month?: InputMaybe<Date_Comparison_Exp>;
  salary_bonus_part?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for inserting data into table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Insert_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
  balance_change?: InputMaybe<Scalars['numeric']>;
  base_salary?: InputMaybe<Scalars['numeric']>;
  billing_for_bonus?: InputMaybe<Scalars['numeric']>;
  calculated_salary_base?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  external_billing?: InputMaybe<Scalars['numeric']>;
  internal_billing?: InputMaybe<Scalars['numeric']>;
  report_month?: InputMaybe<Scalars['date']>;
  salary_bonus_part?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Employee_Monthly_Payroll_Report_Max_Fields = {
  __typename?: 'employee_monthly_payroll_report_max_fields';
  allocation?: Maybe<Scalars['numeric']>;
  balance_change?: Maybe<Scalars['numeric']>;
  base_salary?: Maybe<Scalars['numeric']>;
  billing_for_bonus?: Maybe<Scalars['numeric']>;
  calculated_salary_base?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  external_billing?: Maybe<Scalars['numeric']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  salary_bonus_part?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Max_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employee_Monthly_Payroll_Report_Min_Fields = {
  __typename?: 'employee_monthly_payroll_report_min_fields';
  allocation?: Maybe<Scalars['numeric']>;
  balance_change?: Maybe<Scalars['numeric']>;
  base_salary?: Maybe<Scalars['numeric']>;
  billing_for_bonus?: Maybe<Scalars['numeric']>;
  calculated_salary_base?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  external_billing?: Maybe<Scalars['numeric']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  salary_bonus_part?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Min_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "employee_monthly_payroll_report". */
export type Employee_Monthly_Payroll_Report_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** select columns of table "employee_monthly_payroll_report" */
export enum Employee_Monthly_Payroll_Report_Select_Column {
  /** column name */
  Allocation = 'allocation',
  /** column name */
  BalanceChange = 'balance_change',
  /** column name */
  BaseSalary = 'base_salary',
  /** column name */
  BillingForBonus = 'billing_for_bonus',
  /** column name */
  CalculatedSalaryBase = 'calculated_salary_base',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  ExternalBilling = 'external_billing',
  /** column name */
  InternalBilling = 'internal_billing',
  /** column name */
  ReportMonth = 'report_month',
  /** column name */
  SalaryBonusPart = 'salary_bonus_part'
}

/** aggregate stddev on columns */
export type Employee_Monthly_Payroll_Report_Stddev_Fields = {
  __typename?: 'employee_monthly_payroll_report_stddev_fields';
  allocation?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Stddev_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employee_Monthly_Payroll_Report_Stddev_Pop_Fields = {
  __typename?: 'employee_monthly_payroll_report_stddev_pop_fields';
  allocation?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Stddev_Pop_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employee_Monthly_Payroll_Report_Stddev_Samp_Fields = {
  __typename?: 'employee_monthly_payroll_report_stddev_samp_fields';
  allocation?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Stddev_Samp_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Monthly_Payroll_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Monthly_Payroll_Report_Stream_Cursor_Value_Input = {
  allocation?: InputMaybe<Scalars['numeric']>;
  balance_change?: InputMaybe<Scalars['numeric']>;
  base_salary?: InputMaybe<Scalars['numeric']>;
  billing_for_bonus?: InputMaybe<Scalars['numeric']>;
  calculated_salary_base?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  external_billing?: InputMaybe<Scalars['numeric']>;
  internal_billing?: InputMaybe<Scalars['numeric']>;
  report_month?: InputMaybe<Scalars['date']>;
  salary_bonus_part?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Employee_Monthly_Payroll_Report_Sum_Fields = {
  __typename?: 'employee_monthly_payroll_report_sum_fields';
  allocation?: Maybe<Scalars['numeric']>;
  balance_change?: Maybe<Scalars['numeric']>;
  base_salary?: Maybe<Scalars['numeric']>;
  billing_for_bonus?: Maybe<Scalars['numeric']>;
  calculated_salary_base?: Maybe<Scalars['numeric']>;
  external_billing?: Maybe<Scalars['numeric']>;
  internal_billing?: Maybe<Scalars['numeric']>;
  salary_bonus_part?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Sum_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Employee_Monthly_Payroll_Report_Var_Pop_Fields = {
  __typename?: 'employee_monthly_payroll_report_var_pop_fields';
  allocation?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Var_Pop_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employee_Monthly_Payroll_Report_Var_Samp_Fields = {
  __typename?: 'employee_monthly_payroll_report_var_samp_fields';
  allocation?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Var_Samp_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employee_Monthly_Payroll_Report_Variance_Fields = {
  __typename?: 'employee_monthly_payroll_report_variance_fields';
  allocation?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  external_billing?: Maybe<Scalars['Float']>;
  internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employee_monthly_payroll_report" */
export type Employee_Monthly_Payroll_Report_Variance_Order_By = {
  allocation?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  external_billing?: InputMaybe<Order_By>;
  internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** columns and relationships of "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll = {
  __typename?: 'employee_monthly_predicted_payroll';
  allocation_percentage?: Maybe<Scalars['numeric']>;
  balance_change?: Maybe<Scalars['numeric']>;
  base_salary?: Maybe<Scalars['numeric']>;
  billing_for_bonus?: Maybe<Scalars['numeric']>;
  calculated_salary_base?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  monthly_actual_external_billing?: Maybe<Scalars['numeric']>;
  monthly_actual_internal_billing?: Maybe<Scalars['numeric']>;
  monthly_external_billing?: Maybe<Scalars['numeric']>;
  monthly_internal_billing?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  salary_bonus_part?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Aggregate = {
  __typename?: 'employee_monthly_predicted_payroll_aggregate';
  aggregate?: Maybe<Employee_Monthly_Predicted_Payroll_Aggregate_Fields>;
  nodes: Array<Employee_Monthly_Predicted_Payroll>;
};

export type Employee_Monthly_Predicted_Payroll_Aggregate_Bool_Exp = {
  count?: InputMaybe<Employee_Monthly_Predicted_Payroll_Aggregate_Bool_Exp_Count>;
};

export type Employee_Monthly_Predicted_Payroll_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Aggregate_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_aggregate_fields';
  avg?: Maybe<Employee_Monthly_Predicted_Payroll_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Employee_Monthly_Predicted_Payroll_Max_Fields>;
  min?: Maybe<Employee_Monthly_Predicted_Payroll_Min_Fields>;
  stddev?: Maybe<Employee_Monthly_Predicted_Payroll_Stddev_Fields>;
  stddev_pop?: Maybe<Employee_Monthly_Predicted_Payroll_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Employee_Monthly_Predicted_Payroll_Stddev_Samp_Fields>;
  sum?: Maybe<Employee_Monthly_Predicted_Payroll_Sum_Fields>;
  var_pop?: Maybe<Employee_Monthly_Predicted_Payroll_Var_Pop_Fields>;
  var_samp?: Maybe<Employee_Monthly_Predicted_Payroll_Var_Samp_Fields>;
  variance?: Maybe<Employee_Monthly_Predicted_Payroll_Variance_Fields>;
};


/** aggregate fields of "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Aggregate_Order_By = {
  avg?: InputMaybe<Employee_Monthly_Predicted_Payroll_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Monthly_Predicted_Payroll_Max_Order_By>;
  min?: InputMaybe<Employee_Monthly_Predicted_Payroll_Min_Order_By>;
  stddev?: InputMaybe<Employee_Monthly_Predicted_Payroll_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employee_Monthly_Predicted_Payroll_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employee_Monthly_Predicted_Payroll_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employee_Monthly_Predicted_Payroll_Sum_Order_By>;
  var_pop?: InputMaybe<Employee_Monthly_Predicted_Payroll_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employee_Monthly_Predicted_Payroll_Var_Samp_Order_By>;
  variance?: InputMaybe<Employee_Monthly_Predicted_Payroll_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Arr_Rel_Insert_Input = {
  data: Array<Employee_Monthly_Predicted_Payroll_Insert_Input>;
};

/** aggregate avg on columns */
export type Employee_Monthly_Predicted_Payroll_Avg_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_avg_fields';
  allocation_percentage?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  monthly_actual_external_billing?: Maybe<Scalars['Float']>;
  monthly_actual_internal_billing?: Maybe<Scalars['Float']>;
  monthly_external_billing?: Maybe<Scalars['Float']>;
  monthly_internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Avg_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employee_monthly_predicted_payroll". All fields are combined with a logical 'AND'. */
export type Employee_Monthly_Predicted_Payroll_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Bool_Exp>>;
  _not?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Bool_Exp>>;
  allocation_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  balance_change?: InputMaybe<Numeric_Comparison_Exp>;
  base_salary?: InputMaybe<Numeric_Comparison_Exp>;
  billing_for_bonus?: InputMaybe<Numeric_Comparison_Exp>;
  calculated_salary_base?: InputMaybe<Numeric_Comparison_Exp>;
  employee_id?: InputMaybe<Uuid_Comparison_Exp>;
  monthly_actual_external_billing?: InputMaybe<Numeric_Comparison_Exp>;
  monthly_actual_internal_billing?: InputMaybe<Numeric_Comparison_Exp>;
  monthly_external_billing?: InputMaybe<Numeric_Comparison_Exp>;
  monthly_internal_billing?: InputMaybe<Numeric_Comparison_Exp>;
  report_month?: InputMaybe<Date_Comparison_Exp>;
  salary_bonus_part?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for inserting data into table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Insert_Input = {
  allocation_percentage?: InputMaybe<Scalars['numeric']>;
  balance_change?: InputMaybe<Scalars['numeric']>;
  base_salary?: InputMaybe<Scalars['numeric']>;
  billing_for_bonus?: InputMaybe<Scalars['numeric']>;
  calculated_salary_base?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  monthly_actual_external_billing?: InputMaybe<Scalars['numeric']>;
  monthly_actual_internal_billing?: InputMaybe<Scalars['numeric']>;
  monthly_external_billing?: InputMaybe<Scalars['numeric']>;
  monthly_internal_billing?: InputMaybe<Scalars['numeric']>;
  report_month?: InputMaybe<Scalars['date']>;
  salary_bonus_part?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Employee_Monthly_Predicted_Payroll_Max_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_max_fields';
  allocation_percentage?: Maybe<Scalars['numeric']>;
  balance_change?: Maybe<Scalars['numeric']>;
  base_salary?: Maybe<Scalars['numeric']>;
  billing_for_bonus?: Maybe<Scalars['numeric']>;
  calculated_salary_base?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  monthly_actual_external_billing?: Maybe<Scalars['numeric']>;
  monthly_actual_internal_billing?: Maybe<Scalars['numeric']>;
  monthly_external_billing?: Maybe<Scalars['numeric']>;
  monthly_internal_billing?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  salary_bonus_part?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Max_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Employee_Monthly_Predicted_Payroll_Min_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_min_fields';
  allocation_percentage?: Maybe<Scalars['numeric']>;
  balance_change?: Maybe<Scalars['numeric']>;
  base_salary?: Maybe<Scalars['numeric']>;
  billing_for_bonus?: Maybe<Scalars['numeric']>;
  calculated_salary_base?: Maybe<Scalars['numeric']>;
  employee_id?: Maybe<Scalars['uuid']>;
  monthly_actual_external_billing?: Maybe<Scalars['numeric']>;
  monthly_actual_internal_billing?: Maybe<Scalars['numeric']>;
  monthly_external_billing?: Maybe<Scalars['numeric']>;
  monthly_internal_billing?: Maybe<Scalars['numeric']>;
  report_month?: Maybe<Scalars['date']>;
  salary_bonus_part?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Min_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "employee_monthly_predicted_payroll". */
export type Employee_Monthly_Predicted_Payroll_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  report_month?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** select columns of table "employee_monthly_predicted_payroll" */
export enum Employee_Monthly_Predicted_Payroll_Select_Column {
  /** column name */
  AllocationPercentage = 'allocation_percentage',
  /** column name */
  BalanceChange = 'balance_change',
  /** column name */
  BaseSalary = 'base_salary',
  /** column name */
  BillingForBonus = 'billing_for_bonus',
  /** column name */
  CalculatedSalaryBase = 'calculated_salary_base',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  MonthlyActualExternalBilling = 'monthly_actual_external_billing',
  /** column name */
  MonthlyActualInternalBilling = 'monthly_actual_internal_billing',
  /** column name */
  MonthlyExternalBilling = 'monthly_external_billing',
  /** column name */
  MonthlyInternalBilling = 'monthly_internal_billing',
  /** column name */
  ReportMonth = 'report_month',
  /** column name */
  SalaryBonusPart = 'salary_bonus_part'
}

/** aggregate stddev on columns */
export type Employee_Monthly_Predicted_Payroll_Stddev_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_stddev_fields';
  allocation_percentage?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  monthly_actual_external_billing?: Maybe<Scalars['Float']>;
  monthly_actual_internal_billing?: Maybe<Scalars['Float']>;
  monthly_external_billing?: Maybe<Scalars['Float']>;
  monthly_internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Stddev_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Employee_Monthly_Predicted_Payroll_Stddev_Pop_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_stddev_pop_fields';
  allocation_percentage?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  monthly_actual_external_billing?: Maybe<Scalars['Float']>;
  monthly_actual_internal_billing?: Maybe<Scalars['Float']>;
  monthly_external_billing?: Maybe<Scalars['Float']>;
  monthly_internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Stddev_Pop_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Employee_Monthly_Predicted_Payroll_Stddev_Samp_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_stddev_samp_fields';
  allocation_percentage?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  monthly_actual_external_billing?: Maybe<Scalars['Float']>;
  monthly_actual_internal_billing?: Maybe<Scalars['Float']>;
  monthly_external_billing?: Maybe<Scalars['Float']>;
  monthly_internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Stddev_Samp_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Monthly_Predicted_Payroll_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Monthly_Predicted_Payroll_Stream_Cursor_Value_Input = {
  allocation_percentage?: InputMaybe<Scalars['numeric']>;
  balance_change?: InputMaybe<Scalars['numeric']>;
  base_salary?: InputMaybe<Scalars['numeric']>;
  billing_for_bonus?: InputMaybe<Scalars['numeric']>;
  calculated_salary_base?: InputMaybe<Scalars['numeric']>;
  employee_id?: InputMaybe<Scalars['uuid']>;
  monthly_actual_external_billing?: InputMaybe<Scalars['numeric']>;
  monthly_actual_internal_billing?: InputMaybe<Scalars['numeric']>;
  monthly_external_billing?: InputMaybe<Scalars['numeric']>;
  monthly_internal_billing?: InputMaybe<Scalars['numeric']>;
  report_month?: InputMaybe<Scalars['date']>;
  salary_bonus_part?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Employee_Monthly_Predicted_Payroll_Sum_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_sum_fields';
  allocation_percentage?: Maybe<Scalars['numeric']>;
  balance_change?: Maybe<Scalars['numeric']>;
  base_salary?: Maybe<Scalars['numeric']>;
  billing_for_bonus?: Maybe<Scalars['numeric']>;
  calculated_salary_base?: Maybe<Scalars['numeric']>;
  monthly_actual_external_billing?: Maybe<Scalars['numeric']>;
  monthly_actual_internal_billing?: Maybe<Scalars['numeric']>;
  monthly_external_billing?: Maybe<Scalars['numeric']>;
  monthly_internal_billing?: Maybe<Scalars['numeric']>;
  salary_bonus_part?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Sum_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Employee_Monthly_Predicted_Payroll_Var_Pop_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_var_pop_fields';
  allocation_percentage?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  monthly_actual_external_billing?: Maybe<Scalars['Float']>;
  monthly_actual_internal_billing?: Maybe<Scalars['Float']>;
  monthly_external_billing?: Maybe<Scalars['Float']>;
  monthly_internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Var_Pop_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Employee_Monthly_Predicted_Payroll_Var_Samp_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_var_samp_fields';
  allocation_percentage?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  monthly_actual_external_billing?: Maybe<Scalars['Float']>;
  monthly_actual_internal_billing?: Maybe<Scalars['Float']>;
  monthly_external_billing?: Maybe<Scalars['Float']>;
  monthly_internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Var_Samp_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Employee_Monthly_Predicted_Payroll_Variance_Fields = {
  __typename?: 'employee_monthly_predicted_payroll_variance_fields';
  allocation_percentage?: Maybe<Scalars['Float']>;
  balance_change?: Maybe<Scalars['Float']>;
  base_salary?: Maybe<Scalars['Float']>;
  billing_for_bonus?: Maybe<Scalars['Float']>;
  calculated_salary_base?: Maybe<Scalars['Float']>;
  monthly_actual_external_billing?: Maybe<Scalars['Float']>;
  monthly_actual_internal_billing?: Maybe<Scalars['Float']>;
  monthly_external_billing?: Maybe<Scalars['Float']>;
  monthly_internal_billing?: Maybe<Scalars['Float']>;
  salary_bonus_part?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "employee_monthly_predicted_payroll" */
export type Employee_Monthly_Predicted_Payroll_Variance_Order_By = {
  allocation_percentage?: InputMaybe<Order_By>;
  balance_change?: InputMaybe<Order_By>;
  base_salary?: InputMaybe<Order_By>;
  billing_for_bonus?: InputMaybe<Order_By>;
  calculated_salary_base?: InputMaybe<Order_By>;
  monthly_actual_external_billing?: InputMaybe<Order_By>;
  monthly_actual_internal_billing?: InputMaybe<Order_By>;
  monthly_external_billing?: InputMaybe<Order_By>;
  monthly_internal_billing?: InputMaybe<Order_By>;
  salary_bonus_part?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "employees" */
export type Employee_Mutation_Response = {
  __typename?: 'employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Employee>;
};

/** input type for inserting object relation for remote table "employees" */
export type Employee_Obj_Rel_Insert_Input = {
  data: Employee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};

/** on_conflict condition type for table "employees" */
export type Employee_On_Conflict = {
  constraint: Employee_Constraint;
  update_columns?: Array<Employee_Update_Column>;
  where?: InputMaybe<Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "employees". */
export type Employee_Order_By = {
  assignments_aggregate?: InputMaybe<Assignments_Aggregate_Order_By>;
  avatar?: InputMaybe<Order_By>;
  balance?: InputMaybe<Employee_Balance_Order_By>;
  balance_corrections_aggregate?: InputMaybe<Balance_Corrections_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employee_allocations_aggregate?: InputMaybe<Employee_Allocations_Aggregate_Order_By>;
  employment_end?: InputMaybe<Order_By>;
  employment_start?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  monthly_hours_report_aggregate?: InputMaybe<Employee_Monthly_Hours_Report_Aggregate_Order_By>;
  payroll_prediction_aggregate?: InputMaybe<Employee_Monthly_Predicted_Payroll_Aggregate_Order_By>;
  payroll_reports_aggregate?: InputMaybe<Employee_Monthly_Payroll_Report_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: employees */
export type Employee_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Employee_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "employees" */
export enum Employee_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmploymentEnd = 'employment_end',
  /** column name */
  EmploymentStart = 'employment_start',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "employees" */
export type Employee_Set_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  employment_end?: InputMaybe<Scalars['date']>;
  employment_start?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "employee" */
export type Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  employment_end?: InputMaybe<Scalars['date']>;
  employment_start?: InputMaybe<Scalars['date']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "employees" */
export enum Employee_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmploymentEnd = 'employment_end',
  /** column name */
  EmploymentStart = 'employment_start',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Employee_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Employee_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Employee_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Employee_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Employee_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Employee_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Employee_Set_Input>;
  where: Employee_Bool_Exp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "assignments" */
  delete_assignments?: Maybe<Assignments_Mutation_Response>;
  /** delete single row from the table: "assignments" */
  delete_assignments_by_pk?: Maybe<Assignments>;
  /** delete data from the table: "balance_corrections" */
  delete_balance_corrections?: Maybe<Balance_Corrections_Mutation_Response>;
  /** delete single row from the table: "balance_corrections" */
  delete_balance_corrections_by_pk?: Maybe<Balance_Corrections>;
  /** delete data from the table: "billing_by_project_and_employee_tmp_table" */
  delete_billing_by_project_and_employee_tmp_table?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Mutation_Response>;
  /** delete data from the table: "calendar_tmp_table" */
  delete_calendar_tmp_table?: Maybe<Calendar_Tmp_Table_Mutation_Response>;
  /** delete data from the table: "customers" */
  delete_customers?: Maybe<Customers_Mutation_Response>;
  /** delete single row from the table: "customers" */
  delete_customers_by_pk?: Maybe<Customers>;
  /** delete data from the table: "employees" */
  delete_employee?: Maybe<Employee_Mutation_Response>;
  /** delete data from the table: "employee_allocations" */
  delete_employee_allocations?: Maybe<Employee_Allocations_Mutation_Response>;
  /** delete single row from the table: "employee_allocations" */
  delete_employee_allocations_by_pk?: Maybe<Employee_Allocations>;
  /** delete single row from the table: "employees" */
  delete_employee_by_pk?: Maybe<Employee>;
  /** delete data from the table: "paid_holidays" */
  delete_paid_holidays?: Maybe<Paid_Holidays_Mutation_Response>;
  /** delete single row from the table: "paid_holidays" */
  delete_paid_holidays_by_pk?: Maybe<Paid_Holidays>;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "salary_coeffs" */
  delete_salary_coeffs?: Maybe<Salary_Coeffs_Mutation_Response>;
  /** delete single row from the table: "salary_coeffs" */
  delete_salary_coeffs_by_pk?: Maybe<Salary_Coeffs>;
  /** delete data from the table: "time_entries" */
  delete_time_entries?: Maybe<Time_Entries_Mutation_Response>;
  /** delete single row from the table: "time_entries" */
  delete_time_entries_by_pk?: Maybe<Time_Entries>;
  /** insert data into the table: "assignments" */
  insert_assignments?: Maybe<Assignments_Mutation_Response>;
  /** insert a single row into the table: "assignments" */
  insert_assignments_one?: Maybe<Assignments>;
  /** insert data into the table: "balance_corrections" */
  insert_balance_corrections?: Maybe<Balance_Corrections_Mutation_Response>;
  /** insert a single row into the table: "balance_corrections" */
  insert_balance_corrections_one?: Maybe<Balance_Corrections>;
  /** insert data into the table: "billing_by_project_and_employee_tmp_table" */
  insert_billing_by_project_and_employee_tmp_table?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Mutation_Response>;
  /** insert a single row into the table: "billing_by_project_and_employee_tmp_table" */
  insert_billing_by_project_and_employee_tmp_table_one?: Maybe<Billing_By_Project_And_Employee_Tmp_Table>;
  /** insert data into the table: "calendar_tmp_table" */
  insert_calendar_tmp_table?: Maybe<Calendar_Tmp_Table_Mutation_Response>;
  /** insert a single row into the table: "calendar_tmp_table" */
  insert_calendar_tmp_table_one?: Maybe<Calendar_Tmp_Table>;
  /** insert data into the table: "customers" */
  insert_customers?: Maybe<Customers_Mutation_Response>;
  /** insert a single row into the table: "customers" */
  insert_customers_one?: Maybe<Customers>;
  /** insert data into the table: "employees" */
  insert_employee?: Maybe<Employee_Mutation_Response>;
  /** insert data into the table: "employee_allocations" */
  insert_employee_allocations?: Maybe<Employee_Allocations_Mutation_Response>;
  /** insert a single row into the table: "employee_allocations" */
  insert_employee_allocations_one?: Maybe<Employee_Allocations>;
  /** insert a single row into the table: "employees" */
  insert_employee_one?: Maybe<Employee>;
  /** insert data into the table: "paid_holidays" */
  insert_paid_holidays?: Maybe<Paid_Holidays_Mutation_Response>;
  /** insert a single row into the table: "paid_holidays" */
  insert_paid_holidays_one?: Maybe<Paid_Holidays>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "salary_coeffs" */
  insert_salary_coeffs?: Maybe<Salary_Coeffs_Mutation_Response>;
  /** insert a single row into the table: "salary_coeffs" */
  insert_salary_coeffs_one?: Maybe<Salary_Coeffs>;
  /** insert data into the table: "time_entries" */
  insert_time_entries?: Maybe<Time_Entries_Mutation_Response>;
  /** insert a single row into the table: "time_entries" */
  insert_time_entries_one?: Maybe<Time_Entries>;
  /** update data of the table: "assignments" */
  update_assignments?: Maybe<Assignments_Mutation_Response>;
  /** update single row of the table: "assignments" */
  update_assignments_by_pk?: Maybe<Assignments>;
  /** update multiples rows of table: "assignments" */
  update_assignments_many?: Maybe<Array<Maybe<Assignments_Mutation_Response>>>;
  /** update data of the table: "balance_corrections" */
  update_balance_corrections?: Maybe<Balance_Corrections_Mutation_Response>;
  /** update single row of the table: "balance_corrections" */
  update_balance_corrections_by_pk?: Maybe<Balance_Corrections>;
  /** update multiples rows of table: "balance_corrections" */
  update_balance_corrections_many?: Maybe<Array<Maybe<Balance_Corrections_Mutation_Response>>>;
  /** update data of the table: "billing_by_project_and_employee_tmp_table" */
  update_billing_by_project_and_employee_tmp_table?: Maybe<Billing_By_Project_And_Employee_Tmp_Table_Mutation_Response>;
  /** update multiples rows of table: "billing_by_project_and_employee_tmp_table" */
  update_billing_by_project_and_employee_tmp_table_many?: Maybe<Array<Maybe<Billing_By_Project_And_Employee_Tmp_Table_Mutation_Response>>>;
  /** update data of the table: "calendar_tmp_table" */
  update_calendar_tmp_table?: Maybe<Calendar_Tmp_Table_Mutation_Response>;
  /** update multiples rows of table: "calendar_tmp_table" */
  update_calendar_tmp_table_many?: Maybe<Array<Maybe<Calendar_Tmp_Table_Mutation_Response>>>;
  /** update data of the table: "customers" */
  update_customers?: Maybe<Customers_Mutation_Response>;
  /** update single row of the table: "customers" */
  update_customers_by_pk?: Maybe<Customers>;
  /** update multiples rows of table: "customers" */
  update_customers_many?: Maybe<Array<Maybe<Customers_Mutation_Response>>>;
  /** update data of the table: "employees" */
  update_employee?: Maybe<Employee_Mutation_Response>;
  /** update data of the table: "employee_allocations" */
  update_employee_allocations?: Maybe<Employee_Allocations_Mutation_Response>;
  /** update single row of the table: "employee_allocations" */
  update_employee_allocations_by_pk?: Maybe<Employee_Allocations>;
  /** update multiples rows of table: "employee_allocations" */
  update_employee_allocations_many?: Maybe<Array<Maybe<Employee_Allocations_Mutation_Response>>>;
  /** update single row of the table: "employees" */
  update_employee_by_pk?: Maybe<Employee>;
  /** update multiples rows of table: "employees" */
  update_employee_many?: Maybe<Array<Maybe<Employee_Mutation_Response>>>;
  /** update data of the table: "paid_holidays" */
  update_paid_holidays?: Maybe<Paid_Holidays_Mutation_Response>;
  /** update single row of the table: "paid_holidays" */
  update_paid_holidays_by_pk?: Maybe<Paid_Holidays>;
  /** update multiples rows of table: "paid_holidays" */
  update_paid_holidays_many?: Maybe<Array<Maybe<Paid_Holidays_Mutation_Response>>>;
  /** update data of the table: "projects" */
  update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  update_projects_by_pk?: Maybe<Projects>;
  /** update multiples rows of table: "projects" */
  update_projects_many?: Maybe<Array<Maybe<Projects_Mutation_Response>>>;
  /** update data of the table: "salary_coeffs" */
  update_salary_coeffs?: Maybe<Salary_Coeffs_Mutation_Response>;
  /** update single row of the table: "salary_coeffs" */
  update_salary_coeffs_by_pk?: Maybe<Salary_Coeffs>;
  /** update multiples rows of table: "salary_coeffs" */
  update_salary_coeffs_many?: Maybe<Array<Maybe<Salary_Coeffs_Mutation_Response>>>;
  /** update data of the table: "time_entries" */
  update_time_entries?: Maybe<Time_Entries_Mutation_Response>;
  /** update single row of the table: "time_entries" */
  update_time_entries_by_pk?: Maybe<Time_Entries>;
  /** update multiples rows of table: "time_entries" */
  update_time_entries_many?: Maybe<Array<Maybe<Time_Entries_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_AssignmentsArgs = {
  where: Assignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assignments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Balance_CorrectionsArgs = {
  where: Balance_Corrections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Balance_Corrections_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Billing_By_Project_And_Employee_Tmp_TableArgs = {
  where: Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Calendar_Tmp_TableArgs = {
  where: Calendar_Tmp_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CustomersArgs = {
  where: Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EmployeeArgs = {
  where: Employee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employee_AllocationsArgs = {
  where: Employee_Allocations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Employee_Allocations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Employee_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Paid_HolidaysArgs = {
  where: Paid_Holidays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Paid_Holidays_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Salary_CoeffsArgs = {
  where: Salary_Coeffs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Salary_Coeffs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Time_EntriesArgs = {
  where: Time_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Time_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_AssignmentsArgs = {
  objects: Array<Assignments_Insert_Input>;
  on_conflict?: InputMaybe<Assignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignments_OneArgs = {
  object: Assignments_Insert_Input;
  on_conflict?: InputMaybe<Assignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Balance_CorrectionsArgs = {
  objects: Array<Balance_Corrections_Insert_Input>;
  on_conflict?: InputMaybe<Balance_Corrections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Balance_Corrections_OneArgs = {
  object: Balance_Corrections_Insert_Input;
  on_conflict?: InputMaybe<Balance_Corrections_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_By_Project_And_Employee_Tmp_TableArgs = {
  objects: Array<Billing_By_Project_And_Employee_Tmp_Table_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Billing_By_Project_And_Employee_Tmp_Table_OneArgs = {
  object: Billing_By_Project_And_Employee_Tmp_Table_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Calendar_Tmp_TableArgs = {
  objects: Array<Calendar_Tmp_Table_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Calendar_Tmp_Table_OneArgs = {
  object: Calendar_Tmp_Table_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CustomersArgs = {
  objects: Array<Customers_Insert_Input>;
  on_conflict?: InputMaybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customers_OneArgs = {
  object: Customers_Insert_Input;
  on_conflict?: InputMaybe<Customers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmployeeArgs = {
  objects: Array<Employee_Insert_Input>;
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employee_AllocationsArgs = {
  objects: Array<Employee_Allocations_Insert_Input>;
  on_conflict?: InputMaybe<Employee_Allocations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employee_Allocations_OneArgs = {
  object: Employee_Allocations_Insert_Input;
  on_conflict?: InputMaybe<Employee_Allocations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Employee_OneArgs = {
  object: Employee_Insert_Input;
  on_conflict?: InputMaybe<Employee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Paid_HolidaysArgs = {
  objects: Array<Paid_Holidays_Insert_Input>;
  on_conflict?: InputMaybe<Paid_Holidays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Paid_Holidays_OneArgs = {
  object: Paid_Holidays_Insert_Input;
  on_conflict?: InputMaybe<Paid_Holidays_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: Array<Projects_Insert_Input>;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Salary_CoeffsArgs = {
  objects: Array<Salary_Coeffs_Insert_Input>;
  on_conflict?: InputMaybe<Salary_Coeffs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Salary_Coeffs_OneArgs = {
  object: Salary_Coeffs_Insert_Input;
  on_conflict?: InputMaybe<Salary_Coeffs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Time_EntriesArgs = {
  objects: Array<Time_Entries_Insert_Input>;
  on_conflict?: InputMaybe<Time_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Time_Entries_OneArgs = {
  object: Time_Entries_Insert_Input;
  on_conflict?: InputMaybe<Time_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AssignmentsArgs = {
  _inc?: InputMaybe<Assignments_Inc_Input>;
  _set?: InputMaybe<Assignments_Set_Input>;
  where: Assignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assignments_By_PkArgs = {
  _inc?: InputMaybe<Assignments_Inc_Input>;
  _set?: InputMaybe<Assignments_Set_Input>;
  pk_columns: Assignments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assignments_ManyArgs = {
  updates: Array<Assignments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Balance_CorrectionsArgs = {
  _inc?: InputMaybe<Balance_Corrections_Inc_Input>;
  _set?: InputMaybe<Balance_Corrections_Set_Input>;
  where: Balance_Corrections_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Balance_Corrections_By_PkArgs = {
  _inc?: InputMaybe<Balance_Corrections_Inc_Input>;
  _set?: InputMaybe<Balance_Corrections_Set_Input>;
  pk_columns: Balance_Corrections_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Balance_Corrections_ManyArgs = {
  updates: Array<Balance_Corrections_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_By_Project_And_Employee_Tmp_TableArgs = {
  _inc?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Inc_Input>;
  _set?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Set_Input>;
  where: Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Billing_By_Project_And_Employee_Tmp_Table_ManyArgs = {
  updates: Array<Billing_By_Project_And_Employee_Tmp_Table_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Calendar_Tmp_TableArgs = {
  _inc?: InputMaybe<Calendar_Tmp_Table_Inc_Input>;
  _set?: InputMaybe<Calendar_Tmp_Table_Set_Input>;
  where: Calendar_Tmp_Table_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Calendar_Tmp_Table_ManyArgs = {
  updates: Array<Calendar_Tmp_Table_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomersArgs = {
  _set?: InputMaybe<Customers_Set_Input>;
  where: Customers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_By_PkArgs = {
  _set?: InputMaybe<Customers_Set_Input>;
  pk_columns: Customers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customers_ManyArgs = {
  updates: Array<Customers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EmployeeArgs = {
  _append?: InputMaybe<Employee_Append_Input>;
  _delete_at_path?: InputMaybe<Employee_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Employee_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Employee_Delete_Key_Input>;
  _prepend?: InputMaybe<Employee_Prepend_Input>;
  _set?: InputMaybe<Employee_Set_Input>;
  where: Employee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_AllocationsArgs = {
  _append?: InputMaybe<Employee_Allocations_Append_Input>;
  _delete_at_path?: InputMaybe<Employee_Allocations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Employee_Allocations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Employee_Allocations_Delete_Key_Input>;
  _inc?: InputMaybe<Employee_Allocations_Inc_Input>;
  _prepend?: InputMaybe<Employee_Allocations_Prepend_Input>;
  _set?: InputMaybe<Employee_Allocations_Set_Input>;
  where: Employee_Allocations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_Allocations_By_PkArgs = {
  _append?: InputMaybe<Employee_Allocations_Append_Input>;
  _delete_at_path?: InputMaybe<Employee_Allocations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Employee_Allocations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Employee_Allocations_Delete_Key_Input>;
  _inc?: InputMaybe<Employee_Allocations_Inc_Input>;
  _prepend?: InputMaybe<Employee_Allocations_Prepend_Input>;
  _set?: InputMaybe<Employee_Allocations_Set_Input>;
  pk_columns: Employee_Allocations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_Allocations_ManyArgs = {
  updates: Array<Employee_Allocations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_By_PkArgs = {
  _append?: InputMaybe<Employee_Append_Input>;
  _delete_at_path?: InputMaybe<Employee_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Employee_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Employee_Delete_Key_Input>;
  _prepend?: InputMaybe<Employee_Prepend_Input>;
  _set?: InputMaybe<Employee_Set_Input>;
  pk_columns: Employee_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Employee_ManyArgs = {
  updates: Array<Employee_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Paid_HolidaysArgs = {
  _set?: InputMaybe<Paid_Holidays_Set_Input>;
  where: Paid_Holidays_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Paid_Holidays_By_PkArgs = {
  _set?: InputMaybe<Paid_Holidays_Set_Input>;
  pk_columns: Paid_Holidays_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Paid_Holidays_ManyArgs = {
  updates: Array<Paid_Holidays_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _set?: InputMaybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _set?: InputMaybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Projects_ManyArgs = {
  updates: Array<Projects_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Salary_CoeffsArgs = {
  _inc?: InputMaybe<Salary_Coeffs_Inc_Input>;
  _set?: InputMaybe<Salary_Coeffs_Set_Input>;
  where: Salary_Coeffs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Salary_Coeffs_By_PkArgs = {
  _inc?: InputMaybe<Salary_Coeffs_Inc_Input>;
  _set?: InputMaybe<Salary_Coeffs_Set_Input>;
  pk_columns: Salary_Coeffs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Salary_Coeffs_ManyArgs = {
  updates: Array<Salary_Coeffs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Time_EntriesArgs = {
  _inc?: InputMaybe<Time_Entries_Inc_Input>;
  _set?: InputMaybe<Time_Entries_Set_Input>;
  where: Time_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Time_Entries_By_PkArgs = {
  _inc?: InputMaybe<Time_Entries_Inc_Input>;
  _set?: InputMaybe<Time_Entries_Set_Input>;
  pk_columns: Time_Entries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Time_Entries_ManyArgs = {
  updates: Array<Time_Entries_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "paid_holidays" */
export type Paid_Holidays = {
  __typename?: 'paid_holidays';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day: Scalars['date'];
  id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "paid_holidays" */
export type Paid_Holidays_Aggregate = {
  __typename?: 'paid_holidays_aggregate';
  aggregate?: Maybe<Paid_Holidays_Aggregate_Fields>;
  nodes: Array<Paid_Holidays>;
};

/** aggregate fields of "paid_holidays" */
export type Paid_Holidays_Aggregate_Fields = {
  __typename?: 'paid_holidays_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Paid_Holidays_Max_Fields>;
  min?: Maybe<Paid_Holidays_Min_Fields>;
};


/** aggregate fields of "paid_holidays" */
export type Paid_Holidays_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Paid_Holidays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "paid_holidays". All fields are combined with a logical 'AND'. */
export type Paid_Holidays_Bool_Exp = {
  _and?: InputMaybe<Array<Paid_Holidays_Bool_Exp>>;
  _not?: InputMaybe<Paid_Holidays_Bool_Exp>;
  _or?: InputMaybe<Array<Paid_Holidays_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "paid_holidays" */
export enum Paid_Holidays_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaidHolidaysPkey = 'paid_holidays_pkey'
}

/** input type for inserting data into table "paid_holidays" */
export type Paid_Holidays_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Paid_Holidays_Max_Fields = {
  __typename?: 'paid_holidays_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Paid_Holidays_Min_Fields = {
  __typename?: 'paid_holidays_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "paid_holidays" */
export type Paid_Holidays_Mutation_Response = {
  __typename?: 'paid_holidays_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Paid_Holidays>;
};

/** on_conflict condition type for table "paid_holidays" */
export type Paid_Holidays_On_Conflict = {
  constraint: Paid_Holidays_Constraint;
  update_columns?: Array<Paid_Holidays_Update_Column>;
  where?: InputMaybe<Paid_Holidays_Bool_Exp>;
};

/** Ordering options when selecting data from "paid_holidays". */
export type Paid_Holidays_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: paid_holidays */
export type Paid_Holidays_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "paid_holidays" */
export enum Paid_Holidays_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "paid_holidays" */
export type Paid_Holidays_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "paid_holidays" */
export type Paid_Holidays_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Paid_Holidays_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Paid_Holidays_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "paid_holidays" */
export enum Paid_Holidays_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Paid_Holidays_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Paid_Holidays_Set_Input>;
  where: Paid_Holidays_Bool_Exp;
};

/** columns and relationships of "projects" */
export type Projects = {
  __typename?: 'projects';
  /** An array relationship */
  assignments: Array<Assignments>;
  /** An aggregate relationship */
  assignments_aggregate: Assignments_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  customer?: Maybe<Customers>;
  customer_id?: Maybe<Scalars['uuid']>;
  end_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  po?: Maybe<Scalars['String']>;
  /** An object relationship */
  sales?: Maybe<Employee>;
  sales_person?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};


/** columns and relationships of "projects" */
export type ProjectsAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


/** columns and relationships of "projects" */
export type ProjectsAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<Projects_Aggregate_Fields>;
  nodes: Array<Projects>;
};

export type Projects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Projects_Aggregate_Bool_Exp_Count>;
};

export type Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  __typename?: 'projects_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Projects_Max_Fields>;
  min?: Maybe<Projects_Min_Fields>;
};


/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "projects" */
export type Projects_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Projects_Max_Order_By>;
  min?: InputMaybe<Projects_Min_Order_By>;
};

/** input type for inserting array relation for remote table "projects" */
export type Projects_Arr_Rel_Insert_Input = {
  data: Array<Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Projects_Bool_Exp>>;
  _not?: InputMaybe<Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_Bool_Exp>>;
  assignments?: InputMaybe<Assignments_Bool_Exp>;
  assignments_aggregate?: InputMaybe<Assignments_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Customers_Bool_Exp>;
  customer_id?: InputMaybe<Uuid_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  po?: InputMaybe<String_Comparison_Exp>;
  sales?: InputMaybe<Employee_Bool_Exp>;
  sales_person?: InputMaybe<Uuid_Comparison_Exp>;
  start_date?: InputMaybe<Date_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey'
}

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  assignments?: InputMaybe<Assignments_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  po?: InputMaybe<Scalars['String']>;
  sales?: InputMaybe<Employee_Obj_Rel_Insert_Input>;
  sales_person?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  __typename?: 'projects_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  po?: Maybe<Scalars['String']>;
  sales_person?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "projects" */
export type Projects_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  po?: InputMaybe<Order_By>;
  sales_person?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  __typename?: 'projects_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  po?: Maybe<Scalars['String']>;
  sales_person?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "projects" */
export type Projects_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  po?: InputMaybe<Order_By>;
  sales_person?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  __typename?: 'projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type Projects_Obj_Rel_Insert_Input = {
  data: Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** on_conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  constraint: Projects_Constraint;
  update_columns?: Array<Projects_Update_Column>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  assignments_aggregate?: InputMaybe<Assignments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer?: InputMaybe<Customers_Order_By>;
  customer_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  po?: InputMaybe<Order_By>;
  sales?: InputMaybe<Employee_Order_By>;
  sales_person?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects */
export type Projects_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Po = 'po',
  /** column name */
  SalesPerson = 'sales_person',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  po?: InputMaybe<Scalars['String']>;
  sales_person?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "projects" */
export type Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['uuid']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  po?: InputMaybe<Scalars['String']>;
  sales_person?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Po = 'po',
  /** column name */
  SalesPerson = 'sales_person',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Projects_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  assignments: Array<Assignments>;
  /** An aggregate relationship */
  assignments_aggregate: Assignments_Aggregate;
  /** fetch data from the table: "assignments" using primary key columns */
  assignments_by_pk?: Maybe<Assignments>;
  /** An array relationship */
  balance_corrections: Array<Balance_Corrections>;
  /** An aggregate relationship */
  balance_corrections_aggregate: Balance_Corrections_Aggregate;
  /** fetch data from the table: "balance_corrections" using primary key columns */
  balance_corrections_by_pk?: Maybe<Balance_Corrections>;
  /** execute function "billing_by_project_and_employee" which returns "billing_by_project_and_employee_tmp_table" */
  billing_by_project_and_employee: Array<Billing_By_Project_And_Employee_Tmp_Table>;
  /** execute function "billing_by_project_and_employee" and query aggregates on result of table type "billing_by_project_and_employee_tmp_table" */
  billing_by_project_and_employee_aggregate: Billing_By_Project_And_Employee_Tmp_Table_Aggregate;
  /** fetch data from the table: "billing_by_project_and_employee_tmp_table" */
  billing_by_project_and_employee_tmp_table: Array<Billing_By_Project_And_Employee_Tmp_Table>;
  /** fetch aggregated fields from the table: "billing_by_project_and_employee_tmp_table" */
  billing_by_project_and_employee_tmp_table_aggregate: Billing_By_Project_And_Employee_Tmp_Table_Aggregate;
  /** execute function "calendar" which returns "calendar_tmp_table" */
  calendar: Array<Calendar_Tmp_Table>;
  /** execute function "calendar" and query aggregates on result of table type "calendar_tmp_table" */
  calendar_aggregate: Calendar_Tmp_Table_Aggregate;
  /** fetch data from the table: "calendar_tmp_table" */
  calendar_tmp_table: Array<Calendar_Tmp_Table>;
  /** fetch aggregated fields from the table: "calendar_tmp_table" */
  calendar_tmp_table_aggregate: Calendar_Tmp_Table_Aggregate;
  /** fetch data from the table: "company_monthly_billing_report" */
  company_monthly_billing_report: Array<Company_Monthly_Billing_Report>;
  /** fetch aggregated fields from the table: "company_monthly_billing_report" */
  company_monthly_billing_report_aggregate: Company_Monthly_Billing_Report_Aggregate;
  /** fetch data from the table: "customers" */
  customers: Array<Customers>;
  /** fetch aggregated fields from the table: "customers" */
  customers_aggregate: Customers_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  /** fetch data from the table: "employees" */
  employee: Array<Employee>;
  /** fetch aggregated fields from the table: "employees" */
  employee_aggregate: Employee_Aggregate;
  /** fetch data from the table: "employee_aggregated_hours" */
  employee_aggregated_hours: Array<Employee_Aggregated_Hours>;
  /** fetch aggregated fields from the table: "employee_aggregated_hours" */
  employee_aggregated_hours_aggregate: Employee_Aggregated_Hours_Aggregate;
  /** An array relationship */
  employee_allocations: Array<Employee_Allocations>;
  /** An aggregate relationship */
  employee_allocations_aggregate: Employee_Allocations_Aggregate;
  /** fetch data from the table: "employee_allocations" using primary key columns */
  employee_allocations_by_pk?: Maybe<Employee_Allocations>;
  /** fetch data from the table: "employee_balance" */
  employee_balance: Array<Employee_Balance>;
  /** fetch aggregated fields from the table: "employee_balance" */
  employee_balance_aggregate: Employee_Balance_Aggregate;
  /** fetch data from the table: "employees" using primary key columns */
  employee_by_pk?: Maybe<Employee>;
  /** fetch data from the table: "employee_monthly_billing_report" */
  employee_monthly_billing_report: Array<Employee_Monthly_Billing_Report>;
  /** fetch aggregated fields from the table: "employee_monthly_billing_report" */
  employee_monthly_billing_report_aggregate: Employee_Monthly_Billing_Report_Aggregate;
  /** fetch data from the table: "employee_monthly_hours_report" */
  employee_monthly_hours_report: Array<Employee_Monthly_Hours_Report>;
  /** fetch aggregated fields from the table: "employee_monthly_hours_report" */
  employee_monthly_hours_report_aggregate: Employee_Monthly_Hours_Report_Aggregate;
  /** fetch data from the table: "employee_monthly_payroll_report" */
  employee_monthly_payroll_report: Array<Employee_Monthly_Payroll_Report>;
  /** fetch aggregated fields from the table: "employee_monthly_payroll_report" */
  employee_monthly_payroll_report_aggregate: Employee_Monthly_Payroll_Report_Aggregate;
  /** fetch data from the table: "employee_monthly_predicted_payroll" */
  employee_monthly_predicted_payroll: Array<Employee_Monthly_Predicted_Payroll>;
  /** fetch aggregated fields from the table: "employee_monthly_predicted_payroll" */
  employee_monthly_predicted_payroll_aggregate: Employee_Monthly_Predicted_Payroll_Aggregate;
  /** fetch data from the table: "paid_holidays" */
  paid_holidays: Array<Paid_Holidays>;
  /** fetch aggregated fields from the table: "paid_holidays" */
  paid_holidays_aggregate: Paid_Holidays_Aggregate;
  /** fetch data from the table: "paid_holidays" using primary key columns */
  paid_holidays_by_pk?: Maybe<Paid_Holidays>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "salary_coeffs" */
  salary_coeffs: Array<Salary_Coeffs>;
  /** fetch aggregated fields from the table: "salary_coeffs" */
  salary_coeffs_aggregate: Salary_Coeffs_Aggregate;
  /** fetch data from the table: "salary_coeffs" using primary key columns */
  salary_coeffs_by_pk?: Maybe<Salary_Coeffs>;
  /** An array relationship */
  time_entries: Array<Time_Entries>;
  /** An aggregate relationship */
  time_entries_aggregate: Time_Entries_Aggregate;
  /** fetch data from the table: "time_entries" using primary key columns */
  time_entries_by_pk?: Maybe<Time_Entries>;
};


export type Query_RootAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Query_RootAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Query_RootAssignments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBalance_CorrectionsArgs = {
  distinct_on?: InputMaybe<Array<Balance_Corrections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Corrections_Order_By>>;
  where?: InputMaybe<Balance_Corrections_Bool_Exp>;
};


export type Query_RootBalance_Corrections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Balance_Corrections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Corrections_Order_By>>;
  where?: InputMaybe<Balance_Corrections_Bool_Exp>;
};


export type Query_RootBalance_Corrections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBilling_By_Project_And_EmployeeArgs = {
  args: Billing_By_Project_And_Employee_Args;
  distinct_on?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Order_By>>;
  where?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
};


export type Query_RootBilling_By_Project_And_Employee_AggregateArgs = {
  args: Billing_By_Project_And_Employee_Args;
  distinct_on?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Order_By>>;
  where?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
};


export type Query_RootBilling_By_Project_And_Employee_Tmp_TableArgs = {
  distinct_on?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Order_By>>;
  where?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
};


export type Query_RootBilling_By_Project_And_Employee_Tmp_Table_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Order_By>>;
  where?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
};


export type Query_RootCalendarArgs = {
  args: Calendar_Args;
  distinct_on?: InputMaybe<Array<Calendar_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Tmp_Table_Order_By>>;
  where?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
};


export type Query_RootCalendar_AggregateArgs = {
  args: Calendar_Args;
  distinct_on?: InputMaybe<Array<Calendar_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Tmp_Table_Order_By>>;
  where?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
};


export type Query_RootCalendar_Tmp_TableArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Tmp_Table_Order_By>>;
  where?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
};


export type Query_RootCalendar_Tmp_Table_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Tmp_Table_Order_By>>;
  where?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
};


export type Query_RootCompany_Monthly_Billing_ReportArgs = {
  distinct_on?: InputMaybe<Array<Company_Monthly_Billing_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Monthly_Billing_Report_Order_By>>;
  where?: InputMaybe<Company_Monthly_Billing_Report_Bool_Exp>;
};


export type Query_RootCompany_Monthly_Billing_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Monthly_Billing_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Monthly_Billing_Report_Order_By>>;
  where?: InputMaybe<Company_Monthly_Billing_Report_Bool_Exp>;
};


export type Query_RootCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Query_RootCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Query_RootCustomers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmployeeArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Query_RootEmployee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Query_RootEmployee_Aggregated_HoursArgs = {
  distinct_on?: InputMaybe<Array<Employee_Aggregated_Hours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Aggregated_Hours_Order_By>>;
  where?: InputMaybe<Employee_Aggregated_Hours_Bool_Exp>;
};


export type Query_RootEmployee_Aggregated_Hours_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Aggregated_Hours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Aggregated_Hours_Order_By>>;
  where?: InputMaybe<Employee_Aggregated_Hours_Bool_Exp>;
};


export type Query_RootEmployee_AllocationsArgs = {
  distinct_on?: InputMaybe<Array<Employee_Allocations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Allocations_Order_By>>;
  where?: InputMaybe<Employee_Allocations_Bool_Exp>;
};


export type Query_RootEmployee_Allocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Allocations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Allocations_Order_By>>;
  where?: InputMaybe<Employee_Allocations_Bool_Exp>;
};


export type Query_RootEmployee_Allocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmployee_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Employee_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Balance_Order_By>>;
  where?: InputMaybe<Employee_Balance_Bool_Exp>;
};


export type Query_RootEmployee_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Balance_Order_By>>;
  where?: InputMaybe<Employee_Balance_Bool_Exp>;
};


export type Query_RootEmployee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEmployee_Monthly_Billing_ReportArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Billing_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Billing_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Billing_Report_Bool_Exp>;
};


export type Query_RootEmployee_Monthly_Billing_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Billing_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Billing_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Billing_Report_Bool_Exp>;
};


export type Query_RootEmployee_Monthly_Hours_ReportArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Hours_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Hours_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
};


export type Query_RootEmployee_Monthly_Hours_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Hours_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Hours_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
};


export type Query_RootEmployee_Monthly_Payroll_ReportArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
};


export type Query_RootEmployee_Monthly_Payroll_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
};


export type Query_RootEmployee_Monthly_Predicted_PayrollArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
};


export type Query_RootEmployee_Monthly_Predicted_Payroll_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
};


export type Query_RootPaid_HolidaysArgs = {
  distinct_on?: InputMaybe<Array<Paid_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Paid_Holidays_Order_By>>;
  where?: InputMaybe<Paid_Holidays_Bool_Exp>;
};


export type Query_RootPaid_Holidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Paid_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Paid_Holidays_Order_By>>;
  where?: InputMaybe<Paid_Holidays_Bool_Exp>;
};


export type Query_RootPaid_Holidays_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Query_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSalary_CoeffsArgs = {
  distinct_on?: InputMaybe<Array<Salary_Coeffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Coeffs_Order_By>>;
  where?: InputMaybe<Salary_Coeffs_Bool_Exp>;
};


export type Query_RootSalary_Coeffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salary_Coeffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Coeffs_Order_By>>;
  where?: InputMaybe<Salary_Coeffs_Bool_Exp>;
};


export type Query_RootSalary_Coeffs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTime_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Time_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Entries_Order_By>>;
  where?: InputMaybe<Time_Entries_Bool_Exp>;
};


export type Query_RootTime_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Time_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Entries_Order_By>>;
  where?: InputMaybe<Time_Entries_Bool_Exp>;
};


export type Query_RootTime_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "salary_coeffs" */
export type Salary_Coeffs = {
  __typename?: 'salary_coeffs';
  base_salary?: Maybe<Scalars['numeric']>;
  bonus_percentage?: Maybe<Scalars['numeric']>;
  company_side_cost_coef?: Maybe<Scalars['numeric']>;
  day_wage?: Maybe<Scalars['numeric']>;
  hourly_wage?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  period?: Maybe<Scalars['daterange']>;
};

/** aggregated selection of "salary_coeffs" */
export type Salary_Coeffs_Aggregate = {
  __typename?: 'salary_coeffs_aggregate';
  aggregate?: Maybe<Salary_Coeffs_Aggregate_Fields>;
  nodes: Array<Salary_Coeffs>;
};

/** aggregate fields of "salary_coeffs" */
export type Salary_Coeffs_Aggregate_Fields = {
  __typename?: 'salary_coeffs_aggregate_fields';
  avg?: Maybe<Salary_Coeffs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Salary_Coeffs_Max_Fields>;
  min?: Maybe<Salary_Coeffs_Min_Fields>;
  stddev?: Maybe<Salary_Coeffs_Stddev_Fields>;
  stddev_pop?: Maybe<Salary_Coeffs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Salary_Coeffs_Stddev_Samp_Fields>;
  sum?: Maybe<Salary_Coeffs_Sum_Fields>;
  var_pop?: Maybe<Salary_Coeffs_Var_Pop_Fields>;
  var_samp?: Maybe<Salary_Coeffs_Var_Samp_Fields>;
  variance?: Maybe<Salary_Coeffs_Variance_Fields>;
};


/** aggregate fields of "salary_coeffs" */
export type Salary_Coeffs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Salary_Coeffs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Salary_Coeffs_Avg_Fields = {
  __typename?: 'salary_coeffs_avg_fields';
  base_salary?: Maybe<Scalars['Float']>;
  bonus_percentage?: Maybe<Scalars['Float']>;
  company_side_cost_coef?: Maybe<Scalars['Float']>;
  day_wage?: Maybe<Scalars['Float']>;
  hourly_wage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "salary_coeffs". All fields are combined with a logical 'AND'. */
export type Salary_Coeffs_Bool_Exp = {
  _and?: InputMaybe<Array<Salary_Coeffs_Bool_Exp>>;
  _not?: InputMaybe<Salary_Coeffs_Bool_Exp>;
  _or?: InputMaybe<Array<Salary_Coeffs_Bool_Exp>>;
  base_salary?: InputMaybe<Numeric_Comparison_Exp>;
  bonus_percentage?: InputMaybe<Numeric_Comparison_Exp>;
  company_side_cost_coef?: InputMaybe<Numeric_Comparison_Exp>;
  day_wage?: InputMaybe<Numeric_Comparison_Exp>;
  hourly_wage?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  period?: InputMaybe<Daterange_Comparison_Exp>;
};

/** unique or primary key constraints on table "salary_coeffs" */
export enum Salary_Coeffs_Constraint {
  /** unique or primary key constraint on columns "id" */
  SalaryCoeffsPkey = 'salary_coeffs_pkey'
}

/** input type for incrementing numeric columns in table "salary_coeffs" */
export type Salary_Coeffs_Inc_Input = {
  base_salary?: InputMaybe<Scalars['numeric']>;
  bonus_percentage?: InputMaybe<Scalars['numeric']>;
  company_side_cost_coef?: InputMaybe<Scalars['numeric']>;
  day_wage?: InputMaybe<Scalars['numeric']>;
  hourly_wage?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "salary_coeffs" */
export type Salary_Coeffs_Insert_Input = {
  base_salary?: InputMaybe<Scalars['numeric']>;
  bonus_percentage?: InputMaybe<Scalars['numeric']>;
  company_side_cost_coef?: InputMaybe<Scalars['numeric']>;
  day_wage?: InputMaybe<Scalars['numeric']>;
  hourly_wage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  period?: InputMaybe<Scalars['daterange']>;
};

/** aggregate max on columns */
export type Salary_Coeffs_Max_Fields = {
  __typename?: 'salary_coeffs_max_fields';
  base_salary?: Maybe<Scalars['numeric']>;
  bonus_percentage?: Maybe<Scalars['numeric']>;
  company_side_cost_coef?: Maybe<Scalars['numeric']>;
  day_wage?: Maybe<Scalars['numeric']>;
  hourly_wage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Salary_Coeffs_Min_Fields = {
  __typename?: 'salary_coeffs_min_fields';
  base_salary?: Maybe<Scalars['numeric']>;
  bonus_percentage?: Maybe<Scalars['numeric']>;
  company_side_cost_coef?: Maybe<Scalars['numeric']>;
  day_wage?: Maybe<Scalars['numeric']>;
  hourly_wage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "salary_coeffs" */
export type Salary_Coeffs_Mutation_Response = {
  __typename?: 'salary_coeffs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Salary_Coeffs>;
};

/** on_conflict condition type for table "salary_coeffs" */
export type Salary_Coeffs_On_Conflict = {
  constraint: Salary_Coeffs_Constraint;
  update_columns?: Array<Salary_Coeffs_Update_Column>;
  where?: InputMaybe<Salary_Coeffs_Bool_Exp>;
};

/** Ordering options when selecting data from "salary_coeffs". */
export type Salary_Coeffs_Order_By = {
  base_salary?: InputMaybe<Order_By>;
  bonus_percentage?: InputMaybe<Order_By>;
  company_side_cost_coef?: InputMaybe<Order_By>;
  day_wage?: InputMaybe<Order_By>;
  hourly_wage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  period?: InputMaybe<Order_By>;
};

/** primary key columns input for table: salary_coeffs */
export type Salary_Coeffs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "salary_coeffs" */
export enum Salary_Coeffs_Select_Column {
  /** column name */
  BaseSalary = 'base_salary',
  /** column name */
  BonusPercentage = 'bonus_percentage',
  /** column name */
  CompanySideCostCoef = 'company_side_cost_coef',
  /** column name */
  DayWage = 'day_wage',
  /** column name */
  HourlyWage = 'hourly_wage',
  /** column name */
  Id = 'id',
  /** column name */
  Period = 'period'
}

/** input type for updating data in table "salary_coeffs" */
export type Salary_Coeffs_Set_Input = {
  base_salary?: InputMaybe<Scalars['numeric']>;
  bonus_percentage?: InputMaybe<Scalars['numeric']>;
  company_side_cost_coef?: InputMaybe<Scalars['numeric']>;
  day_wage?: InputMaybe<Scalars['numeric']>;
  hourly_wage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  period?: InputMaybe<Scalars['daterange']>;
};

/** aggregate stddev on columns */
export type Salary_Coeffs_Stddev_Fields = {
  __typename?: 'salary_coeffs_stddev_fields';
  base_salary?: Maybe<Scalars['Float']>;
  bonus_percentage?: Maybe<Scalars['Float']>;
  company_side_cost_coef?: Maybe<Scalars['Float']>;
  day_wage?: Maybe<Scalars['Float']>;
  hourly_wage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Salary_Coeffs_Stddev_Pop_Fields = {
  __typename?: 'salary_coeffs_stddev_pop_fields';
  base_salary?: Maybe<Scalars['Float']>;
  bonus_percentage?: Maybe<Scalars['Float']>;
  company_side_cost_coef?: Maybe<Scalars['Float']>;
  day_wage?: Maybe<Scalars['Float']>;
  hourly_wage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Salary_Coeffs_Stddev_Samp_Fields = {
  __typename?: 'salary_coeffs_stddev_samp_fields';
  base_salary?: Maybe<Scalars['Float']>;
  bonus_percentage?: Maybe<Scalars['Float']>;
  company_side_cost_coef?: Maybe<Scalars['Float']>;
  day_wage?: Maybe<Scalars['Float']>;
  hourly_wage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "salary_coeffs" */
export type Salary_Coeffs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Salary_Coeffs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Salary_Coeffs_Stream_Cursor_Value_Input = {
  base_salary?: InputMaybe<Scalars['numeric']>;
  bonus_percentage?: InputMaybe<Scalars['numeric']>;
  company_side_cost_coef?: InputMaybe<Scalars['numeric']>;
  day_wage?: InputMaybe<Scalars['numeric']>;
  hourly_wage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  period?: InputMaybe<Scalars['daterange']>;
};

/** aggregate sum on columns */
export type Salary_Coeffs_Sum_Fields = {
  __typename?: 'salary_coeffs_sum_fields';
  base_salary?: Maybe<Scalars['numeric']>;
  bonus_percentage?: Maybe<Scalars['numeric']>;
  company_side_cost_coef?: Maybe<Scalars['numeric']>;
  day_wage?: Maybe<Scalars['numeric']>;
  hourly_wage?: Maybe<Scalars['numeric']>;
};

/** update columns of table "salary_coeffs" */
export enum Salary_Coeffs_Update_Column {
  /** column name */
  BaseSalary = 'base_salary',
  /** column name */
  BonusPercentage = 'bonus_percentage',
  /** column name */
  CompanySideCostCoef = 'company_side_cost_coef',
  /** column name */
  DayWage = 'day_wage',
  /** column name */
  HourlyWage = 'hourly_wage',
  /** column name */
  Id = 'id',
  /** column name */
  Period = 'period'
}

export type Salary_Coeffs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Salary_Coeffs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Salary_Coeffs_Set_Input>;
  where: Salary_Coeffs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Salary_Coeffs_Var_Pop_Fields = {
  __typename?: 'salary_coeffs_var_pop_fields';
  base_salary?: Maybe<Scalars['Float']>;
  bonus_percentage?: Maybe<Scalars['Float']>;
  company_side_cost_coef?: Maybe<Scalars['Float']>;
  day_wage?: Maybe<Scalars['Float']>;
  hourly_wage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Salary_Coeffs_Var_Samp_Fields = {
  __typename?: 'salary_coeffs_var_samp_fields';
  base_salary?: Maybe<Scalars['Float']>;
  bonus_percentage?: Maybe<Scalars['Float']>;
  company_side_cost_coef?: Maybe<Scalars['Float']>;
  day_wage?: Maybe<Scalars['Float']>;
  hourly_wage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Salary_Coeffs_Variance_Fields = {
  __typename?: 'salary_coeffs_variance_fields';
  base_salary?: Maybe<Scalars['Float']>;
  bonus_percentage?: Maybe<Scalars['Float']>;
  company_side_cost_coef?: Maybe<Scalars['Float']>;
  day_wage?: Maybe<Scalars['Float']>;
  hourly_wage?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  assignments: Array<Assignments>;
  /** An aggregate relationship */
  assignments_aggregate: Assignments_Aggregate;
  /** fetch data from the table: "assignments" using primary key columns */
  assignments_by_pk?: Maybe<Assignments>;
  /** fetch data from the table in a streaming manner: "assignments" */
  assignments_stream: Array<Assignments>;
  /** An array relationship */
  balance_corrections: Array<Balance_Corrections>;
  /** An aggregate relationship */
  balance_corrections_aggregate: Balance_Corrections_Aggregate;
  /** fetch data from the table: "balance_corrections" using primary key columns */
  balance_corrections_by_pk?: Maybe<Balance_Corrections>;
  /** fetch data from the table in a streaming manner: "balance_corrections" */
  balance_corrections_stream: Array<Balance_Corrections>;
  /** execute function "billing_by_project_and_employee" which returns "billing_by_project_and_employee_tmp_table" */
  billing_by_project_and_employee: Array<Billing_By_Project_And_Employee_Tmp_Table>;
  /** execute function "billing_by_project_and_employee" and query aggregates on result of table type "billing_by_project_and_employee_tmp_table" */
  billing_by_project_and_employee_aggregate: Billing_By_Project_And_Employee_Tmp_Table_Aggregate;
  /** fetch data from the table: "billing_by_project_and_employee_tmp_table" */
  billing_by_project_and_employee_tmp_table: Array<Billing_By_Project_And_Employee_Tmp_Table>;
  /** fetch aggregated fields from the table: "billing_by_project_and_employee_tmp_table" */
  billing_by_project_and_employee_tmp_table_aggregate: Billing_By_Project_And_Employee_Tmp_Table_Aggregate;
  /** fetch data from the table in a streaming manner: "billing_by_project_and_employee_tmp_table" */
  billing_by_project_and_employee_tmp_table_stream: Array<Billing_By_Project_And_Employee_Tmp_Table>;
  /** execute function "calendar" which returns "calendar_tmp_table" */
  calendar: Array<Calendar_Tmp_Table>;
  /** execute function "calendar" and query aggregates on result of table type "calendar_tmp_table" */
  calendar_aggregate: Calendar_Tmp_Table_Aggregate;
  /** fetch data from the table: "calendar_tmp_table" */
  calendar_tmp_table: Array<Calendar_Tmp_Table>;
  /** fetch aggregated fields from the table: "calendar_tmp_table" */
  calendar_tmp_table_aggregate: Calendar_Tmp_Table_Aggregate;
  /** fetch data from the table in a streaming manner: "calendar_tmp_table" */
  calendar_tmp_table_stream: Array<Calendar_Tmp_Table>;
  /** fetch data from the table: "company_monthly_billing_report" */
  company_monthly_billing_report: Array<Company_Monthly_Billing_Report>;
  /** fetch aggregated fields from the table: "company_monthly_billing_report" */
  company_monthly_billing_report_aggregate: Company_Monthly_Billing_Report_Aggregate;
  /** fetch data from the table in a streaming manner: "company_monthly_billing_report" */
  company_monthly_billing_report_stream: Array<Company_Monthly_Billing_Report>;
  /** fetch data from the table: "customers" */
  customers: Array<Customers>;
  /** fetch aggregated fields from the table: "customers" */
  customers_aggregate: Customers_Aggregate;
  /** fetch data from the table: "customers" using primary key columns */
  customers_by_pk?: Maybe<Customers>;
  /** fetch data from the table in a streaming manner: "customers" */
  customers_stream: Array<Customers>;
  /** fetch data from the table: "employees" */
  employee: Array<Employee>;
  /** fetch aggregated fields from the table: "employees" */
  employee_aggregate: Employee_Aggregate;
  /** fetch data from the table: "employee_aggregated_hours" */
  employee_aggregated_hours: Array<Employee_Aggregated_Hours>;
  /** fetch aggregated fields from the table: "employee_aggregated_hours" */
  employee_aggregated_hours_aggregate: Employee_Aggregated_Hours_Aggregate;
  /** fetch data from the table in a streaming manner: "employee_aggregated_hours" */
  employee_aggregated_hours_stream: Array<Employee_Aggregated_Hours>;
  /** An array relationship */
  employee_allocations: Array<Employee_Allocations>;
  /** An aggregate relationship */
  employee_allocations_aggregate: Employee_Allocations_Aggregate;
  /** fetch data from the table: "employee_allocations" using primary key columns */
  employee_allocations_by_pk?: Maybe<Employee_Allocations>;
  /** fetch data from the table in a streaming manner: "employee_allocations" */
  employee_allocations_stream: Array<Employee_Allocations>;
  /** fetch data from the table: "employee_balance" */
  employee_balance: Array<Employee_Balance>;
  /** fetch aggregated fields from the table: "employee_balance" */
  employee_balance_aggregate: Employee_Balance_Aggregate;
  /** fetch data from the table in a streaming manner: "employee_balance" */
  employee_balance_stream: Array<Employee_Balance>;
  /** fetch data from the table: "employees" using primary key columns */
  employee_by_pk?: Maybe<Employee>;
  /** fetch data from the table: "employee_monthly_billing_report" */
  employee_monthly_billing_report: Array<Employee_Monthly_Billing_Report>;
  /** fetch aggregated fields from the table: "employee_monthly_billing_report" */
  employee_monthly_billing_report_aggregate: Employee_Monthly_Billing_Report_Aggregate;
  /** fetch data from the table in a streaming manner: "employee_monthly_billing_report" */
  employee_monthly_billing_report_stream: Array<Employee_Monthly_Billing_Report>;
  /** fetch data from the table: "employee_monthly_hours_report" */
  employee_monthly_hours_report: Array<Employee_Monthly_Hours_Report>;
  /** fetch aggregated fields from the table: "employee_monthly_hours_report" */
  employee_monthly_hours_report_aggregate: Employee_Monthly_Hours_Report_Aggregate;
  /** fetch data from the table in a streaming manner: "employee_monthly_hours_report" */
  employee_monthly_hours_report_stream: Array<Employee_Monthly_Hours_Report>;
  /** fetch data from the table: "employee_monthly_payroll_report" */
  employee_monthly_payroll_report: Array<Employee_Monthly_Payroll_Report>;
  /** fetch aggregated fields from the table: "employee_monthly_payroll_report" */
  employee_monthly_payroll_report_aggregate: Employee_Monthly_Payroll_Report_Aggregate;
  /** fetch data from the table in a streaming manner: "employee_monthly_payroll_report" */
  employee_monthly_payroll_report_stream: Array<Employee_Monthly_Payroll_Report>;
  /** fetch data from the table: "employee_monthly_predicted_payroll" */
  employee_monthly_predicted_payroll: Array<Employee_Monthly_Predicted_Payroll>;
  /** fetch aggregated fields from the table: "employee_monthly_predicted_payroll" */
  employee_monthly_predicted_payroll_aggregate: Employee_Monthly_Predicted_Payroll_Aggregate;
  /** fetch data from the table in a streaming manner: "employee_monthly_predicted_payroll" */
  employee_monthly_predicted_payroll_stream: Array<Employee_Monthly_Predicted_Payroll>;
  /** fetch data from the table in a streaming manner: "employees" */
  employee_stream: Array<Employee>;
  /** fetch data from the table: "paid_holidays" */
  paid_holidays: Array<Paid_Holidays>;
  /** fetch aggregated fields from the table: "paid_holidays" */
  paid_holidays_aggregate: Paid_Holidays_Aggregate;
  /** fetch data from the table: "paid_holidays" using primary key columns */
  paid_holidays_by_pk?: Maybe<Paid_Holidays>;
  /** fetch data from the table in a streaming manner: "paid_holidays" */
  paid_holidays_stream: Array<Paid_Holidays>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table in a streaming manner: "projects" */
  projects_stream: Array<Projects>;
  /** fetch data from the table: "salary_coeffs" */
  salary_coeffs: Array<Salary_Coeffs>;
  /** fetch aggregated fields from the table: "salary_coeffs" */
  salary_coeffs_aggregate: Salary_Coeffs_Aggregate;
  /** fetch data from the table: "salary_coeffs" using primary key columns */
  salary_coeffs_by_pk?: Maybe<Salary_Coeffs>;
  /** fetch data from the table in a streaming manner: "salary_coeffs" */
  salary_coeffs_stream: Array<Salary_Coeffs>;
  /** An array relationship */
  time_entries: Array<Time_Entries>;
  /** An aggregate relationship */
  time_entries_aggregate: Time_Entries_Aggregate;
  /** fetch data from the table: "time_entries" using primary key columns */
  time_entries_by_pk?: Maybe<Time_Entries>;
  /** fetch data from the table in a streaming manner: "time_entries" */
  time_entries_stream: Array<Time_Entries>;
};


export type Subscription_RootAssignmentsArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Subscription_RootAssignments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Assignments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Assignments_Order_By>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Subscription_RootAssignments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAssignments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Assignments_Stream_Cursor_Input>>;
  where?: InputMaybe<Assignments_Bool_Exp>;
};


export type Subscription_RootBalance_CorrectionsArgs = {
  distinct_on?: InputMaybe<Array<Balance_Corrections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Corrections_Order_By>>;
  where?: InputMaybe<Balance_Corrections_Bool_Exp>;
};


export type Subscription_RootBalance_Corrections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Balance_Corrections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Balance_Corrections_Order_By>>;
  where?: InputMaybe<Balance_Corrections_Bool_Exp>;
};


export type Subscription_RootBalance_Corrections_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBalance_Corrections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Balance_Corrections_Stream_Cursor_Input>>;
  where?: InputMaybe<Balance_Corrections_Bool_Exp>;
};


export type Subscription_RootBilling_By_Project_And_EmployeeArgs = {
  args: Billing_By_Project_And_Employee_Args;
  distinct_on?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Order_By>>;
  where?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootBilling_By_Project_And_Employee_AggregateArgs = {
  args: Billing_By_Project_And_Employee_Args;
  distinct_on?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Order_By>>;
  where?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootBilling_By_Project_And_Employee_Tmp_TableArgs = {
  distinct_on?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Order_By>>;
  where?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootBilling_By_Project_And_Employee_Tmp_Table_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_By_Project_And_Employee_Tmp_Table_Order_By>>;
  where?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootBilling_By_Project_And_Employee_Tmp_Table_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Stream_Cursor_Input>>;
  where?: InputMaybe<Billing_By_Project_And_Employee_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootCalendarArgs = {
  args: Calendar_Args;
  distinct_on?: InputMaybe<Array<Calendar_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Tmp_Table_Order_By>>;
  where?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootCalendar_AggregateArgs = {
  args: Calendar_Args;
  distinct_on?: InputMaybe<Array<Calendar_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Tmp_Table_Order_By>>;
  where?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootCalendar_Tmp_TableArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Tmp_Table_Order_By>>;
  where?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootCalendar_Tmp_Table_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Calendar_Tmp_Table_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Calendar_Tmp_Table_Order_By>>;
  where?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootCalendar_Tmp_Table_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Calendar_Tmp_Table_Stream_Cursor_Input>>;
  where?: InputMaybe<Calendar_Tmp_Table_Bool_Exp>;
};


export type Subscription_RootCompany_Monthly_Billing_ReportArgs = {
  distinct_on?: InputMaybe<Array<Company_Monthly_Billing_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Monthly_Billing_Report_Order_By>>;
  where?: InputMaybe<Company_Monthly_Billing_Report_Bool_Exp>;
};


export type Subscription_RootCompany_Monthly_Billing_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Monthly_Billing_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Monthly_Billing_Report_Order_By>>;
  where?: InputMaybe<Company_Monthly_Billing_Report_Bool_Exp>;
};


export type Subscription_RootCompany_Monthly_Billing_Report_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Monthly_Billing_Report_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Monthly_Billing_Report_Bool_Exp>;
};


export type Subscription_RootCustomersArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customers_Order_By>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Subscription_RootCustomers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customers_Stream_Cursor_Input>>;
  where?: InputMaybe<Customers_Bool_Exp>;
};


export type Subscription_RootEmployeeArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Subscription_RootEmployee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Order_By>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Subscription_RootEmployee_Aggregated_HoursArgs = {
  distinct_on?: InputMaybe<Array<Employee_Aggregated_Hours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Aggregated_Hours_Order_By>>;
  where?: InputMaybe<Employee_Aggregated_Hours_Bool_Exp>;
};


export type Subscription_RootEmployee_Aggregated_Hours_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Aggregated_Hours_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Aggregated_Hours_Order_By>>;
  where?: InputMaybe<Employee_Aggregated_Hours_Bool_Exp>;
};


export type Subscription_RootEmployee_Aggregated_Hours_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Aggregated_Hours_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Aggregated_Hours_Bool_Exp>;
};


export type Subscription_RootEmployee_AllocationsArgs = {
  distinct_on?: InputMaybe<Array<Employee_Allocations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Allocations_Order_By>>;
  where?: InputMaybe<Employee_Allocations_Bool_Exp>;
};


export type Subscription_RootEmployee_Allocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Allocations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Allocations_Order_By>>;
  where?: InputMaybe<Employee_Allocations_Bool_Exp>;
};


export type Subscription_RootEmployee_Allocations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmployee_Allocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Allocations_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Allocations_Bool_Exp>;
};


export type Subscription_RootEmployee_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Employee_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Balance_Order_By>>;
  where?: InputMaybe<Employee_Balance_Bool_Exp>;
};


export type Subscription_RootEmployee_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Balance_Order_By>>;
  where?: InputMaybe<Employee_Balance_Bool_Exp>;
};


export type Subscription_RootEmployee_Balance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Balance_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Balance_Bool_Exp>;
};


export type Subscription_RootEmployee_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmployee_Monthly_Billing_ReportArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Billing_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Billing_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Billing_Report_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Billing_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Billing_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Billing_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Billing_Report_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Billing_Report_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Monthly_Billing_Report_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Monthly_Billing_Report_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Hours_ReportArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Hours_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Hours_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Hours_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Hours_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Hours_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Hours_Report_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Monthly_Hours_Report_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Monthly_Hours_Report_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Payroll_ReportArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Payroll_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Payroll_Report_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Payroll_Report_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Monthly_Payroll_Report_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Monthly_Payroll_Report_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Predicted_PayrollArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Predicted_Payroll_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Monthly_Predicted_Payroll_Order_By>>;
  where?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
};


export type Subscription_RootEmployee_Monthly_Predicted_Payroll_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Monthly_Predicted_Payroll_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Monthly_Predicted_Payroll_Bool_Exp>;
};


export type Subscription_RootEmployee_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Bool_Exp>;
};


export type Subscription_RootPaid_HolidaysArgs = {
  distinct_on?: InputMaybe<Array<Paid_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Paid_Holidays_Order_By>>;
  where?: InputMaybe<Paid_Holidays_Bool_Exp>;
};


export type Subscription_RootPaid_Holidays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Paid_Holidays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Paid_Holidays_Order_By>>;
  where?: InputMaybe<Paid_Holidays_Bool_Exp>;
};


export type Subscription_RootPaid_Holidays_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPaid_Holidays_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Paid_Holidays_Stream_Cursor_Input>>;
  where?: InputMaybe<Paid_Holidays_Bool_Exp>;
};


export type Subscription_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProjects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};


export type Subscription_RootSalary_CoeffsArgs = {
  distinct_on?: InputMaybe<Array<Salary_Coeffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Coeffs_Order_By>>;
  where?: InputMaybe<Salary_Coeffs_Bool_Exp>;
};


export type Subscription_RootSalary_Coeffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Salary_Coeffs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Salary_Coeffs_Order_By>>;
  where?: InputMaybe<Salary_Coeffs_Bool_Exp>;
};


export type Subscription_RootSalary_Coeffs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSalary_Coeffs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Salary_Coeffs_Stream_Cursor_Input>>;
  where?: InputMaybe<Salary_Coeffs_Bool_Exp>;
};


export type Subscription_RootTime_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Time_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Entries_Order_By>>;
  where?: InputMaybe<Time_Entries_Bool_Exp>;
};


export type Subscription_RootTime_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Time_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Time_Entries_Order_By>>;
  where?: InputMaybe<Time_Entries_Bool_Exp>;
};


export type Subscription_RootTime_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTime_Entries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Time_Entries_Stream_Cursor_Input>>;
  where?: InputMaybe<Time_Entries_Bool_Exp>;
};

/** columns and relationships of "time_entries" */
export type Time_Entries = {
  __typename?: 'time_entries';
  /** An object relationship */
  assignment?: Maybe<Assignments>;
  assignment_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day: Scalars['date'];
  hours: Scalars['numeric'];
  id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "time_entries" */
export type Time_Entries_Aggregate = {
  __typename?: 'time_entries_aggregate';
  aggregate?: Maybe<Time_Entries_Aggregate_Fields>;
  nodes: Array<Time_Entries>;
};

export type Time_Entries_Aggregate_Bool_Exp = {
  count?: InputMaybe<Time_Entries_Aggregate_Bool_Exp_Count>;
};

export type Time_Entries_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Time_Entries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Time_Entries_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "time_entries" */
export type Time_Entries_Aggregate_Fields = {
  __typename?: 'time_entries_aggregate_fields';
  avg?: Maybe<Time_Entries_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Time_Entries_Max_Fields>;
  min?: Maybe<Time_Entries_Min_Fields>;
  stddev?: Maybe<Time_Entries_Stddev_Fields>;
  stddev_pop?: Maybe<Time_Entries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Time_Entries_Stddev_Samp_Fields>;
  sum?: Maybe<Time_Entries_Sum_Fields>;
  var_pop?: Maybe<Time_Entries_Var_Pop_Fields>;
  var_samp?: Maybe<Time_Entries_Var_Samp_Fields>;
  variance?: Maybe<Time_Entries_Variance_Fields>;
};


/** aggregate fields of "time_entries" */
export type Time_Entries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Time_Entries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "time_entries" */
export type Time_Entries_Aggregate_Order_By = {
  avg?: InputMaybe<Time_Entries_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Time_Entries_Max_Order_By>;
  min?: InputMaybe<Time_Entries_Min_Order_By>;
  stddev?: InputMaybe<Time_Entries_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Time_Entries_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Time_Entries_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Time_Entries_Sum_Order_By>;
  var_pop?: InputMaybe<Time_Entries_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Time_Entries_Var_Samp_Order_By>;
  variance?: InputMaybe<Time_Entries_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "time_entries" */
export type Time_Entries_Arr_Rel_Insert_Input = {
  data: Array<Time_Entries_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Time_Entries_On_Conflict>;
};

/** aggregate avg on columns */
export type Time_Entries_Avg_Fields = {
  __typename?: 'time_entries_avg_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "time_entries" */
export type Time_Entries_Avg_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "time_entries". All fields are combined with a logical 'AND'. */
export type Time_Entries_Bool_Exp = {
  _and?: InputMaybe<Array<Time_Entries_Bool_Exp>>;
  _not?: InputMaybe<Time_Entries_Bool_Exp>;
  _or?: InputMaybe<Array<Time_Entries_Bool_Exp>>;
  assignment?: InputMaybe<Assignments_Bool_Exp>;
  assignment_id?: InputMaybe<Uuid_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
  hours?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "time_entries" */
export enum Time_Entries_Constraint {
  /** unique or primary key constraint on columns "day", "assignment_id" */
  SingleEntryPerDayOnAssignment = 'single_entry_per_day_on_assignment',
  /** unique or primary key constraint on columns "id" */
  TimeEntriesPkey = 'time_entries_pkey'
}

/** input type for incrementing numeric columns in table "time_entries" */
export type Time_Entries_Inc_Input = {
  hours?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "time_entries" */
export type Time_Entries_Insert_Input = {
  assignment?: InputMaybe<Assignments_Obj_Rel_Insert_Input>;
  assignment_id?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  hours?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Time_Entries_Max_Fields = {
  __typename?: 'time_entries_max_fields';
  assignment_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  hours?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "time_entries" */
export type Time_Entries_Max_Order_By = {
  assignment_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  hours?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Time_Entries_Min_Fields = {
  __typename?: 'time_entries_min_fields';
  assignment_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  day?: Maybe<Scalars['date']>;
  hours?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "time_entries" */
export type Time_Entries_Min_Order_By = {
  assignment_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  hours?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "time_entries" */
export type Time_Entries_Mutation_Response = {
  __typename?: 'time_entries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Time_Entries>;
};

/** on_conflict condition type for table "time_entries" */
export type Time_Entries_On_Conflict = {
  constraint: Time_Entries_Constraint;
  update_columns?: Array<Time_Entries_Update_Column>;
  where?: InputMaybe<Time_Entries_Bool_Exp>;
};

/** Ordering options when selecting data from "time_entries". */
export type Time_Entries_Order_By = {
  assignment?: InputMaybe<Assignments_Order_By>;
  assignment_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
  hours?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: time_entries */
export type Time_Entries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "time_entries" */
export enum Time_Entries_Select_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  Hours = 'hours',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "time_entries" */
export type Time_Entries_Set_Input = {
  assignment_id?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  hours?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Time_Entries_Stddev_Fields = {
  __typename?: 'time_entries_stddev_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "time_entries" */
export type Time_Entries_Stddev_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Time_Entries_Stddev_Pop_Fields = {
  __typename?: 'time_entries_stddev_pop_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "time_entries" */
export type Time_Entries_Stddev_Pop_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Time_Entries_Stddev_Samp_Fields = {
  __typename?: 'time_entries_stddev_samp_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "time_entries" */
export type Time_Entries_Stddev_Samp_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "time_entries" */
export type Time_Entries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Time_Entries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Time_Entries_Stream_Cursor_Value_Input = {
  assignment_id?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  day?: InputMaybe<Scalars['date']>;
  hours?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Time_Entries_Sum_Fields = {
  __typename?: 'time_entries_sum_fields';
  hours?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "time_entries" */
export type Time_Entries_Sum_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** update columns of table "time_entries" */
export enum Time_Entries_Update_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Day = 'day',
  /** column name */
  Hours = 'hours',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Time_Entries_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Time_Entries_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Time_Entries_Set_Input>;
  where: Time_Entries_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Time_Entries_Var_Pop_Fields = {
  __typename?: 'time_entries_var_pop_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "time_entries" */
export type Time_Entries_Var_Pop_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Time_Entries_Var_Samp_Fields = {
  __typename?: 'time_entries_var_samp_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "time_entries" */
export type Time_Entries_Var_Samp_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Time_Entries_Variance_Fields = {
  __typename?: 'time_entries_variance_fields';
  hours?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "time_entries" */
export type Time_Entries_Variance_Order_By = {
  hours?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AddEmployeeMutationVariables = Exact<{
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['String']>;
}>;


export type AddEmployeeMutation = { __typename?: 'mutation_root', insert_employee?: { __typename?: 'employee_mutation_response', returning: Array<{ __typename?: 'employee', id: any }> } | null };

export type UpdateEmployeeMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Employee_Set_Input>;
}>;


export type UpdateEmployeeMutation = { __typename?: 'mutation_root', update_employee?: { __typename?: 'employee_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'employee', id: any }> } | null };

export type UpdateAssignmentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Assignments_Set_Input>;
}>;


export type UpdateAssignmentMutation = { __typename?: 'mutation_root', update_assignments?: { __typename?: 'assignments_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'assignments', id: any }> } | null };

export type AddPaidHolidayMutationVariables = Exact<{
  day?: InputMaybe<Scalars['date']>;
  comment?: InputMaybe<Scalars['String']>;
}>;


export type AddPaidHolidayMutation = { __typename?: 'mutation_root', insert_paid_holidays?: { __typename?: 'paid_holidays_mutation_response', returning: Array<{ __typename?: 'paid_holidays', id: any }> } | null };

export type AddAssignmentToProjectMutationVariables = Exact<{
  employee_id?: InputMaybe<Scalars['uuid']>;
  start_date?: InputMaybe<Scalars['date']>;
  end_date?: InputMaybe<Scalars['date']>;
  allocation?: InputMaybe<Scalars['numeric']>;
  external_price?: InputMaybe<Scalars['numeric']>;
  internal_price?: InputMaybe<Scalars['numeric']>;
  comment?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  po?: InputMaybe<Scalars['String']>;
}>;


export type AddAssignmentToProjectMutation = { __typename?: 'mutation_root', insert_assignments?: { __typename?: 'assignments_mutation_response', returning: Array<{ __typename?: 'assignments', id: any }> } | null };

export type DeleteAssignmentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type DeleteAssignmentMutation = { __typename?: 'mutation_root', delete_assignments?: { __typename?: 'assignments_mutation_response', returning: Array<{ __typename?: 'assignments', id: any }> } | null };

export type AddProjectMutationVariables = Exact<{
  customer_id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['date']>;
  end_date?: InputMaybe<Scalars['date']>;
  type?: InputMaybe<Scalars['String']>;
  po?: InputMaybe<Scalars['String']>;
}>;


export type AddProjectMutation = { __typename?: 'mutation_root', insert_projects?: { __typename?: 'projects_mutation_response', returning: Array<{ __typename?: 'projects', id: any }> } | null };

export type UpdateProjectMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Projects_Set_Input>;
}>;


export type UpdateProjectMutation = { __typename?: 'mutation_root', update_projects?: { __typename?: 'projects_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'projects', id: any }> } | null };

export type DeleteProjectMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type DeleteProjectMutation = { __typename?: 'mutation_root', delete_projects?: { __typename?: 'projects_mutation_response', returning: Array<{ __typename?: 'projects', id: any }> } | null };

export type AddCustomerMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  is_own?: InputMaybe<Scalars['Boolean']>;
  contact?: InputMaybe<Scalars['json']>;
}>;


export type AddCustomerMutation = { __typename?: 'mutation_root', insert_customers?: { __typename?: 'customers_mutation_response', returning: Array<{ __typename?: 'customers', id: any }> } | null };

export type UpdateCustomerMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Customers_Set_Input>;
}>;


export type UpdateCustomerMutation = { __typename?: 'mutation_root', update_customers?: { __typename?: 'customers_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'customers', id: any }> } | null };

export type AddEmployeeAllocationMutationVariables = Exact<{
  data: Array<Employee_Allocations_Insert_Input> | Employee_Allocations_Insert_Input;
}>;


export type AddEmployeeAllocationMutation = { __typename?: 'mutation_root', insert_employee_allocations?: { __typename?: 'employee_allocations_mutation_response', returning: Array<{ __typename?: 'employee_allocations', id: any }> } | null };

export type AddBalanceCorrectionMutationVariables = Exact<{
  data: Array<Balance_Corrections_Insert_Input> | Balance_Corrections_Insert_Input;
}>;


export type AddBalanceCorrectionMutation = { __typename?: 'mutation_root', insert_balance_corrections?: { __typename?: 'balance_corrections_mutation_response', returning: Array<{ __typename?: 'balance_corrections', id: any }> } | null };

export type DeleteEmployeeAllocationMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type DeleteEmployeeAllocationMutation = { __typename?: 'mutation_root', delete_employee_allocations?: { __typename?: 'employee_allocations_mutation_response', returning: Array<{ __typename?: 'employee_allocations', id: any }> } | null };

export type DeleteBalanceCorrectionMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type DeleteBalanceCorrectionMutation = { __typename?: 'mutation_root', delete_balance_corrections?: { __typename?: 'balance_corrections_mutation_response', returning: Array<{ __typename?: 'balance_corrections', id: any }> } | null };

export type AddSalaryCoeffsMutationVariables = Exact<{
  data: Array<Salary_Coeffs_Insert_Input> | Salary_Coeffs_Insert_Input;
}>;


export type AddSalaryCoeffsMutation = { __typename?: 'mutation_root', insert_salary_coeffs?: { __typename?: 'salary_coeffs_mutation_response', returning: Array<{ __typename?: 'salary_coeffs', base_salary?: any | null, bonus_percentage?: any | null, company_side_cost_coef?: any | null, day_wage?: any | null, hourly_wage?: any | null, period?: any | null }> } | null };

export type CustomerDetailsQueryVariables = Exact<{
  where?: InputMaybe<Customers_Bool_Exp>;
}>;


export type CustomerDetailsQuery = { __typename?: 'query_root', customers: Array<{ __typename?: 'customers', id: any, name: string, business_id?: string | null, logo?: string | null, is_own: boolean, projects: Array<{ __typename?: 'projects', id: any, name?: string | null, po?: string | null, start_date?: any | null, end_date?: any | null }>, projects_aggregate: { __typename?: 'projects_aggregate', nodes: Array<{ __typename?: 'projects', id: any, assignments: Array<{ __typename?: 'assignments', external_price?: any | null, time_entries_aggregate: { __typename?: 'time_entries_aggregate', aggregate?: { __typename?: 'time_entries_aggregate_fields', sum?: { __typename?: 'time_entries_sum_fields', hours?: any | null } | null } | null } }> }> } }> };

export type CustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomersQuery = { __typename?: 'query_root', customers: Array<{ __typename?: 'customers', id: any, name: string, business_id?: string | null, logo?: string | null, is_own: boolean, projects: Array<{ __typename?: 'projects', id: any, name?: string | null, start_date?: any | null, end_date?: any | null, po?: string | null, assignments: Array<{ __typename?: 'assignments', id: any, employee?: { __typename?: 'employee', id: any, avatar?: string | null, last_name?: string | null, first_name?: string | null, metadata?: any | null } | null }> }> }> };

export type AssignmentDetailsQueryVariables = Exact<{
  where?: InputMaybe<Assignments_Bool_Exp>;
  time_entries_aggr_where?: InputMaybe<Time_Entries_Bool_Exp>;
}>;


export type AssignmentDetailsQuery = { __typename?: 'query_root', assignments: Array<{ __typename?: 'assignments', id: any, start_date?: any | null, end_date?: any | null, external_price?: any | null, internal_price?: any | null, allocation?: any | null, comment?: string | null, employee?: { __typename?: 'employee', id: any, last_name?: string | null, first_name?: string | null, avatar?: string | null, metadata?: any | null } | null, time_entries_aggregate: { __typename?: 'time_entries_aggregate', aggregate?: { __typename?: 'time_entries_aggregate_fields', sum?: { __typename?: 'time_entries_sum_fields', hours?: any | null } | null } | null } }> };

export type ProjectsQueryVariables = Exact<{
  where?: InputMaybe<Projects_Bool_Exp>;
}>;


export type ProjectsQuery = { __typename?: 'query_root', projects: Array<{ __typename?: 'projects', id: any, name?: string | null, start_date?: any | null, end_date?: any | null, po?: string | null, customer?: { __typename?: 'customers', id: any, name: string, logo?: string | null } | null, assignments: Array<{ __typename?: 'assignments', id: any, start_date?: any | null, end_date?: any | null, external_price?: any | null, internal_price?: any | null, po?: string | null, employee?: { __typename?: 'employee', id: any, last_name?: string | null, first_name?: string | null, avatar?: string | null, metadata?: any | null } | null }> }> };

export type TimeEntryCountQueryVariables = Exact<{
  where?: InputMaybe<Assignments_Bool_Exp>;
}>;


export type TimeEntryCountQuery = { __typename?: 'query_root', assignments: Array<{ __typename?: 'assignments', id: any, time_entries_aggregate: { __typename?: 'time_entries_aggregate', aggregate?: { __typename?: 'time_entries_aggregate_fields', count: number } | null } }> };

export type ProjectAggregatedTimeEntresQueryVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type ProjectAggregatedTimeEntresQuery = { __typename?: 'query_root', projects_aggregate: { __typename?: 'projects_aggregate', nodes: Array<{ __typename?: 'projects', assignments_aggregate: { __typename?: 'assignments_aggregate', nodes: Array<{ __typename?: 'assignments', time_entries_aggregate: { __typename?: 'time_entries_aggregate', aggregate?: { __typename?: 'time_entries_aggregate_fields', sum?: { __typename?: 'time_entries_sum_fields', hours?: any | null } | null } | null } }> } }> } };

export type EmployeesQueryVariables = Exact<{
  where?: InputMaybe<Employee_Bool_Exp>;
}>;


export type EmployeesQuery = { __typename?: 'query_root', employee: Array<{ __typename?: 'employee', id: any, email?: string | null, first_name?: string | null, last_name?: string | null, avatar?: string | null, employment_start?: any | null, metadata?: any | null, employee_allocations: Array<{ __typename?: 'employee_allocations', id: any, start_date: any, allocation: any, metadata?: any | null, comment?: string | null }> }> };

export type EmployeesWithAssignmentsQueryVariables = Exact<{
  where?: InputMaybe<Employee_Bool_Exp>;
}>;


export type EmployeesWithAssignmentsQuery = { __typename?: 'query_root', employee: Array<{ __typename?: 'employee', id: any, email?: string | null, first_name?: string | null, last_name?: string | null, avatar?: string | null, employment_start?: any | null, metadata?: any | null, balance?: { __typename?: 'employee_balance', rolling_balance?: any | null } | null, employee_allocations: Array<{ __typename?: 'employee_allocations', id: any, start_date: any, allocation: any, metadata?: any | null, comment?: string | null }>, assignments: Array<{ __typename?: 'assignments', id: any, name?: string | null, project?: { __typename?: 'projects', name?: string | null, customer?: { __typename?: 'customers', id: any, logo?: string | null, name: string } | null } | null }> }> };

export type EmployeesDetailsQueryVariables = Exact<{
  where?: InputMaybe<Employee_Bool_Exp>;
}>;


export type EmployeesDetailsQuery = { __typename?: 'query_root', employee: Array<{ __typename?: 'employee', id: any, email?: string | null, first_name?: string | null, last_name?: string | null, employment_start?: any | null, metadata?: any | null, balance_corrections: Array<{ __typename?: 'balance_corrections', id: any, hours: any, day: any, comment?: string | null }>, employee_allocations: Array<{ __typename?: 'employee_allocations', id: any, start_date: any, allocation: any, metadata?: any | null, comment?: string | null }> }> };

export type CompanyMonthlyBillingByProjectAndEmployeeQueryVariables = Exact<{
  report_month?: InputMaybe<Scalars['date']>;
}>;


export type CompanyMonthlyBillingByProjectAndEmployeeQuery = { __typename?: 'query_root', company_monthly_billing_report: Array<{ __typename?: 'company_monthly_billing_report', sales_comission?: any | null, employee_comission?: any | null, billing?: any | null, report_month?: any | null, working_days?: any | null, hours?: any | null, employee?: { __typename?: 'employee', id: any, last_name?: string | null, first_name?: string | null, metadata?: any | null } | null, assignment?: { __typename?: 'assignments', po?: string | null, external_price?: any | null } | null, project?: { __typename?: 'projects', name?: string | null, po?: string | null, customer?: { __typename?: 'customers', name: string, business_id?: string | null } | null, sales?: { __typename?: 'employee', last_name?: string | null, first_name?: string | null } | null } | null }> };

export type EmployeeTimeEntriesQueryVariables = Exact<{
  start?: InputMaybe<Scalars['date']>;
  end?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type EmployeeTimeEntriesQuery = { __typename?: 'query_root', time_entries: Array<{ __typename?: 'time_entries', hours: any, day: any, comment?: string | null, assignment?: { __typename?: 'assignments', name?: string | null, id: any, external_price?: any | null, internal_price?: any | null, project?: { __typename?: 'projects', id: any, name?: string | null, type?: string | null, customer?: { __typename?: 'customers', name: string, business_id?: string | null, logo?: string | null } | null } | null } | null }> };

export type PayrollSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type PayrollSettingsQuery = { __typename?: 'query_root', salary_coeffs: Array<{ __typename?: 'salary_coeffs', base_salary?: any | null, bonus_percentage?: any | null, company_side_cost_coef?: any | null, day_wage?: any | null, hourly_wage?: any | null, period?: any | null }> };

export type MonthlyHoursReportQueryVariables = Exact<{
  report_month?: InputMaybe<Scalars['date']>;
}>;


export type MonthlyHoursReportQuery = { __typename?: 'query_root', employee: Array<{ __typename?: 'employee', first_name?: string | null, id: any, last_name?: string | null, avatar?: string | null, employment_start?: any | null, metadata?: any | null, employee_allocations: Array<{ __typename?: 'employee_allocations', start_date: any, allocation: any, metadata?: any | null }>, monthly_hours_report: Array<{ __typename?: 'employee_monthly_hours_report', billable_hours?: any | null, report_month?: any | null, company_hours?: any | null, holiday_hours?: any | null, sickleave_hours?: any | null, unpaid_hours?: any | null }> }>, employee_monthly_billing_report_aggregate: { __typename?: 'employee_monthly_billing_report_aggregate', aggregate?: { __typename?: 'employee_monthly_billing_report_aggregate_fields', sum?: { __typename?: 'employee_monthly_billing_report_sum_fields', external_billing?: any | null } | null } | null } };

export type MonthlyPayrollReportQueryVariables = Exact<{
  report_month?: InputMaybe<Scalars['date']>;
}>;


export type MonthlyPayrollReportQuery = { __typename?: 'query_root', employee: Array<{ __typename?: 'employee', first_name?: string | null, id: any, last_name?: string | null, avatar?: string | null, employment_start?: any | null, metadata?: any | null, employee_allocations: Array<{ __typename?: 'employee_allocations', start_date: any, allocation: any, metadata?: any | null }>, payroll_reports: Array<{ __typename?: 'employee_monthly_payroll_report', balance_change?: any | null, base_salary?: any | null, billing_for_bonus?: any | null, calculated_salary_base?: any | null, external_billing?: any | null, internal_billing?: any | null, report_month?: any | null, salary_bonus_part?: any | null }>, monthly_hours_report: Array<{ __typename?: 'employee_monthly_hours_report', billable_hours?: any | null, report_month?: any | null, company_hours?: any | null, holiday_hours?: any | null, sickleave_hours?: any | null, unpaid_hours?: any | null, working_days?: any | null, worked_days?: any | null }> }> };

export type PaidHolidaysQueryVariables = Exact<{ [key: string]: never; }>;


export type PaidHolidaysQuery = { __typename?: 'query_root', paid_holidays: Array<{ __typename?: 'paid_holidays', id: any, day: any, comment?: string | null }> };

export type CalendarQueryVariables = Exact<{
  from?: InputMaybe<Scalars['date']>;
  to?: InputMaybe<Scalars['date']>;
}>;


export type CalendarQuery = { __typename?: 'query_root', calendar: Array<{ __typename?: 'calendar_tmp_table', day?: any | null, paid_holiday?: number | null, weekday?: number | null }> };

export type AssignmentTimeEntryCountQueryVariables = Exact<{
  assignment_ids?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type AssignmentTimeEntryCountQuery = { __typename?: 'query_root', assignments: Array<{ __typename?: 'assignments', id: any, time_entries_aggregate: { __typename?: 'time_entries_aggregate', aggregate?: { __typename?: 'time_entries_aggregate_fields', count: number } | null } }> };

export type CustomerDetailedBillingReportQueryVariables = Exact<{
  customer_id?: InputMaybe<Scalars['uuid']>;
}>;


export type CustomerDetailedBillingReportQuery = { __typename?: 'query_root', assignments: Array<{ __typename?: 'assignments', id: any, external_price?: any | null, employee?: { __typename?: 'employee', id: any, first_name?: string | null, avatar?: string | null, last_name?: string | null, metadata?: any | null } | null, project?: { __typename?: 'projects', id: any, name?: string | null, start_date?: any | null, end_date?: any | null } | null, time_entries: Array<{ __typename?: 'time_entries', id: any, day: any, hours: any }> }> };


export const AddEmployeeDocument = gql`
    mutation AddEmployee($first_name: String, $last_name: String, $email: String, $avatar: String) {
  insert_employee(
    objects: {first_name: $first_name, last_name: $last_name, email: $email, avatar: $avatar}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddEmployeeMutationFn = Apollo.MutationFunction<AddEmployeeMutation, AddEmployeeMutationVariables>;

/**
 * __useAddEmployeeMutation__
 *
 * To run a mutation, you first call `useAddEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmployeeMutation, { data, loading, error }] = useAddEmployeeMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useAddEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<AddEmployeeMutation, AddEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmployeeMutation, AddEmployeeMutationVariables>(AddEmployeeDocument, options);
      }
export type AddEmployeeMutationHookResult = ReturnType<typeof useAddEmployeeMutation>;
export type AddEmployeeMutationResult = Apollo.MutationResult<AddEmployeeMutation>;
export type AddEmployeeMutationOptions = Apollo.BaseMutationOptions<AddEmployeeMutation, AddEmployeeMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($id: uuid, $data: employee_set_input) {
  update_employee(where: {id: {_eq: $id}}, _set: $data) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const UpdateAssignmentDocument = gql`
    mutation UpdateAssignment($id: uuid, $data: assignments_set_input) {
  update_assignments(where: {id: {_eq: $id}}, _set: $data) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type UpdateAssignmentMutationFn = Apollo.MutationFunction<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(UpdateAssignmentDocument, options);
      }
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = Apollo.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;
export const AddPaidHolidayDocument = gql`
    mutation AddPaidHoliday($day: date, $comment: String) {
  insert_paid_holidays(objects: {day: $day, comment: $comment}) {
    returning {
      id
    }
  }
}
    `;
export type AddPaidHolidayMutationFn = Apollo.MutationFunction<AddPaidHolidayMutation, AddPaidHolidayMutationVariables>;

/**
 * __useAddPaidHolidayMutation__
 *
 * To run a mutation, you first call `useAddPaidHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPaidHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPaidHolidayMutation, { data, loading, error }] = useAddPaidHolidayMutation({
 *   variables: {
 *      day: // value for 'day'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddPaidHolidayMutation(baseOptions?: Apollo.MutationHookOptions<AddPaidHolidayMutation, AddPaidHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPaidHolidayMutation, AddPaidHolidayMutationVariables>(AddPaidHolidayDocument, options);
      }
export type AddPaidHolidayMutationHookResult = ReturnType<typeof useAddPaidHolidayMutation>;
export type AddPaidHolidayMutationResult = Apollo.MutationResult<AddPaidHolidayMutation>;
export type AddPaidHolidayMutationOptions = Apollo.BaseMutationOptions<AddPaidHolidayMutation, AddPaidHolidayMutationVariables>;
export const AddAssignmentToProjectDocument = gql`
    mutation AddAssignmentToProject($employee_id: uuid, $start_date: date, $end_date: date, $allocation: numeric, $external_price: numeric, $internal_price: numeric, $comment: String, $project_id: uuid, $po: String) {
  insert_assignments(
    objects: {employee_id: $employee_id, project_id: $project_id, start_date: $start_date, end_date: $end_date, allocation: $allocation, external_price: $external_price, internal_price: $internal_price, comment: $comment, po: $po}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddAssignmentToProjectMutationFn = Apollo.MutationFunction<AddAssignmentToProjectMutation, AddAssignmentToProjectMutationVariables>;

/**
 * __useAddAssignmentToProjectMutation__
 *
 * To run a mutation, you first call `useAddAssignmentToProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssignmentToProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssignmentToProjectMutation, { data, loading, error }] = useAddAssignmentToProjectMutation({
 *   variables: {
 *      employee_id: // value for 'employee_id'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      allocation: // value for 'allocation'
 *      external_price: // value for 'external_price'
 *      internal_price: // value for 'internal_price'
 *      comment: // value for 'comment'
 *      project_id: // value for 'project_id'
 *      po: // value for 'po'
 *   },
 * });
 */
export function useAddAssignmentToProjectMutation(baseOptions?: Apollo.MutationHookOptions<AddAssignmentToProjectMutation, AddAssignmentToProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAssignmentToProjectMutation, AddAssignmentToProjectMutationVariables>(AddAssignmentToProjectDocument, options);
      }
export type AddAssignmentToProjectMutationHookResult = ReturnType<typeof useAddAssignmentToProjectMutation>;
export type AddAssignmentToProjectMutationResult = Apollo.MutationResult<AddAssignmentToProjectMutation>;
export type AddAssignmentToProjectMutationOptions = Apollo.BaseMutationOptions<AddAssignmentToProjectMutation, AddAssignmentToProjectMutationVariables>;
export const DeleteAssignmentDocument = gql`
    mutation DeleteAssignment($id: uuid) {
  delete_assignments(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteAssignmentMutationFn = Apollo.MutationFunction<DeleteAssignmentMutation, DeleteAssignmentMutationVariables>;

/**
 * __useDeleteAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignmentMutation, { data, loading, error }] = useDeleteAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssignmentMutation, DeleteAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssignmentMutation, DeleteAssignmentMutationVariables>(DeleteAssignmentDocument, options);
      }
export type DeleteAssignmentMutationHookResult = ReturnType<typeof useDeleteAssignmentMutation>;
export type DeleteAssignmentMutationResult = Apollo.MutationResult<DeleteAssignmentMutation>;
export type DeleteAssignmentMutationOptions = Apollo.BaseMutationOptions<DeleteAssignmentMutation, DeleteAssignmentMutationVariables>;
export const AddProjectDocument = gql`
    mutation AddProject($customer_id: uuid, $name: String, $start_date: date, $end_date: date, $type: String, $po: String) {
  insert_projects(
    objects: {customer_id: $customer_id, name: $name, start_date: $start_date, end_date: $end_date, type: $type, po: $po}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddProjectMutationFn = Apollo.MutationFunction<AddProjectMutation, AddProjectMutationVariables>;

/**
 * __useAddProjectMutation__
 *
 * To run a mutation, you first call `useAddProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectMutation, { data, loading, error }] = useAddProjectMutation({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *      name: // value for 'name'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      type: // value for 'type'
 *      po: // value for 'po'
 *   },
 * });
 */
export function useAddProjectMutation(baseOptions?: Apollo.MutationHookOptions<AddProjectMutation, AddProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProjectMutation, AddProjectMutationVariables>(AddProjectDocument, options);
      }
export type AddProjectMutationHookResult = ReturnType<typeof useAddProjectMutation>;
export type AddProjectMutationResult = Apollo.MutationResult<AddProjectMutation>;
export type AddProjectMutationOptions = Apollo.BaseMutationOptions<AddProjectMutation, AddProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($id: uuid, $data: projects_set_input) {
  update_projects(where: {id: {_eq: $id}}, _set: $data) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($id: uuid) {
  delete_projects(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const AddCustomerDocument = gql`
    mutation AddCustomer($name: String, $logo: String, $is_own: Boolean, $contact: json) {
  insert_customers(
    objects: {name: $name, logo: $logo, is_own: $is_own, contact: $contact}
  ) {
    returning {
      id
    }
  }
}
    `;
export type AddCustomerMutationFn = Apollo.MutationFunction<AddCustomerMutation, AddCustomerMutationVariables>;

/**
 * __useAddCustomerMutation__
 *
 * To run a mutation, you first call `useAddCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerMutation, { data, loading, error }] = useAddCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      logo: // value for 'logo'
 *      is_own: // value for 'is_own'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useAddCustomerMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomerMutation, AddCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomerMutation, AddCustomerMutationVariables>(AddCustomerDocument, options);
      }
export type AddCustomerMutationHookResult = ReturnType<typeof useAddCustomerMutation>;
export type AddCustomerMutationResult = Apollo.MutationResult<AddCustomerMutation>;
export type AddCustomerMutationOptions = Apollo.BaseMutationOptions<AddCustomerMutation, AddCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($id: uuid, $data: customers_set_input) {
  update_customers(where: {id: {_eq: $id}}, _set: $data) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const AddEmployeeAllocationDocument = gql`
    mutation AddEmployeeAllocation($data: [employee_allocations_insert_input!]!) {
  insert_employee_allocations(objects: $data) {
    returning {
      id
    }
  }
}
    `;
export type AddEmployeeAllocationMutationFn = Apollo.MutationFunction<AddEmployeeAllocationMutation, AddEmployeeAllocationMutationVariables>;

/**
 * __useAddEmployeeAllocationMutation__
 *
 * To run a mutation, you first call `useAddEmployeeAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmployeeAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmployeeAllocationMutation, { data, loading, error }] = useAddEmployeeAllocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddEmployeeAllocationMutation(baseOptions?: Apollo.MutationHookOptions<AddEmployeeAllocationMutation, AddEmployeeAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmployeeAllocationMutation, AddEmployeeAllocationMutationVariables>(AddEmployeeAllocationDocument, options);
      }
export type AddEmployeeAllocationMutationHookResult = ReturnType<typeof useAddEmployeeAllocationMutation>;
export type AddEmployeeAllocationMutationResult = Apollo.MutationResult<AddEmployeeAllocationMutation>;
export type AddEmployeeAllocationMutationOptions = Apollo.BaseMutationOptions<AddEmployeeAllocationMutation, AddEmployeeAllocationMutationVariables>;
export const AddBalanceCorrectionDocument = gql`
    mutation AddBalanceCorrection($data: [balance_corrections_insert_input!]!) {
  insert_balance_corrections(objects: $data) {
    returning {
      id
    }
  }
}
    `;
export type AddBalanceCorrectionMutationFn = Apollo.MutationFunction<AddBalanceCorrectionMutation, AddBalanceCorrectionMutationVariables>;

/**
 * __useAddBalanceCorrectionMutation__
 *
 * To run a mutation, you first call `useAddBalanceCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBalanceCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBalanceCorrectionMutation, { data, loading, error }] = useAddBalanceCorrectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddBalanceCorrectionMutation(baseOptions?: Apollo.MutationHookOptions<AddBalanceCorrectionMutation, AddBalanceCorrectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBalanceCorrectionMutation, AddBalanceCorrectionMutationVariables>(AddBalanceCorrectionDocument, options);
      }
export type AddBalanceCorrectionMutationHookResult = ReturnType<typeof useAddBalanceCorrectionMutation>;
export type AddBalanceCorrectionMutationResult = Apollo.MutationResult<AddBalanceCorrectionMutation>;
export type AddBalanceCorrectionMutationOptions = Apollo.BaseMutationOptions<AddBalanceCorrectionMutation, AddBalanceCorrectionMutationVariables>;
export const DeleteEmployeeAllocationDocument = gql`
    mutation DeleteEmployeeAllocation($id: uuid) {
  delete_employee_allocations(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteEmployeeAllocationMutationFn = Apollo.MutationFunction<DeleteEmployeeAllocationMutation, DeleteEmployeeAllocationMutationVariables>;

/**
 * __useDeleteEmployeeAllocationMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeAllocationMutation, { data, loading, error }] = useDeleteEmployeeAllocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeeAllocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeAllocationMutation, DeleteEmployeeAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeAllocationMutation, DeleteEmployeeAllocationMutationVariables>(DeleteEmployeeAllocationDocument, options);
      }
export type DeleteEmployeeAllocationMutationHookResult = ReturnType<typeof useDeleteEmployeeAllocationMutation>;
export type DeleteEmployeeAllocationMutationResult = Apollo.MutationResult<DeleteEmployeeAllocationMutation>;
export type DeleteEmployeeAllocationMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeAllocationMutation, DeleteEmployeeAllocationMutationVariables>;
export const DeleteBalanceCorrectionDocument = gql`
    mutation DeleteBalanceCorrection($id: uuid) {
  delete_balance_corrections(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteBalanceCorrectionMutationFn = Apollo.MutationFunction<DeleteBalanceCorrectionMutation, DeleteBalanceCorrectionMutationVariables>;

/**
 * __useDeleteBalanceCorrectionMutation__
 *
 * To run a mutation, you first call `useDeleteBalanceCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBalanceCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBalanceCorrectionMutation, { data, loading, error }] = useDeleteBalanceCorrectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBalanceCorrectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBalanceCorrectionMutation, DeleteBalanceCorrectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBalanceCorrectionMutation, DeleteBalanceCorrectionMutationVariables>(DeleteBalanceCorrectionDocument, options);
      }
export type DeleteBalanceCorrectionMutationHookResult = ReturnType<typeof useDeleteBalanceCorrectionMutation>;
export type DeleteBalanceCorrectionMutationResult = Apollo.MutationResult<DeleteBalanceCorrectionMutation>;
export type DeleteBalanceCorrectionMutationOptions = Apollo.BaseMutationOptions<DeleteBalanceCorrectionMutation, DeleteBalanceCorrectionMutationVariables>;
export const AddSalaryCoeffsDocument = gql`
    mutation AddSalaryCoeffs($data: [salary_coeffs_insert_input!]!) {
  insert_salary_coeffs(objects: $data) {
    returning {
      base_salary
      bonus_percentage
      company_side_cost_coef
      day_wage
      hourly_wage
      period
    }
  }
}
    `;
export type AddSalaryCoeffsMutationFn = Apollo.MutationFunction<AddSalaryCoeffsMutation, AddSalaryCoeffsMutationVariables>;

/**
 * __useAddSalaryCoeffsMutation__
 *
 * To run a mutation, you first call `useAddSalaryCoeffsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSalaryCoeffsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSalaryCoeffsMutation, { data, loading, error }] = useAddSalaryCoeffsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddSalaryCoeffsMutation(baseOptions?: Apollo.MutationHookOptions<AddSalaryCoeffsMutation, AddSalaryCoeffsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSalaryCoeffsMutation, AddSalaryCoeffsMutationVariables>(AddSalaryCoeffsDocument, options);
      }
export type AddSalaryCoeffsMutationHookResult = ReturnType<typeof useAddSalaryCoeffsMutation>;
export type AddSalaryCoeffsMutationResult = Apollo.MutationResult<AddSalaryCoeffsMutation>;
export type AddSalaryCoeffsMutationOptions = Apollo.BaseMutationOptions<AddSalaryCoeffsMutation, AddSalaryCoeffsMutationVariables>;
export const CustomerDetailsDocument = gql`
    query CustomerDetails($where: customers_bool_exp) {
  customers(where: $where) {
    id
    name
    business_id
    logo
    is_own
    projects {
      id
      name
      po
      start_date
      end_date
    }
    projects_aggregate {
      nodes {
        id
        assignments {
          external_price
          time_entries_aggregate {
            aggregate {
              sum {
                hours
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCustomerDetailsQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomerDetailsQuery(baseOptions?: Apollo.QueryHookOptions<CustomerDetailsQuery, CustomerDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDetailsQuery, CustomerDetailsQueryVariables>(CustomerDetailsDocument, options);
      }
export function useCustomerDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDetailsQuery, CustomerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDetailsQuery, CustomerDetailsQueryVariables>(CustomerDetailsDocument, options);
        }
export type CustomerDetailsQueryHookResult = ReturnType<typeof useCustomerDetailsQuery>;
export type CustomerDetailsLazyQueryHookResult = ReturnType<typeof useCustomerDetailsLazyQuery>;
export type CustomerDetailsQueryResult = Apollo.QueryResult<CustomerDetailsQuery, CustomerDetailsQueryVariables>;
export const CustomersDocument = gql`
    query Customers {
  customers {
    id
    name
    business_id
    logo
    is_own
    projects {
      id
      name
      start_date
      end_date
      po
      assignments {
        id
        employee {
          id
          avatar
          last_name
          first_name
          metadata
        }
      }
    }
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const AssignmentDetailsDocument = gql`
    query AssignmentDetails($where: assignments_bool_exp, $time_entries_aggr_where: time_entries_bool_exp) {
  assignments(where: $where) {
    id
    start_date
    end_date
    external_price
    internal_price
    allocation
    comment
    employee {
      id
      last_name
      first_name
      avatar
      metadata
    }
    time_entries_aggregate(where: $time_entries_aggr_where) {
      aggregate {
        sum {
          hours
        }
      }
    }
  }
}
    `;

/**
 * __useAssignmentDetailsQuery__
 *
 * To run a query within a React component, call `useAssignmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      time_entries_aggr_where: // value for 'time_entries_aggr_where'
 *   },
 * });
 */
export function useAssignmentDetailsQuery(baseOptions?: Apollo.QueryHookOptions<AssignmentDetailsQuery, AssignmentDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignmentDetailsQuery, AssignmentDetailsQueryVariables>(AssignmentDetailsDocument, options);
      }
export function useAssignmentDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignmentDetailsQuery, AssignmentDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignmentDetailsQuery, AssignmentDetailsQueryVariables>(AssignmentDetailsDocument, options);
        }
export type AssignmentDetailsQueryHookResult = ReturnType<typeof useAssignmentDetailsQuery>;
export type AssignmentDetailsLazyQueryHookResult = ReturnType<typeof useAssignmentDetailsLazyQuery>;
export type AssignmentDetailsQueryResult = Apollo.QueryResult<AssignmentDetailsQuery, AssignmentDetailsQueryVariables>;
export const ProjectsDocument = gql`
    query Projects($where: projects_bool_exp) {
  projects(where: $where) {
    id
    name
    start_date
    end_date
    po
    customer {
      id
      name
      logo
    }
    assignments {
      id
      start_date
      end_date
      external_price
      internal_price
      po
      employee {
        id
        last_name
        first_name
        avatar
        metadata
      }
    }
  }
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const TimeEntryCountDocument = gql`
    query TimeEntryCount($where: assignments_bool_exp) {
  assignments(where: $where) {
    id
    time_entries_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useTimeEntryCountQuery__
 *
 * To run a query within a React component, call `useTimeEntryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeEntryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeEntryCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTimeEntryCountQuery(baseOptions?: Apollo.QueryHookOptions<TimeEntryCountQuery, TimeEntryCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeEntryCountQuery, TimeEntryCountQueryVariables>(TimeEntryCountDocument, options);
      }
export function useTimeEntryCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeEntryCountQuery, TimeEntryCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeEntryCountQuery, TimeEntryCountQueryVariables>(TimeEntryCountDocument, options);
        }
export type TimeEntryCountQueryHookResult = ReturnType<typeof useTimeEntryCountQuery>;
export type TimeEntryCountLazyQueryHookResult = ReturnType<typeof useTimeEntryCountLazyQuery>;
export type TimeEntryCountQueryResult = Apollo.QueryResult<TimeEntryCountQuery, TimeEntryCountQueryVariables>;
export const ProjectAggregatedTimeEntresDocument = gql`
    query ProjectAggregatedTimeEntres($id: uuid) {
  projects_aggregate(where: {id: {_eq: $id}}) {
    nodes {
      assignments_aggregate {
        nodes {
          time_entries_aggregate {
            aggregate {
              sum {
                hours
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProjectAggregatedTimeEntresQuery__
 *
 * To run a query within a React component, call `useProjectAggregatedTimeEntresQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAggregatedTimeEntresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAggregatedTimeEntresQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectAggregatedTimeEntresQuery(baseOptions?: Apollo.QueryHookOptions<ProjectAggregatedTimeEntresQuery, ProjectAggregatedTimeEntresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectAggregatedTimeEntresQuery, ProjectAggregatedTimeEntresQueryVariables>(ProjectAggregatedTimeEntresDocument, options);
      }
export function useProjectAggregatedTimeEntresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectAggregatedTimeEntresQuery, ProjectAggregatedTimeEntresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectAggregatedTimeEntresQuery, ProjectAggregatedTimeEntresQueryVariables>(ProjectAggregatedTimeEntresDocument, options);
        }
export type ProjectAggregatedTimeEntresQueryHookResult = ReturnType<typeof useProjectAggregatedTimeEntresQuery>;
export type ProjectAggregatedTimeEntresLazyQueryHookResult = ReturnType<typeof useProjectAggregatedTimeEntresLazyQuery>;
export type ProjectAggregatedTimeEntresQueryResult = Apollo.QueryResult<ProjectAggregatedTimeEntresQuery, ProjectAggregatedTimeEntresQueryVariables>;
export const EmployeesDocument = gql`
    query Employees($where: employee_bool_exp) {
  employee(where: $where) {
    id
    email
    first_name
    last_name
    avatar
    employment_start
    metadata
    employee_allocations {
      id
      start_date
      allocation
      metadata
      comment
    }
  }
}
    `;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
      }
export function useEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
        }
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>;
export type EmployeesQueryResult = Apollo.QueryResult<EmployeesQuery, EmployeesQueryVariables>;
export const EmployeesWithAssignmentsDocument = gql`
    query EmployeesWithAssignments($where: employee_bool_exp) {
  employee(where: $where) {
    id
    email
    first_name
    last_name
    avatar
    employment_start
    metadata
    balance {
      rolling_balance
    }
    employee_allocations {
      id
      start_date
      allocation
      metadata
      comment
    }
    assignments {
      id
      name
      project {
        name
        customer {
          id
          logo
          name
        }
      }
    }
  }
}
    `;

/**
 * __useEmployeesWithAssignmentsQuery__
 *
 * To run a query within a React component, call `useEmployeesWithAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesWithAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesWithAssignmentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEmployeesWithAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesWithAssignmentsQuery, EmployeesWithAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesWithAssignmentsQuery, EmployeesWithAssignmentsQueryVariables>(EmployeesWithAssignmentsDocument, options);
      }
export function useEmployeesWithAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesWithAssignmentsQuery, EmployeesWithAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesWithAssignmentsQuery, EmployeesWithAssignmentsQueryVariables>(EmployeesWithAssignmentsDocument, options);
        }
export type EmployeesWithAssignmentsQueryHookResult = ReturnType<typeof useEmployeesWithAssignmentsQuery>;
export type EmployeesWithAssignmentsLazyQueryHookResult = ReturnType<typeof useEmployeesWithAssignmentsLazyQuery>;
export type EmployeesWithAssignmentsQueryResult = Apollo.QueryResult<EmployeesWithAssignmentsQuery, EmployeesWithAssignmentsQueryVariables>;
export const EmployeesDetailsDocument = gql`
    query EmployeesDetails($where: employee_bool_exp) {
  employee(where: $where) {
    id
    email
    first_name
    last_name
    employment_start
    metadata
    balance_corrections(order_by: {day: desc}) {
      id
      hours
      day
      comment
    }
    employee_allocations(order_by: {start_date: desc}) {
      id
      start_date
      allocation
      metadata
      comment
    }
  }
}
    `;

/**
 * __useEmployeesDetailsQuery__
 *
 * To run a query within a React component, call `useEmployeesDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEmployeesDetailsQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesDetailsQuery, EmployeesDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesDetailsQuery, EmployeesDetailsQueryVariables>(EmployeesDetailsDocument, options);
      }
export function useEmployeesDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesDetailsQuery, EmployeesDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesDetailsQuery, EmployeesDetailsQueryVariables>(EmployeesDetailsDocument, options);
        }
export type EmployeesDetailsQueryHookResult = ReturnType<typeof useEmployeesDetailsQuery>;
export type EmployeesDetailsLazyQueryHookResult = ReturnType<typeof useEmployeesDetailsLazyQuery>;
export type EmployeesDetailsQueryResult = Apollo.QueryResult<EmployeesDetailsQuery, EmployeesDetailsQueryVariables>;
export const CompanyMonthlyBillingByProjectAndEmployeeDocument = gql`
    query CompanyMonthlyBillingByProjectAndEmployee($report_month: date) {
  company_monthly_billing_report(where: {report_month: {_eq: $report_month}}) {
    employee {
      id
      last_name
      first_name
      metadata
    }
    assignment {
      po
      external_price
    }
    project {
      customer {
        name
        business_id
      }
      name
      po
      sales {
        last_name
        first_name
      }
    }
    sales_comission
    employee_comission
    billing
    report_month
    working_days
    hours
  }
}
    `;

/**
 * __useCompanyMonthlyBillingByProjectAndEmployeeQuery__
 *
 * To run a query within a React component, call `useCompanyMonthlyBillingByProjectAndEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMonthlyBillingByProjectAndEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMonthlyBillingByProjectAndEmployeeQuery({
 *   variables: {
 *      report_month: // value for 'report_month'
 *   },
 * });
 */
export function useCompanyMonthlyBillingByProjectAndEmployeeQuery(baseOptions?: Apollo.QueryHookOptions<CompanyMonthlyBillingByProjectAndEmployeeQuery, CompanyMonthlyBillingByProjectAndEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyMonthlyBillingByProjectAndEmployeeQuery, CompanyMonthlyBillingByProjectAndEmployeeQueryVariables>(CompanyMonthlyBillingByProjectAndEmployeeDocument, options);
      }
export function useCompanyMonthlyBillingByProjectAndEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyMonthlyBillingByProjectAndEmployeeQuery, CompanyMonthlyBillingByProjectAndEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyMonthlyBillingByProjectAndEmployeeQuery, CompanyMonthlyBillingByProjectAndEmployeeQueryVariables>(CompanyMonthlyBillingByProjectAndEmployeeDocument, options);
        }
export type CompanyMonthlyBillingByProjectAndEmployeeQueryHookResult = ReturnType<typeof useCompanyMonthlyBillingByProjectAndEmployeeQuery>;
export type CompanyMonthlyBillingByProjectAndEmployeeLazyQueryHookResult = ReturnType<typeof useCompanyMonthlyBillingByProjectAndEmployeeLazyQuery>;
export type CompanyMonthlyBillingByProjectAndEmployeeQueryResult = Apollo.QueryResult<CompanyMonthlyBillingByProjectAndEmployeeQuery, CompanyMonthlyBillingByProjectAndEmployeeQueryVariables>;
export const EmployeeTimeEntriesDocument = gql`
    query EmployeeTimeEntries($start: date, $end: date, $id: uuid) {
  time_entries(
    where: {_and: {day: {_gte: $start, _lte: $end}}, assignment: {employee_id: {_eq: $id}}}
  ) {
    hours
    day
    comment
    assignment {
      name
      id
      external_price
      internal_price
      project {
        id
        name
        type
        customer {
          name
          business_id
          logo
        }
      }
    }
  }
}
    `;

/**
 * __useEmployeeTimeEntriesQuery__
 *
 * To run a query within a React component, call `useEmployeeTimeEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeTimeEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeTimeEntriesQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeTimeEntriesQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeTimeEntriesQuery, EmployeeTimeEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeTimeEntriesQuery, EmployeeTimeEntriesQueryVariables>(EmployeeTimeEntriesDocument, options);
      }
export function useEmployeeTimeEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeTimeEntriesQuery, EmployeeTimeEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeTimeEntriesQuery, EmployeeTimeEntriesQueryVariables>(EmployeeTimeEntriesDocument, options);
        }
export type EmployeeTimeEntriesQueryHookResult = ReturnType<typeof useEmployeeTimeEntriesQuery>;
export type EmployeeTimeEntriesLazyQueryHookResult = ReturnType<typeof useEmployeeTimeEntriesLazyQuery>;
export type EmployeeTimeEntriesQueryResult = Apollo.QueryResult<EmployeeTimeEntriesQuery, EmployeeTimeEntriesQueryVariables>;
export const PayrollSettingsDocument = gql`
    query PayrollSettings {
  salary_coeffs(order_by: {period: desc}) {
    base_salary
    bonus_percentage
    company_side_cost_coef
    day_wage
    hourly_wage
    period
  }
}
    `;

/**
 * __usePayrollSettingsQuery__
 *
 * To run a query within a React component, call `usePayrollSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayrollSettingsQuery(baseOptions?: Apollo.QueryHookOptions<PayrollSettingsQuery, PayrollSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayrollSettingsQuery, PayrollSettingsQueryVariables>(PayrollSettingsDocument, options);
      }
export function usePayrollSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayrollSettingsQuery, PayrollSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayrollSettingsQuery, PayrollSettingsQueryVariables>(PayrollSettingsDocument, options);
        }
export type PayrollSettingsQueryHookResult = ReturnType<typeof usePayrollSettingsQuery>;
export type PayrollSettingsLazyQueryHookResult = ReturnType<typeof usePayrollSettingsLazyQuery>;
export type PayrollSettingsQueryResult = Apollo.QueryResult<PayrollSettingsQuery, PayrollSettingsQueryVariables>;
export const MonthlyHoursReportDocument = gql`
    query MonthlyHoursReport($report_month: date) {
  employee {
    first_name
    id
    last_name
    avatar
    employment_start
    metadata
    employee_allocations {
      start_date
      allocation
      metadata
    }
    monthly_hours_report(where: {report_month: {_eq: $report_month}}) {
      billable_hours
      report_month
      company_hours
      holiday_hours
      sickleave_hours
      unpaid_hours
    }
  }
  employee_monthly_billing_report_aggregate(
    where: {report_month: {_eq: $report_month}}
  ) {
    aggregate {
      sum {
        external_billing
      }
    }
  }
}
    `;

/**
 * __useMonthlyHoursReportQuery__
 *
 * To run a query within a React component, call `useMonthlyHoursReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyHoursReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyHoursReportQuery({
 *   variables: {
 *      report_month: // value for 'report_month'
 *   },
 * });
 */
export function useMonthlyHoursReportQuery(baseOptions?: Apollo.QueryHookOptions<MonthlyHoursReportQuery, MonthlyHoursReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonthlyHoursReportQuery, MonthlyHoursReportQueryVariables>(MonthlyHoursReportDocument, options);
      }
export function useMonthlyHoursReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyHoursReportQuery, MonthlyHoursReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonthlyHoursReportQuery, MonthlyHoursReportQueryVariables>(MonthlyHoursReportDocument, options);
        }
export type MonthlyHoursReportQueryHookResult = ReturnType<typeof useMonthlyHoursReportQuery>;
export type MonthlyHoursReportLazyQueryHookResult = ReturnType<typeof useMonthlyHoursReportLazyQuery>;
export type MonthlyHoursReportQueryResult = Apollo.QueryResult<MonthlyHoursReportQuery, MonthlyHoursReportQueryVariables>;
export const MonthlyPayrollReportDocument = gql`
    query MonthlyPayrollReport($report_month: date) {
  employee {
    first_name
    id
    last_name
    avatar
    employment_start
    metadata
    employee_allocations {
      start_date
      allocation
      metadata
    }
    payroll_reports(where: {report_month: {_eq: $report_month}}) {
      balance_change
      base_salary
      billing_for_bonus
      calculated_salary_base
      external_billing
      internal_billing
      report_month
      salary_bonus_part
    }
    monthly_hours_report(where: {report_month: {_eq: $report_month}}) {
      billable_hours
      report_month
      company_hours
      holiday_hours
      sickleave_hours
      unpaid_hours
      working_days
      worked_days
    }
  }
}
    `;

/**
 * __useMonthlyPayrollReportQuery__
 *
 * To run a query within a React component, call `useMonthlyPayrollReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyPayrollReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyPayrollReportQuery({
 *   variables: {
 *      report_month: // value for 'report_month'
 *   },
 * });
 */
export function useMonthlyPayrollReportQuery(baseOptions?: Apollo.QueryHookOptions<MonthlyPayrollReportQuery, MonthlyPayrollReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonthlyPayrollReportQuery, MonthlyPayrollReportQueryVariables>(MonthlyPayrollReportDocument, options);
      }
export function useMonthlyPayrollReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonthlyPayrollReportQuery, MonthlyPayrollReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonthlyPayrollReportQuery, MonthlyPayrollReportQueryVariables>(MonthlyPayrollReportDocument, options);
        }
export type MonthlyPayrollReportQueryHookResult = ReturnType<typeof useMonthlyPayrollReportQuery>;
export type MonthlyPayrollReportLazyQueryHookResult = ReturnType<typeof useMonthlyPayrollReportLazyQuery>;
export type MonthlyPayrollReportQueryResult = Apollo.QueryResult<MonthlyPayrollReportQuery, MonthlyPayrollReportQueryVariables>;
export const PaidHolidaysDocument = gql`
    query PaidHolidays {
  paid_holidays(order_by: {day: desc}) {
    id
    day
    comment
  }
}
    `;

/**
 * __usePaidHolidaysQuery__
 *
 * To run a query within a React component, call `usePaidHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaidHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaidHolidaysQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaidHolidaysQuery(baseOptions?: Apollo.QueryHookOptions<PaidHolidaysQuery, PaidHolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaidHolidaysQuery, PaidHolidaysQueryVariables>(PaidHolidaysDocument, options);
      }
export function usePaidHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaidHolidaysQuery, PaidHolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaidHolidaysQuery, PaidHolidaysQueryVariables>(PaidHolidaysDocument, options);
        }
export type PaidHolidaysQueryHookResult = ReturnType<typeof usePaidHolidaysQuery>;
export type PaidHolidaysLazyQueryHookResult = ReturnType<typeof usePaidHolidaysLazyQuery>;
export type PaidHolidaysQueryResult = Apollo.QueryResult<PaidHolidaysQuery, PaidHolidaysQueryVariables>;
export const CalendarDocument = gql`
    query Calendar($from: date, $to: date) {
  calendar(args: {from_date: $from, to_date: $to}) {
    day
    paid_holiday
    weekday
  }
}
    `;

/**
 * __useCalendarQuery__
 *
 * To run a query within a React component, call `useCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useCalendarQuery(baseOptions?: Apollo.QueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, options);
      }
export function useCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarQuery, CalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarQuery, CalendarQueryVariables>(CalendarDocument, options);
        }
export type CalendarQueryHookResult = ReturnType<typeof useCalendarQuery>;
export type CalendarLazyQueryHookResult = ReturnType<typeof useCalendarLazyQuery>;
export type CalendarQueryResult = Apollo.QueryResult<CalendarQuery, CalendarQueryVariables>;
export const AssignmentTimeEntryCountDocument = gql`
    query AssignmentTimeEntryCount($assignment_ids: [uuid!]) {
  assignments(where: {id: {_in: $assignment_ids}}) {
    id
    time_entries_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useAssignmentTimeEntryCountQuery__
 *
 * To run a query within a React component, call `useAssignmentTimeEntryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentTimeEntryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentTimeEntryCountQuery({
 *   variables: {
 *      assignment_ids: // value for 'assignment_ids'
 *   },
 * });
 */
export function useAssignmentTimeEntryCountQuery(baseOptions?: Apollo.QueryHookOptions<AssignmentTimeEntryCountQuery, AssignmentTimeEntryCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssignmentTimeEntryCountQuery, AssignmentTimeEntryCountQueryVariables>(AssignmentTimeEntryCountDocument, options);
      }
export function useAssignmentTimeEntryCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssignmentTimeEntryCountQuery, AssignmentTimeEntryCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssignmentTimeEntryCountQuery, AssignmentTimeEntryCountQueryVariables>(AssignmentTimeEntryCountDocument, options);
        }
export type AssignmentTimeEntryCountQueryHookResult = ReturnType<typeof useAssignmentTimeEntryCountQuery>;
export type AssignmentTimeEntryCountLazyQueryHookResult = ReturnType<typeof useAssignmentTimeEntryCountLazyQuery>;
export type AssignmentTimeEntryCountQueryResult = Apollo.QueryResult<AssignmentTimeEntryCountQuery, AssignmentTimeEntryCountQueryVariables>;
export const CustomerDetailedBillingReportDocument = gql`
    query CustomerDetailedBillingReport($customer_id: uuid) {
  assignments(where: {project: {customer: {id: {_eq: $customer_id}}}}) {
    id
    external_price
    employee {
      id
      first_name
      avatar
      last_name
      metadata
    }
    project {
      id
      name
      start_date
      end_date
    }
    time_entries {
      id
      day
      hours
    }
  }
}
    `;

/**
 * __useCustomerDetailedBillingReportQuery__
 *
 * To run a query within a React component, call `useCustomerDetailedBillingReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailedBillingReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailedBillingReportQuery({
 *   variables: {
 *      customer_id: // value for 'customer_id'
 *   },
 * });
 */
export function useCustomerDetailedBillingReportQuery(baseOptions?: Apollo.QueryHookOptions<CustomerDetailedBillingReportQuery, CustomerDetailedBillingReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerDetailedBillingReportQuery, CustomerDetailedBillingReportQueryVariables>(CustomerDetailedBillingReportDocument, options);
      }
export function useCustomerDetailedBillingReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerDetailedBillingReportQuery, CustomerDetailedBillingReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerDetailedBillingReportQuery, CustomerDetailedBillingReportQueryVariables>(CustomerDetailedBillingReportDocument, options);
        }
export type CustomerDetailedBillingReportQueryHookResult = ReturnType<typeof useCustomerDetailedBillingReportQuery>;
export type CustomerDetailedBillingReportLazyQueryHookResult = ReturnType<typeof useCustomerDetailedBillingReportLazyQuery>;
export type CustomerDetailedBillingReportQueryResult = Apollo.QueryResult<CustomerDetailedBillingReportQuery, CustomerDetailedBillingReportQueryVariables>;
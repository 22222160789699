import { createColumnHelper } from '@tanstack/react-table';
import * as d3 from 'd3';
import { isSameDay, isWeekend, lastDayOfMonth, startOfMonth } from 'date-fns';
import { Tooltip } from 'flowbite-react';
import { useMemo } from 'react';
import { Employee } from '../../generated/graphql';
import { Avatar } from '../Avatars/Avatar';
import { NumberColumn, dateColumnGenerator } from '../Tables/ColumnTypes';
import { ExtendableTable } from '../Tables/ExtendableTable';

type ProjectMonthlyReportTableProps = {
  transposed: any[];
};

export const ProjectMonthlyReportTable = ({ transposed }: ProjectMonthlyReportTableProps) => {
  const columnHelper = createColumnHelper<Employee>();

  const byDay: any = d3.rollup(
    transposed,
    (v: any) => {
      return {
        billing: d3.sum(v, (d: any) => d.billing),
        hours: d3.sum(v, (d: any) => d.hours)
      };
    },
    (d: any) => Date.parse(d.day),
    (d: any) => d.employee_id
  );
  //console.log('byDay', byDay);
  const monthExtent = [startOfMonth(transposed[0].day), lastDayOfMonth(transposed[0].day)];

  const allDays: any[] = d3.timeDays(monthExtent[0], monthExtent[1]);
  const reportedDates: any[] = [...byDay.keys()];

  allDays.forEach((d: Date) => {
    const found = reportedDates.find((rd) => isSameDay(rd, d));
    if (found === undefined) {
      byDay.set(d.getTime(), new Map());
    }
  });

  const columns = [
    columnHelper.accessor((row: any) => row[0], {
      id: 'day',
      ...dateColumnGenerator('Day')
    })
  ];

  const employees: any[] = useMemo(
    () => [
      ...d3.group(transposed, (d: any) => {
        return d.employee_id;
      })
    ],
    [transposed]
  );

  employees.forEach((employee: any) => {
    columns.push({
      id: employee[0],
      header: () => (
        <div className='flex justify-end w-full'>
          <Tooltip className='bg-royal-dark-blue border-white' content={employee[1][0].employee}>
            <Avatar data-tooltip-target='tooltip-animation' size={8} url={employee[1][0].avatar} />
          </Tooltip>
        </div>
      ),
      cell: (item: any) => {
        const d = new Date(item.row.original[0]);
        const stl = isWeekend(d) ? 'text-gray-400 text-end' : 'text-end';
        return (
          <NumberColumn
            className={stl}
            value={item.row.original[1].get(employee[0])?.hours || '-'}
            unit={'h'}
          />
        );
      },
      footer: (props: any) => {
        let val = props.table.getFilteredRowModel().rows.reduce((total: number, row: any) => {
          return total + (row.original[1].get(employee[0])?.hours || 0);
        }, 0);

        return <div className=' text-end'>{val} h</div>;
      },
      size: 40
    });
  });

  return (
    <div className='mt-40'>
      <ExtendableTable
        columns={columns}
        data={[...byDay]}
        title='Employee summary by day'
        hasFooter={true}
        initialSort={{
          id: 'day',
          desc: false
        }}
      />
    </div>
  );
};

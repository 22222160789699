import * as d3 from 'd3';

import { isBefore, isSameMonth } from 'date-fns';
import { useContext, useState } from 'react';
import { StatsCard } from '../../components/Cards/StatsCard';
import { Content, PanelTab } from '../../components/Tabs/Panels';
import Toggle from '../../components/Toggle/Toggle';
import { Customers, useCustomerDetailedBillingReportQuery } from '../../generated/graphql';
import { MonthContext } from '../../providers/MonthContext';
import { formatCurrency } from '../../utils/formatters';
import { Chart } from './Chart';

type CustomerReportProps = {
  customer: Customers;
};
export const CustomerReports = ({ customer }: CustomerReportProps) => {
  const { selectedMonth } = useContext(MonthContext);
  const [fullHistory, setFullHistory] = useState<boolean>(false);
  const { data } = useCustomerDetailedBillingReportQuery({
    variables: {
      customer_id: customer.id
    }
  });

  console.log(data);
  const transposed =
    data?.assignments
      .reduce((prev: any, current: any) => {
        return [
          ...prev,
          ...current.time_entries.map((e: any) => {
            return {
              day: d3.utcParse('%Y-%m-%d')(e.day),
              project: current.project.name,
              price: current.external_price,
              hours: e.hours as number,
              billing: e.hours * current.external_price,
              employee: `${current.employee.last_name} ${current.employee.first_name}`
            };
          })
        ];
      }, [])
      .sort((a: any, b: any) => (isBefore(a.day, b.day) ? -1 : 1))
      .filter((a: any) => fullHistory || isSameMonth(a.day, selectedMonth)) || [];

  const historyToggle = () => {
    return (
      <Toggle
        onChange={(e) => setFullHistory(e.selected)}
        value={{ selected: fullHistory, id: 'asd', label: 'Show full history' }}
      />
    );
  };

  const totalBilling = d3.sum(transposed, (d: any) => d.billing);
  const totalHours = d3.sum(transposed, (d: any) => d.hours);
  const peeps = [...d3.group(transposed, (d) => d.employee).keys()].length;
  return (
    <>
      <PanelTab action={historyToggle()}>Reports</PanelTab>
      <Content>
        <div className='grid grid-cols-3 gap-8 mb-12 mt-12'>
          <StatsCard label='Billing' value={`${formatCurrency(totalBilling)}`} />
          <StatsCard label='Effort' value={`${totalHours} h`} />
          <StatsCard label='People' value={`${peeps}`} />
        </div>
        <Chart transposed={transposed} />
      </Content>
    </>
  );
};

import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { isWeekend } from 'date-fns';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Datepicker, { DateRangeType } from 'react-tailwindcss-datepicker';
import { Employee } from '../../generated/graphql';
import { employeeBasicInfoOk, employeeFullname } from '../../models/EmployeeTypes';
import { customerLogoUrl } from '../../models/ProjectTypes';
import { formatCurrency, formatDate, toDecimalString } from '../../utils/formatters';
import { Avatar } from '../Avatars/Avatar';
import { EmployeeAvatar } from '../Avatars/EmployeeAvatar';

type PercentageColumnProps = {
  value: number;
};
export const PercentageColumn = ({ value }: PercentageColumnProps) => {
  return <div className='text-end'>{toDecimalString(value, 2)} %</div>;
};

export const employeeAvatarFullnameGenerator = () => {
  return {
    cell: (value: any) => {
      const notification = !employeeBasicInfoOk(value.getValue());
      const employee = value.getValue();
      return (
        <Link to={`/people/${employee.id}`} className='flex items-center'>
          <EmployeeAvatar size={8} employee={employee} notification={notification} />
          <span className='p-2 ml-4'>{employeeFullname(employee)}</span>
        </Link>
      );
    },
    header: () => <div className='text-start'>Employee</div>,
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      const e1 = rowA.getValue(columnId) as Employee;
      const e2 = rowB.getValue(columnId) as Employee;
      return employeeFullname(e1).localeCompare(employeeFullname(e2), 'FI');
    },
    enableSorting: true
  };
};

export const assignmentProjectNameAvatarGenerator = () => {
  return {
    cell: (value: any) => {
      const ass = value.getValue();
      return (
        <div className='flex'>
          <Avatar size={8} url={customerLogoUrl(ass?.project?.customer!)} />
          <span className='ml-4 self-center'>
            {ass?.project?.name} ({ass?.project?.customer?.name})
          </span>
        </div>
      );
    },

    header: () => <div className='text-start'>Project</div>
  };
};

type NumberColumnProps = {
  value: any;
  unit?: any;
  className?: string;
};

export const NumberColumn = ({ value, unit, className }: NumberColumnProps) => {
  return (
    <div className={className ? className : 'text-end'}>
      {value || 0} {unit}
    </div>
  );
};
type CurrencyEditColumnProps = {
  value: number;
  context: any;
  onCommit: (value: number, context: any, key: keyof Object) => Promise<number>;
  valueKey: keyof Object;
};

const Progress = () => (
  <svg
    aria-hidden='true'
    className=' w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600'
    viewBox='0 0 100 101'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
      fill='currentColor'
    />
    <path
      d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
      fill='currentFill'
    />
  </svg>
);

type DateEditColumnProps = {
  value: Date;
  context: any;
  onCommit: (value: Date, context: any, key: keyof Object) => Promise<Date>;
  valueKey: keyof Object;
};

export const DateEditColumn = ({ value, context, onCommit, valueKey }: DateEditColumnProps) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<DateRangeType>({ startDate: value, endDate: value });

  const commit = (date: Date) => {
    setInProgress(true);
    onCommit(date, context, valueKey)
      .then((v: Date) => {
        setInputValue({ startDate: v, endDate: v });
      })
      .catch((e) => console.error('Could not update assignment', e))
      .finally(() => {
        setInProgress(false);
      });
  };

  const inputClass =
    'flex items-center w-[130px] justify-end h-5 border-none bg-transparent focus:outline-none focus:ring-0 caret-transparent text-sm';
  return (
    <div className='flex text-sm items-center justify-between w-[108px]'>
      <Datepicker
        placeholder={formatDate(inputValue.startDate!)}
        toggleIcon={(open: boolean) => {
          if (inProgress) {
            return (
              <div className='w-4 h-4'>
                <Progress />
              </div>
            );
          }
          return (
            <PencilSquareIcon className='w-4 h-4 text-gray-300 group-hover:text-royal-dark-blue' />
          );
        }}
        toggleClassName={'absolute top-[1px] right-0'}
        asSingle={true}
        useRange={false}
        inputClassName={inputClass}
        primaryColor={'blue'}
        value={inputValue}
        readOnly
        onChange={(date) => {
          if (date?.startDate) {
            const d = date!.startDate;
            commit(new Date(d));
          }
        }}
        displayFormat={'DD.MM.YYYY'}
      />
    </div>
  );
};

export const dateEditColumnGenerator = (
  columnHeader: string,
  onCommit: (value: Date, context: any, key: keyof Object) => Promise<Date>
) => {
  return {
    header: () => <div className='text-end'>{columnHeader}</div>,
    size: 130,
    cell: (cell: any) => {
      return (
        <DateEditColumn
          value={cell.getValue()}
          context={cell.row.original}
          onCommit={onCommit}
          valueKey={cell.column.id as keyof Object}
        />
      );
    }
  };
};

export const CurrencyEditColumn = ({
  value,
  context,
  onCommit,
  valueKey
}: CurrencyEditColumnProps) => {
  const [inputValue, setInputValue] = useState<number>(value);
  const [editing, setEditing] = useState<boolean>(false);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  const commit = () => {
    setInProgress(true);
    onCommit(ref.current?.value as any, context, valueKey)
      .then((v: number) => {
        setInputValue(v);
      })
      .catch((e) => console.error('Could not update assignment', e))
      .finally(() => {
        setEditing(false);
        setInProgress(false);
      });
  };

  return editing ? (
    <div className='relative'>
      <div className='flex items-center'>
        <input
          ref={ref}
          value={inputValue}
          autoFocus={true}
          type='number'
          className='block w-full text-center p-1 text-sm text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
          onBlur={() => {
            if (!inProgress) {
              setEditing(false);
            }
          }}
          onChange={(v: any) => setInputValue(v.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              commit();
            } else if (event.key === 'Escape') {
              setInputValue(value);
              setEditing(false);
              setInProgress(false);
            }
          }}
        />
      </div>
      {inProgress ? (
        <div className='absolute top-[7px] right-2'>
          <Progress />
        </div>
      ) : null}
    </div>
  ) : (
    <div className='flex items-center justify-end'>
      <span className=''>{formatCurrency(inputValue)}</span>
      <PencilSquareIcon
        className='flex ml-2 w-4 h-4 text-gray-300 group-hover:text-royal-dark-blue'
        onClick={() => {
          setEditing(true);
        }}
      />
    </div>
  );
};

export const currencyEditColumnGenerator = (
  columnHeader: string,
  onCommit: (value: number, context: any, key: keyof Object) => Promise<number>
) => {
  return {
    header: () => <div className='text-end'>{columnHeader}</div>,
    size: 50,
    cell: (cell: any) => {
      return (
        <CurrencyEditColumn
          value={cell.getValue()}
          context={cell.row.original}
          onCommit={onCommit}
          valueKey={cell.column.id as keyof Object}
        />
      );
    }
  };
};

export const numberColumnGenerator = (
  columnHeader: string,
  unit?: string,
  formatter?: (value: any) => any,
  footer?: boolean,
  size?: number
) => {
  let data: any = {
    cell: (cell: any) => {
      let v = cell.getValue();
      if (formatter && v) {
        v = formatter(v);
      }
      return <NumberColumn value={v} unit={unit} />;
    },
    header: () => <div className='text-end'>{columnHeader}</div>,
    size: size ? size : 80
  };

  if (footer) {
    return {
      ...data,
      footer: (props: any) => {
        let val = props.table.getFilteredRowModel().rows.reduce((total: number, row: any) => {
          return total + (row.getValue(props.column.id) || 0);
        }, 0);
        if (formatter) {
          val = formatter(val);
        }
        return <div className='text-end'>{val}</div>;
      }
    };
  }

  return data;
};

export const dateColumnGenerator = (columnHeader: string) => {
  return {
    cell: (cell: any) => {
      const d = new Date(cell.getValue());

      const stl = isWeekend(d) ? 'text-gray-400 text-start' : 'text-start';
      return <div className={stl}>{formatDate(cell.getValue())} </div>;
    },
    header: () => <div className='text-start'>{columnHeader}</div>,
    size: 120
  };
};

export const stringColumnGenerator = (columnHeader: string) => {
  return {
    cell: (cell: any) => <div className='text-start'>{cell.getValue()}</div>,
    header: () => <div className='text-start'>{columnHeader}</div>
  };
};

export const jsonStringifierColumnGenerator = (columnHeader: string) => {
  return {
    cell: (cell: any) => <div className='text-start'>{JSON.stringify(cell.getValue())}</div>,
    header: () => <div className='text-start'>{columnHeader}</div>
  };
};

export const hoursColumnGenerator = (columnHeader: string, footer?: boolean) => {
  return numberColumnGenerator(columnHeader, 'h', undefined, footer);
};

export const percentColumnGenerator = (columnHeader: string) => {
  return numberColumnGenerator(columnHeader, '%');
};

export const currencyColumnGenerator = (columnHeader: string, footer?: boolean) => {
  return numberColumnGenerator(columnHeader, '', formatCurrency, footer);
};
